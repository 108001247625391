import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'shards-react';
import { connect } from 'react-redux';
import { setConfirmModalFields } from '../../appRedux/actions';
import { FormattedMessage } from 'react-intl';


class ConfirmModal extends React.Component {

  onClickConfirm = () => {
    if (this.props.confirmModal.callBack) {
      this.props.confirmModal.callBack();
    }
    this.onClickCancel();
  }

  onClickCancel = () => {
    this.props.setConfirmModalFields({ reset: true });
  }

  render () {

    const { confirmModal = {} } = this.props;

    return (
      <Modal open={confirmModal.open} toggle={this.onClickCancel}>
        <ModalHeader>{confirmModal.title}</ModalHeader>
        <ModalBody>
          {confirmModal.message}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-link"onClick={this.onClickCancel}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
          <Button onClick={this.onClickConfirm}><FormattedMessage id="confirm" defaultMessage="Confirm" /></Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  confirmModal: state.confirmModal,
});

export default connect(mapStateToProps, { setConfirmModalFields })(ConfirmModal);

import {
  SET_ADMINISTRATOR,
  SET_BOOKINGS_FIELDS,
  SET_AUTH_FIELDS,
  SET_CONFIRM_MODAL_FIELDS,
  SET_CUSTOMERS,
  SET_CUSTOMERS_FIELDS,
  SET_EMPLOYEES_FIELDS,
  SET_ITEMS,
  SET_PROFILE,
  SET_SERVICES,
  SET_NOTIFICATION_FIELDS,
  SET_VEHICLES_FIELDS,
  SET_REPORTS_FIELDS,
  SET_INVOICES_FIELDS,
  SET_CALENDAR_FIELDS,
  SET_LOCATIONS_FIELDS,
  SET_APPLICATION_FIELDS,
  SET_LOADING,
  SET_SUPERADMIN_FIELDS,
  SET_SUPER_ADMIN_ERRORS,
} from '../../utils/constants';

export const setAdministrator = payload => ({ payload, type: SET_ADMINISTRATOR });
export const setAuthFields = payload => ({ payload, type: SET_AUTH_FIELDS });
export const setBookingsFields = payload => ({ payload, type: SET_BOOKINGS_FIELDS });
export const setCalendarFeilds = payload => ({ payload, type: SET_CALENDAR_FIELDS });
export const setConfirmModalFields = payload => ({ payload, type: SET_CONFIRM_MODAL_FIELDS });
export const setCustomers = payload => ({ payload, type: SET_CUSTOMERS });
export const setCustomersFields = payload => ({ payload, type: SET_CUSTOMERS_FIELDS });
export const setEmployeesFields = payload => ({ payload, type: SET_EMPLOYEES_FIELDS });
export const setInvoicesFields = payload => ({ payload, type: SET_INVOICES_FIELDS });
export const setItems = payload => ({ payload, type: SET_ITEMS });
export const setLocationsFields = payload => ({ payload, type: SET_LOCATIONS_FIELDS });
export const setProfile = payload => ({ payload, type: SET_PROFILE });
export const setReportsFields = payload => ({ payload, type: SET_REPORTS_FIELDS });
export const setServices = payload => ({ payload, type: SET_SERVICES });
export const setSuperAdminErrors = payload => ({ payload, type: SET_SUPER_ADMIN_ERRORS });
export const setSuperAdminFields = payload => ({ payload, type: SET_SUPERADMIN_FIELDS });
export const setNotificationFields = payload => ({ payload, type: SET_NOTIFICATION_FIELDS });
export const setVehiclesFields = payload => ({ payload, type: SET_VEHICLES_FIELDS });
export const setApplicationFields = payload => ({ payload, type: SET_APPLICATION_FIELDS });
export const setLoading = payload => ({ payload, type: SET_LOADING });

import { SET_APPLICATION_FIELDS, SET_LOADING } from '../../utils/constants';

const initialState = {
  isLoading: false,
  isShowMantanceBanner: true,
};

function application (state = initialState, action) {
  switch (action.type) {
  case SET_APPLICATION_FIELDS:

    return {
      ...state,
      ...action.payload,
    };
  case SET_LOADING:
    if (state.isLoading === action.payload) return state;
    return {
      ...state,
      isLoading: action.payload,
    };
  default:
    return state;
  }
}

export default application;

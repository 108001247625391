import axios from '../../utils/Api';
import { setProfile } from '../actions';
import { userSignOut } from './auth';
import { getNotificationMessage } from './utility';

// get user profile detail
export const requestProfile = () => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get('/api/v1/profile/details');

    if (response.data.errors) {
      if (response.data.errors.auth_errors === 'Invalid Authorization Code') {
        dispatch(userSignOut());
      }

      notification.notifyError('Profile error', 'Problem in getting user details');
    }
    dispatch(setProfile(response.data.data));
  } catch (err) {
    notification.notifyError('Profile error', 'Problem in getting user details');
  }
};

// update profile
export const requestUpdateProfile = details => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put('/api/v1/profile/update', { user: details });

    if (response.data.errors) {
      notification.notifyError('Profile error', getNotificationMessage(response.data.errors, 'Profle'));
    }

    dispatch(setProfile(response.data));
    notification.notifySuccess('Profile', 'Profile updated');
  } catch (err) {
    notification.notifyError('Profile error', 'Error while updating profile');
  }
};

// integrate fortnox
export const requestFortnoxIntegration = details => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put('/api/v1/company/integrate_fortnox', details);

    if (response.data.errors) {
      notification.notifyError('Profile error', getNotificationMessage(response.data.errors, 'Profle'));
    }

    notification.notifySuccess('Profile', 'Fortnox Integrated');
    setTimeout(() => {
      window.location.replace('/#/settings');
    }, 800);
  } catch (err) {
    notification.notifySuccess('Profile', 'Error while integrating fortnox');
  }
};

// remove fortnox integration
export const requestRemoveFortnoxIntegration = () => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put('/api/v1/company/remove_fortnox');

    if (response.data.errors) {
      notification.notifyError('Profile error', getNotificationMessage(response.data.errors, 'Profle'));
    }

    dispatch(requestProfile());
    notification.notifySuccess('Profile', 'Fortnox integration removed');
  } catch (err) {
    notification.notifySuccess('Profile', 'Error while integrating fortnox');
  }
};

/* global google */
/* eslint-disable no-console */
/* eslint-disable init-declarations */
// import axios from '../../utils/Api';
import { setLocationsFields } from '../actions';

export const requestSearchPlaces = query => (dispatch, getState) => {
  const { notification } = getState();
  try {
    const successAction = res => dispatch(setLocationsFields({ records: res }));
    const placeAutoComplete = new google.maps.places.AutocompleteService();
    placeAutoComplete.getPlacePredictions({ input: query }, successAction);
    // const response = await axios.get('https://maps.googleapis.com/maps/api/place/autocomplete/json', { params: reqParams });

  } catch (err) {
    console.log(err);
    notification.notifyError('Place Error ', 'Error in getting places');
  }
};

export const requestPlaceDetails = (placeId, callBack) => (dispatch, getState) => {
  const { notification } = getState();
  try {
    const placeDetails = new google.maps.places.PlacesService();
    placeDetails.getDetails({ placeId, fields: ['name', 'geometry'] }, callBack);
  } catch (err) {
    console.log(err);
    notification.notifyError('Place Error ', 'Error in getting places');
  }
};

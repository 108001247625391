import axios from '../../utils/Api';
import FileSaver from 'file-saver';
import { setReportsFields } from '../actions';
import { getNotificationMessage } from './utility';

export const requestExportExcelBookings = params => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const parameters = {
      'start_date': params.startDate || '',
      'end_date': params.endDate || '',
      'customer_id': params.customerId || '',
      'employee_id': params.employeeId || '',
      'service_id': params.serviceId || '',
      'status': params.status || '',
      'car_id': params.carId || '',
      'start': params.start || '',
      ...(!params.employeeId ? { booking_xlsx: true } : { single_employee_report: true }),
    };
    const response = await axios.get('/api/v1/bookings/bookings_xlsx', { params: parameters });
    if (response.data.errors) {
      notification.notifyError('Booking error', getNotificationMessage(response.data.errors, 'Reports'));
    }

    if (response.data.data) {
      fetch(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.data.xlsx_file.content}`)
        .then(res => res.blob())
        .then(blob => FileSaver.saveAs(blob, response.data.data.xlsx_file.name));

    }
  } catch (err) {
    console.log(err)
    notification.notifyError('Report error', 'error while getting bookings');
  }
};

export const requestFilteredBookings = params => async (dispatch, getState) => {
  const { notification, reports } = getState();
  try {
    const parameters = {
      'start_date': params.startDate || '',
      'end_date': params.endDate || '',
      'customer_id': params.customerId || '',
      'employee_id': params.employeeId || '',
      'service_id': params.serviceId || '',
      'status': params.status || '',
      'car_id': params.carId || '',
      'start': params.start || '',
      'per_page': params.pageSize || reports.pageSize,
      'page': params.page || reports.page,
      'advance_search': params.advance_search,
    };

    const response = await axios.get('/api/v1/bookings/get_bookings', { params: parameters });
    if (response.data.errors) return;
    dispatch(setReportsFields({ records: response.data.data.bookings }));
  } catch (err) {
    console.log(err)
    notification.notifyError('Report error', 'Please check start date and end date selection');
  }
};

/* eslint-disable no-undefined */
/* eslint-disable line-comment-position */
/* eslint-disable no-unused-expressions */
import React from 'react';
import InputWithSuggestions from '../../InputWithSuggestions';
import get from 'lodash/fp/get';
import FormModal from '../../FormModal';
import { FormattedMessage } from 'react-intl';
import {
  Row,
  Col,
  Button,
  FormInput,
  FormCheckbox,
  InputGroup,
  FormSelect,
} from 'shards-react';

const itemDetail = ['vat', 'title', 'sequence_num', 'name', 'unit', 'per_unit', 'hours', 'quantity', 'do_not_invoice', 'is_house_work', 'housework_type', 'fixed_price'];

class Price extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      openAddNewModal: false,
      // items: [],
      rut: false,
      vatIncluded: null,
      editingObject: {},
      housework_type: '',
      paymentType: 'invoice',
    };
    this.onSubmitModal = this.onSubmitModal.bind(this);
    this.selectedItemRef = React.createRef();
  }

  onChangedoCheckbox = (name, item) => {
    const newState = { ...this.state };

    if (item) {
      newState.editingObject[name] = !newState.editingObject[name];
    } else {
      newState[name] = !this.state[name];
    }
    this.setState(newState);
  }

  static getDerivedStateFromProps (props, state) {
    const newState = { ...state };

    if (!props.active) {
      newState.quantity = null;
      return newState;
    }

    newState.vatIncluded = (state.vatIncluded !== null && state.vatIncluded !== undefined)
      ? state.vatIncluded : get('booking.customer.vat_included')(props);

    if (props.employees && !state.editingObject.fixed_price && props.active) {
      const totalTime = (props.employees || []).reduce((res, emp) => res + Number(emp.paid_time), 0);

      state.editingObject.quantity = state.editingObject.quantity || totalTime;
    }

    newState.items = state.items || props.booking.booking_items;
    newState.housework_type = state.housework_type || props.booking.housework_type || 'rut';

    return newState;
  }

  componentDidUpdate (preProps) {
    if ((!preProps.customer && this.props.customer) ||
      (preProps.customer && this.props.customer.sequence_num !== preProps.customer.sequence_num)) {
      this.setState({
        vatIncluded: this.props.customer.vat_included,
      });
    }
  }

  setEditingObjectFixedHour = (fixed_price) => {
    const quantity = fixed_price ? 0 : this.state.editingObject.quantity;
    this.setState({ editingObject: { ...this.state.editingObject, fixed_price, quantity } });
  }

  setQuantity = (e) => {
    this.setState({ editingObject: { ...this.state.editingObject, quantity: e.target.value } });
  }

  onChangeVat = (e) => {
    this.setState({ editingObject: { ...this.state.editingObject, vat: e.target.value } });
  }

  onChangePerUnit = (e) => {
    this.setState({ editingObject: { ...this.state.editingObject, per_unit: e.target.value } });
  }

  onChangeUnit = (e) => {
    this.setState({ editingObject: { ...this.state.editingObject, unit: e.target.value } });
  }

  onChangeItemHouseWorkType = (e) => {
    this.setState({ editingObject: { ...this.state.editingObject, housework_type: e.target.value } });
  }

  setSelectedItemDetails = (item) => {
    this.setState({
      editingObject: {
        per_unit: item.per_unit,
        unit: item.unit,
        vat: item.vat,
      },
    });
  }

  getTotal = (item) => {
    if (item.quantity === undefined || item.per_unit === undefined) return { total: 0, totalWithRut: 0 };
    const { per_unit = 0, quantity = 0 } = item;
    if (!this.state.vatIncluded && !item.is_house_work) {
      return {
        vat: (Number(per_unit) * 0.25 * Number(quantity)).toFixed(2),
        total: (Number(per_unit) * 1.25 * Number(quantity)).toFixed(2),
        rut: 0,
        toPay: (Number(per_unit) * 1.25 * Number(quantity)).toFixed(2),
      };
    }
    if (this.state.vatIncluded && !item.is_house_work) {
      return {
        vat: (((Number(per_unit) * 25) / 125) * Number(quantity)).toFixed(2),
        total: (Number(per_unit) * Number(quantity)).toFixed(2),
        rut: 0,
        toPay: (Number(per_unit) * Number(quantity)).toFixed(2),
      };
    }
    if (!this.state.vatIncluded && item.is_house_work) {
      return {
        vat: ((Number(per_unit) * 0.25) * Number(quantity)).toFixed(2),
        total: ((Number(per_unit) * 1.25) * Number(quantity)).toFixed(2),
        rut: ((Number(per_unit) * 1.25 * Number(quantity)) / 2),
        toPay: Math.abs(((Number(per_unit) * 1.25) * Number(quantity)) - ((((Number(per_unit) * 1.25) * Number(quantity)) / 2))).toFixed(2),
      };
    }
    if (this.state.vatIncluded && item.is_house_work) {
      return {
        vat: (((Number(per_unit) * 25) / 125) * Number(quantity)).toFixed(2),
        total: (Number(per_unit) * Number(quantity)).toFixed(2),
        rut: Number(per_unit) * Number(quantity) / 2,
        toPay: Math.abs((Number(per_unit) * Number(quantity)) - (((Number(per_unit) * Number(quantity)) / 2))).toFixed(2),
      };
    }
  }

  onFocusItemSearch = (txt) => {
    if (!txt) this.props.getSuggestions('needFiveArticle', 'items');
  }

  getModalContent = (item = {}) => {
    const { total, vat, rut, toPay } = this.getTotal(item);

    return (
      <Col lg="12 p-2">
        <div className="tab-content p-2" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
            <Row form>
              <Col lg="4" className="form-group">
                <label htmlFor="hourlyPriceCheck">
                  <input
                    type="radio"
                    className="mr-2"
                    id="hourlyPriceCheck"
                    defaultChecked={!item.fixed_price}
                    value={!item.fixed_price}
                    onChange={() => this.setEditingObjectFixedHour(false)}
                    name="paymentType"
                  />
                  <FormattedMessage id="hourly_price" defaultMessage="Hourly Price"/>
                </label>
              </Col>
              <Col lg="4" className="form-group">
                <label htmlFor="fixedPriceCheck">
                  <input
                    type="radio"
                    className="mr-2"
                    defaultChecked={item.fixed_price}
                    value={item.fixed_price}
                    onChange={() => this.setEditingObjectFixedHour(true)}
                    name="paymentType"
                  />
                  <FormattedMessage id="fixed_price" defaultMessage="Fixed Price"/>
                </label>
              </Col>
              <Col lg="4" className="form-group">
                <label htmlFor="do_not_invoice">
                  <input
                    type="checkbox"
                    className="mr-2"
                    name="do_not_invoice"
                    onChange={() => this.onChangedoCheckbox('do_not_invoice', true)}
                    defaultChecked={item.do_not_invoice}
                    checked={this.state.do_not_invoice === undefined ? item.do_not_invoice : this.state.do_not_invoice}
                    value={this.state.do_not_invoice === undefined ? item.do_not_invoice : this.state.do_not_invoice}
                  />
                  <FormattedMessage id="do_not_invoice" defaultMessage="Do not Invoice"/>
                </label>
              </Col>
            </Row>
            <Row form>
              <Col lg="12" className="form-group">
                <InputWithSuggestions
                  name="sequence_num"
                  label={<FormattedMessage id="search_your_item" defaultMessage="Search Your Item"/>}
                  labelName="title"
                  object={item}
                  suggestions={this.props.itemsSuggestions}
                  getSuggestions={txt => this.props.getSuggestions(txt, 'items')}
                  onSelect={this.setSelectedItemDetails}
                  onFocus={this.onFocusItemSearch}
                  required
                />
              </Col>
            </Row>
            <Row form>
              <Col lg="4" className="form-group">
                <label><FormattedMessage id="unit" defaultMessage="Unit"/></label>
                <InputGroup className="mb-3">
                  <FormSelect value={item.unit} onChange={this.onChangeUnit} name="unit">
                    <option value="h">h</option>
                    <option value="km">km</option>
                    <option value="st">st</option>
                    <option value="utl">utl</option>
                    <option value="förp">förp</option>
                  </FormSelect>
                </InputGroup>
              </Col>
              <Col lg="4" className="form-group">
                <label htmlFor="per_unit"><FormattedMessage id="price_per_unit" defaultMessage="Price / Unit"/></label>
                <FormInput
                  type="number"
                  id="per_unit"
                  name="per_unit"
                  defaultValue={item.per_unit}
                  onChange={this.onChangePerUnit}
                  value={item.per_unit}
                />
              </Col>

              <Col lg="4" className="form-group">
                <label><FormattedMessage id="vat_percent" defaultMessage="VAT %"/></label>
                <InputGroup className="mb-3">
                  <FormSelect onChange={this.onChangeVat} value={item.vat} name="vat">
                    <option value="25">25</option>
                    <option value="12">12</option>
                    <option value="6">6</option>
                    <option value="0">0</option>
                  </FormSelect>
                </InputGroup>
              </Col>

            </Row>
            <Row form>
              {this.state.housework_type === 'rut' && (
                <>
                <Col key="rut-houseWorkType-3" lg="3" className="form-group">
                  <label htmlFor="rut"></label>
                  <FormCheckbox
                    name="is_house_work"
                    onChange={() => this.onChangedoCheckbox('is_house_work', true)}
                    checked={(item.is_house_work && this.state.housework_type === 'rut')}
                    value={(item.is_house_work && this.state.housework_type === 'rut')}
                  >
                    <FormattedMessage id="rut" defaultMessage="RUT"/>
                  </FormCheckbox>
                </Col>
                <Col key="rut-houseWorkType-5" lg="5" className="form-group">
                  <label><FormattedMessage id="house_work_type" defaultMessage="House work type"/></label>
                  <InputGroup className="mb-3">
                    <FormSelect name="housework_type" value={item.housework_type} onChange={this.onChangeItemHouseWorkType}>
                      <option></option>
                      <option value="moving_services">Flyttjänster</option>
                      <option value="cleaning">Städning</option>
                      <option value="other_cost">Övriga kostnader</option>
                    </FormSelect>
                  </InputGroup>
                </Col>

              <Col lg="4" className="form-group">
                <label htmlFor="hours"><FormattedMessage id="reporting_hours" defaultMessage="Reporting hours"/></label>
                <FormInput
                  type="number"
                  id="hours"
                  name="hours"
                  defaultValue={item.hours}
                />
              </Col>
              </>)
              }
            </Row>
            <Row>
              <Col lg="4" className="form-group">
                <label htmlFor="quantity"><FormattedMessage id="quantity" defaultMessage="Quantity"/></label>
                <FormInput
                  type="number"
                  id="quantity"
                  name="quantity"
                  onChange={this.setQuantity}
                  value={item.quantity}
                />
              </Col>
              <Col lg="12">
                <small><FormattedMessage id="hour_fixed_price_message" defaultMessage="If hourly price selected then Quantity will be automatically updated upon employee working time."/> </small>
              </Col>
            </Row>
            <Row className="border-top d-flex justify-content-end">
              <Col className="col-4">
                <div><FormattedMessage id="rut_total" defaultMessage="RUT:"/><span className="ml-2">{rut}</span></div>
                <div><FormattedMessage id="vat_total" defaultMessage="VAT:"/><span className="ml-2">{vat}</span></div>
                <div><FormattedMessage id="total_price" defaultMessage="Total:"/><span className="ml-2">{total}</span></div>
                <div><FormattedMessage id="topay_price" defaultMessage="To Pay:"/><span className="ml-2">{toPay}</span></div>
              </Col>
            </Row>
            <FormInput
              type="text"
              id="fixed_price"
              name="fixed_price"
              value={item.fixed_price}
              readOnly
              hidden
            />
          </div>
        </div>
      </Col>
    );
  }

  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      editingObject: {},
      editingIndex: null,
      is_house_work: undefined,
    });
  }

  onClickAddNew = () => {
    this.setState({ openAddNewModal: true });
  }

  onSubmitModal (e, itemId) {
    e.stopPropagation();
    e.preventDefault();
    const item = {};
    const items = this.state.items ? this.state.items.slice() : [];

    for (const field of itemDetail) {
      if (e.target[field]) {
        item[field] = e.target[field].value;
      }
    }

    if (this.state.editingObject.sequence_num && typeof this.state.editingIndex === 'number') {
      items[this.state.editingIndex] = item;
    } else {
      items[items.length] = item;
    }

    this.setState({ items }, () => this.props.setModalData('items', this.state.items));
    this.onClickCloseModal();
  }

  onChangeHouseWorkType = (event) => {
    if (event.target.value === 'none') {
      const items = (this.state.items || []).map(i => ({ ...i, is_house_work: false }));
      this.setState({ housework_type: event.target.value, items });
    } else {
      this.setState({ housework_type: event.target.value });
    }
  }

  onChangePaymentType = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onClickRemoveItem = (index) => {
    this.setState({ items: this.state.items.filter((it, indx) => indx !== index) });
  }

  onClickEditItem = (editingObject, editingIndex) => {
    this.setState({ openAddNewModal: true, editingObject, editingIndex });
  }

  onClickDownItemsPdf = (totalValues) => {
    this.props.downloadItemsPdf({ ...totalValues, booking_id: this.props.booking.sequence_num });
  }

  render () {
    const { active } = this.props;
    const { items, vatIncluded, editingObject } = this.state;
    const content = this.getModalContent(editingObject);
    const totalValues = !items ? { toPay: 0, total: 0, vat: 0, rut: 0 } : items.reduce((res, acc) => {
      const { total = 0, vat = 0, rut = 0, toPay = 0 } = this.getTotal(acc);
      return {
        total: Number(total) + res.total,
        vat: Number(vat) + res.vat,
        rut: Number(rut) + res.rut,
        toPay: Number(toPay) + res.toPay,
      };
    }, { total: 0, vat: 0, rut: 0, toPay: 0 });

    return (
      <div>
        <FormModal
          content={content}
          title={<FormattedMessage id="add_edit" defaultMessage="Add New Item"/>}
          onSubmit={this.onSubmitModal}
          open={this.state.openAddNewModal}
          object={editingObject}
          objectId={editingObject.sequence_num}
          onClose={this.onClickCloseModal}
        />
        <Row style={{ overflowY: 'auto', display: active ? 'block' : 'none' }}>
          <Col lg="12">
            <div className="tab-content p-2" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
                <Row form>
                  <Col
                    lg="3"
                    data-toggle="tooltip" data-placement="top" title="Detta värde hämtas ur kundkortet. Det går ej att ändra här."
                    className="form-group d-flex align-items-center"
                  >
                    <label htmlFor="vat_included" className="text-muted">
                      <input
                        type="checkbox"
                        onChange={() => this.onChangedoCheckbox('vatIncluded')}
                        name="vat_included"
                        checked={vatIncluded}
                        value={vatIncluded}
                        className="mr-2"
                        disabled
                      />
                      <FormattedMessage id="vat_included" defaultMessage="Inkl. VAT"/>
                    </label>
                  </Col>
                  <Col lg="3" className="form-group">
                    <label><FormattedMessage id="house_work_type" defaultMessage="House work type"/></label>
                    <InputGroup className="mb-3">
                      <FormSelect onChange={this.onChangeHouseWorkType} value={this.state.housework_type} name="housework_type">
                        <option value="rut">RUT</option>
                        <option value="none">Ej Rut</option>
                      </FormSelect>
                    </InputGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col lg="12" className={`${this.props.booking.id ? 'd-flex justify-content-between' : ''} form-group`}>
                    <Button onClick={this.onClickAddNew}><FormattedMessage id="add_new_row" defaultMessage="Add New Row"/></Button>
                    {this.props.booking.id && (
                      <button type="button" className="btn btn-link" onClick={() => this.onClickDownItemsPdf(totalValues)}><i class="material-icons mr-2">save_alt</i><FormattedMessage id="download-pdf" defaultMessage="Download pdf"/></button>)
                    }
                  </Col>
                </Row>

                {items && items.map((item, ind) => (
                  <Row key={`price-1-${ind}`} form>
                    <Col xs="12" className="font-weight-bold">{item.title}</Col>
                    <Col>
                      <small className="form-text">
                        {item.quantity}{item.unit}, Pris/enhet: {item.per_unit}, Moms: {item.vat}%, RUT: {item.is_house_work && this.state.housework_type === 'rut' ? 'Ja' : 'Nej'}
                      </small>
                    </Col>

                    <Col xs="2" className="form-group">
                      <div className="font-weight-bold">{this.getTotal(item).total} kr</div>
                    </Col>
                    <Col xs="2" className="form-group">
                      <span onClick={() => this.onClickEditItem(item, ind)}><i className="material-icons mr-2 cursor-pointer">edit</i></span>
                      <span onClick={() => this.onClickRemoveItem(ind)} className="text-danger cursor-pointer"><i className="material-icons">delete</i></span>
                    </Col>
                  </Row>))
                }
                <Row>
                  <Col className="d-flex justify-content-between border-top pt-2">
                    <div xs="4"><FormattedMessage id="vat_total" defaultMessage="VAT:"/> {totalValues.vat} kr</div>
                    <div xs="4" ><FormattedMessage id="rut_total" defaultMessage="RUT:"/> {totalValues.rut} kr</div>
                    <div xs="4"><FormattedMessage id="total_price" defaultMessage="Total:"/> {totalValues.total} kr</div>
                    <div xs="4" className="font-weight-bold"><FormattedMessage id="topay_price" defaultMessage="To Pay:"/> {totalValues.toPay} kr</div>
                  </Col>
                </Row>
                {/* <hr/>
                <Row>
                  <Col xs="12">
                    <table className="table table-bordered p-0">
                      <thead>
                        <tr>
                          <th scope="col">Personal number</th>
                          <th scope="col">Name</th>
                          <th scope="col">RUT</th>
                        </tr>
                      </thead>
                      <tbody className="p-0">
                        <tr>
                          <td className="p-0">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control border-0"
                                id="personalNumber"
                                placeholder="Personalnumber"
                              />
                            </div>
                          </td>
                          <td className="p-0">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control border-0"
                                id="name"
                                placeholder="name"
                              />
                            </div>
                          </td>
                          <td className="p-0">
                            <div className="form-group">
                              <input
                                type="number"
                                className="form-control border-0"
                                id="rutAmount"
                                placeholder="Rut(kr)"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row> */}
              </div>
            </div>
            <FormInput
              name="items"
              value={JSON.stringify(items || this.props.booking.booking_items || [])}
              onChange={() => { }} // eslint-disable-line
              hidden
            />
          </Col>
        </Row>
      </div>

    );
  }
}

export default Price;

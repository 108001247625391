import { combineReducers } from 'redux';
import administrator from './administrator';
import auth from './auth';
import bookings from './bookings';
import profile from './profile';
import confirmModal from './confirmModal';
import customers from './customers';
import services from './services';
import items from './items';
import employees from './employees';
import notification from './notification';
import vehicles from './vehicles';
import reports from './reports';
import invoices from './invoices';
import calendar from './calendar';
import locations from './locations';
import application from './application';
import superAdmin from './superAdmin';

const reducers = combineReducers({
  administrator,
  auth,
  bookings,
  calendar,
  confirmModal,
  customers,
  profile,
  reports,
  services,
  superAdmin,
  items,
  invoices,
  locations,
  employees,
  notification,
  vehicles,
  application,
});

export default reducers;

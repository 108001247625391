import React from 'react';
import { Route, Switch } from 'react-router-dom';

import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';

import ProtectedRoute from './privatRoute/privateRoutes';
import { Role } from './utils/roles';

import { connect } from 'react-redux';
import { setNotificationFields } from './appRedux/actions';


// Route Views
import MainContentArea from './views/MainContentArea';
import Login from './views/Login';
import Register from './views/Register';
import ForgotPassword from './views/ForgotPassword';
import ChangePassword from './views/ChangePassword';
import ConfirmModal from './components/ConfirmModal';

class Routes extends React.Component {

  constructor () {
    super();
    this.notificationDOMRef = React.createRef();
  }

  componentDidMount () {
    this.props.setNotificationFields({ addNotification: this.notificationDOMRef.current.addNotification });
  }

  render () {

    return (
      <>
        <ConfirmModal />
        <ReactNotification ref={this.notificationDOMRef} />
        <Switch>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/forget-password" component={ForgotPassword}/>
          <Route exact path="/change-password" component={ChangePassword}/>
          <ProtectedRoute path="/" roles={Object.values(Role)} component={MainContentArea}/>
        </Switch>
      </>
    );
  }
}

export default connect(null, { setNotificationFields })(Routes);

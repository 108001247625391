/* eslint-disable jsx-quotes */
/* eslint-disable no-inline-comments */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
// import FuzzySearch from 'fuzzy-search';
import { setConfirmModalFields } from '../appRedux/actions';
import FormModal from '../components/FormModal';
import { FormattedMessage } from 'react-intl';
import {
  CardBody,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  FormCheckbox,
  ButtonGroup,
  Button,
} from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import {
  requestItems,
  requestCreateItem,
  requestDeleteItems,
  requestUpdateItem,
  requestSearchItems,
} from '../appRedux/thunks/items';

function getTableColumns () {
  return [
    {
      Header: () => (this.state.selectedItemIds.length ? <span onClick={this.onClickDelete} className="text-danger"><FormattedMessage id="delete" defaultMessage="Delete" /></span> : <FormattedMessage id="select" defaultMessage="Select" />), // eslint-disable-line
      accessor: 'select',
      maxWidth: 60,
      className: 'text-center',
      Cell: row => (
        <FormCheckbox
          className="ml-auto my-auto"
          name="select-service"
          checked={this.state.selectedItemIds.includes(row.original.sequence_num)}
          onChange={() => this.onSelectService(row.original.sequence_num)}
        />),
    },
    {
      Header: <FormattedMessage id="id" defaultMessage="ID" />,
      accessor: 'sequence_num',
      maxWidth: 60,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="title" defaultMessage="Title" />,
      accessor: 'title',
      className: 'text-center',
      minWidth: 200,
    },
    {
      Header: <FormattedMessage id="vat_%" defaultMessage="VAT %" />,
      accessor: 'vat',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="unit" defaultMessage="Enhet" />,
      accessor: 'unit',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="price_per_unit" defaultMessage="Price Per Unit" />,
      accessor: 'per_unit',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="actions" defaultMessage="Actions" />,
      accessor: 'actions',
      className: 'text-center',
      maxWidth: 150,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button theme="white" onClick={() => this.onClickEdit(row)}>
            <i className="material-icons">edit</i>
          </Button>
          <Button theme="white" onClick={() => this.onClickDelete(row.original.sequence_num)}>
            <i className="material-icons">delete</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}

class Items extends React.Component {
  constructor () {
    super();
    this.state = {
      editingItemObject: {},
      openAddNewModal: false,
      openEditingItemModal: false,
      selectedItemIds: [],
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      tableData: [],
      errors: {},
    };

    this.tableColumns = getTableColumns.call(this);
  }

  onChangeModalInput = (e) => {
    const valid = e.target.type === 'number'
      ? parseInt(e.target.value) >= 1 // eslint-disable-line
      : e.target.value.length > 1;
    const name = e.target.name;

    this.setState({ errors: { ...this.state.errors, [name]: valid } });
  }

  getModalContent = item => (
    <Row>
      <Col lg="12">
        <Row form>
          <Col lg="12" className="form-group">
            <label htmlFor="title"><FormattedMessage id="title" defaultMessage="Title" /></label>
            <FormInput
              id="title"
              name="title"
              valid={this.state.errors.title}
              invalid={this.state.errors.title === false}
              onChange={this.onChangeModalInput}
              defaultValue={item.title}
              required
            />
          </Col>
        </Row>
        <Row form>
          <Col lg="12" className="form-group">
            <label htmlFor="vat"><FormattedMessage id="vat_%" defaultMessage="VAT %" /></label>
            <FormSelect
              id="vat"
              name="vat"
              defaultValue={item.vat}
              type="number"
              valid={this.state.errors.vat}
              invalid={this.state.errors.vat === false}
              onChange={this.onChangeModalInput}
              required
            >
              <option value="25">25</option>
              <option value="12">12</option>
              <option value="6">6</option>
              <option value="0">0</option>
            </FormSelect>
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="form-group">
            <label htmlFor="unit"><FormattedMessage id="unit" defaultMessage="Unit" /></label>
            <FormSelect
              id="unit"
              name="unit"
              defaultValue={item.unit || ''}
              valid={this.state.errors.unit}
              invalid={this.state.errors.unit === false}
              onChange={this.onChangeModalInput}
              required
            >
              <option value="" disabled>Select</option>
              <option value="tim">tim</option>
              <option value="st">st</option>
              <option value="km">km</option>
              <option value="utl">utl</option>
            </FormSelect>
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="form-group">
            <label htmlFor="per_unit"><FormattedMessage id="price_per_unit" defaultMessage="Price Per Unit" /></label>
            <FormInput
              id="description"
              name="per_unit"
              type="number"
              min="0"
              defaultValue={item.per_unit}
              valid={this.state.errors.per_unit}
              invalid={this.state.errors.per_unit === false}
              onChange={this.onChangeModalInput}
              required
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )

  // i think it should be componentWillMount?
  componentDidMount () {
    this.props.requestItems();
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.items.pageSize;
    const page = !e.target ? (e + 1) : this.props.items.page;

    this.props.requestItems(page, pageSize);
  }

  onClickEdit = (row) => {
    this.setState({ editingItemObject: row.original, openEditingItemModal: true });
  }

  onClickAddNew = () => {
    this.setState({ openAddNewModal: true });
  }

  onSubmitItem = (event, itemId) => {
    const newService = {
      itemId,
      title: event.target.title.value,
      unit: event.target.unit.value,
      vat: event.target.vat.value,
      per_unit: event.target.per_unit.value, // eslint-disable-line
    };

    if (itemId) {
      this.props.requestUpdateItem(newService);
    } else {
      this.props.requestCreateItem(newService);
    }
    this.onClickCloseModal();
  }

  onClickDelete = (id) => {
    const idArray = (typeof id === 'object') ? this.state.selectedItemIds : [id];

    if (!idArray.length) return;
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeleteItems(idArray),
      message: <FormattedMessage id="delete_item_message" defaultMessage='Are you sure you want to delete the selected item(s)?' />,
      open: true,
      title: <FormattedMessage id="delete_item_title" defaultMessage='Confirm' />,
    });
  }

  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      openEditingItemModal: false,
      editingItemObject: {},
      errors: {},
    });
  }

  onSelectService = (id) => {
    const { selectedItemIds } = this.state;

    this.setState({
      selectedItemIds: !selectedItemIds.includes(id)
        ? [...selectedItemIds, id] : selectedItemIds.filter(sId => sId !== id),
    });
  }

  handleFilterSearch = (e) => {
    const searchText = e.target.value;

    if (searchText.length < 2) return;
    this.props.requestSearchItems(searchText);
  }

  render () {
    const { editingItemObject } = this.state;
    const content = this.getModalContent(editingItemObject);
    const { records = [], pageSizeOptions, pageSize, pages, page } = this.props.items;

    return (
      <div>
        <FormModal content={content} title={<FormattedMessage id="add" defaultMessage="Add New Item" />} onSubmit={this.onSubmitItem} open={this.state.openAddNewModal} onClose={this.onClickCloseModal}/>
        <FormModal content={content} objectId={editingItemObject.sequence_num} title={<FormattedMessage id="edit" defaultMessage="Edit Item" />} service={editingItemObject} onSubmit={this.onSubmitItem} open={this.state.openEditingItemModal} onClose={this.onClickCloseModal} />
        <Container className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={<FormattedMessage id="items" defaultMessage="Items" />} subtitle={<FormattedMessage id="items_desc" defaultMessage="select or search items below" />} className="text-sm-left mb-3" />
            <Col sm="4" className="d-flex ml-auto my-auto justify-content-end">
              <Button onClick={this.onClickAddNew}><i className="material-icons mr-2">add</i><FormattedMessage id="add_new_item" defaultMessage="Add New Item" /></Button>
            </Col>
          </Row>
          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span><FormattedMessage id="show_rows" defaultMessage="Number of Rows"/></span>
                    <FormSelect
                      size="sm"
                      value={pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  {/* Filters :: Search */}
                  <Col className="file-manager__filters__search d-flex" md="6">
                    <InputGroup seamless size="sm" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput onChange={this.handleFilterSearch} />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div>
                <ReactTable
                  manual
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  columns={this.tableColumns}
                  data={records}
                  pageSize={pageSize}
                  pages={pages}
                  page={page - 1}
                  showPageSizeOptions={false}
                  resizable={false}
                  onPageChange={this.handlePageSizeChange}
                  className="-striped -highlight"
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  items: state.items,
});

const mapDispatchToProps = {
  requestItems,
  requestCreateItem,
  requestUpdateItem,
  requestDeleteItems,
  requestSearchItems,
  setConfirmModalFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);

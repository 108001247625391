/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable sort-keys */

/* eslint-disable global-require */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSignOut } from '../../../../appRedux/thunks/auth';
import { FormattedMessage } from 'react-intl';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from 'shards-react';
import { requestProfile } from '../../../../appRedux/thunks/profile';


class UserActions extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions () {
    this.setState({
      visible: !this.state.visible,
    });
  }

  componentDidMount () {
    this.props.requestProfile();
  }


  render () {
    const { user = {} } = this.props.profile;

    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            // style={{ minWidth: '38px' }}
            className="user-avatar rounded-circle mr-2"
            src={require('./../../../../images/avatars/user.png')}
            alt="User Avatar"
          />{' '}
          <span className="d-none d-md-inline-block">{user.name}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/settings">
            <i className="material-icons">&#xE7FD;</i> <FormattedMessage id="profile_settings" defaultMessage="Profile / Settings" />
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/" className="text-danger" onClick={() => this.props.userSignOut()}>
            <i className="material-icons text-danger">&#xE879;</i>
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
});


export default connect(mapStateToProps, { userSignOut, requestProfile })(UserActions);

import { SET_ITEMS } from '../../utils/constants';
import { getPages } from './utility';

const initialState = {
  pageSizeOptions: [5, 10, 15, 20, 25, 30],
  pageSize: 10,
  pages: 1,
  page: 1,
};

function items (state = initialState, action) {
  switch (action.type) {
  case SET_ITEMS:
    return {
      ...state,
      ...action.payload,
      page: action.payload.page ? parseInt(action.payload.page, 10) : state.page,
      pages: getPages(state, action.payload),
      pageSize: action.payload.per_page ? parseInt(action.payload.per_page, 10) : state.pageSize,
    };
  default:
    return state;
  }
}

export default items;

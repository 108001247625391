/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-empty-function */
/* eslint-disable no-inline-comments */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import get from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';
import { setConfirmModalFields, setBookingsFields } from '../appRedux/actions';
import { requestSearchPlaces } from '../appRedux/thunks/googleMap';
import FormModal from '../components/FormModal';
import PageTitle from '../components/common/PageTitle';
import ModalContent from '../components/ModalContent/BookingsModalContent';
import InputWithSuggestions from '../components/InputWithSuggestions';
import optionsInEnglish from '../translations/se/options.json';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';

import {
  Badge,
  CardBody,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  FormCheckbox,
  Button,
  ButtonGroup,
} from 'shards-react';
import {
  requestBookings,
  requestUpdateBooking,
  requestCreateBooking,
  requstExportBookingPdf,
  requestDeleteBookings,
  requestBooking,
  requstFilteredBookings,
  requestCompleteBooking,
  requestCancelBooking,
  requestBookingEmailToCustomer,
  requestRestoreBooking,
  downloadItemsPdf,
  requestDeletedBookings,
} from '../appRedux/thunks/bookings';
import { requestSearchServices } from '../appRedux/thunks/services';
import { requestSearchCustomers, requestCreateCustomer } from '../appRedux/thunks/customers';
import { requestSearchEmployees } from '../appRedux/thunks/employees';
import { requestSearchItems } from '../appRedux/thunks/items';
import { requestSearchVehicles } from '../appRedux/thunks/vehicles';

const bookingFields = ['work_type', 'damage_history', 'congestion_charge', 'car_charges', 'start_date', 'customer_conversation', 'notes', 'service', 'housework_type', 'vat_included', 'order_date', 'start_time', 'vehicle', 'desciption', 'employee_count', 'employee_price', 'our_reference', 'start'];
const seenUsOptions = ['Annons', 'Cykel', 'Mäklare', 'Visitkort', 'Bil', 'City Self Storage', 'Eniro', 'Facebook', 'Hitta.se', 'Flyer', 'Tidigare kund', 'Nätet', 'Reco', 'Rekommenderad', 'Lokal & trottoarpratare', 'Landningssida-Uppsala', 'Landningssida-Stockholm'];
const startOptions = { morgon: 'Morgon / Faststart', lunchtid: 'Lunchtid', flex: 'Flex' };
const defaultSelectedWorkDesc = [{ cash_payment: true, mail_us: true, travel_time: true, minideb: true, insurance_conditions: true, anniversary: true, damage: true }];

function getTableColumns (profile) {
  let fields = [];
  if (!this.state.viewInactive) {
    fields = [
      ...fields, ...[
        {
      Header: () => (this.state.selectedBookingIds.length ? <span onClick={this.onClickDelete} className="text-danger"><FormattedMessage id="delete" defaultMessage="Delete"/></span> : <FormattedMessage id="select" defaultMessage="Select"/>), // eslint-disable-line
          accessor: 'select',
          maxWidth: 50,
          className: 'text-center',
          sortable: false,
          Cell: row => (
            !this.state.viewInactive && <FormCheckbox
              disabled={row.original.status === 'Completed'}
              className="ml-auto my-auto"
              name="select-service"
              checked={this.state.selectedBookingIds.includes(row.original.sequence_num)}
              onChange={() => row.original.status === 'Completed' ? undefined : this.onSelectCustomer(row.original.sequence_num)}
            />),
        },
      ],
    ];
  }

  fields = [
    ...fields, ...[
      {
        Header: <FormattedMessage id="id" defaultMessage="ID"/>,
        id: 'sequence_num',
        accessor: 'sequence_num',
        sortable: true,
        maxWidth: 50,
        className: 'text-center',
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 80,
        minWidth: 80,
        className: 'text-center',
        Cell: (row) => {
          const themes = { Active: 'secondary', Completed: 'success', Canceled: 'danger' };
          return this.state.viewInactive ? <Badge pill theme={'danger'}>{'Deleted'}</Badge> : <Badge pill theme={themes[row.original.status]}>{row.original.status}</Badge>;
        },
      },
      {
        Header: <FormattedMessage id="order_date" defaultMessage="Order Date"/>,
        accessor: 'order_date',
        sortable: true,
        maxWidth: 100,
        minWidth: 100,
        className: 'text-center',
      },
      {
        Header: 'Start',
        accessor: 'start',
        maxWidth: 120,
        minWidth: 120,
        className: 'text-center',
        Cell: row => <span>{startOptions[row.original.start] || '-'}</span>,
      },
      {
        Header: <FormattedMessage id="time" defaultMessage="Time"/>,
        accessor: 'start_time',
        maxWidth: 60,
        minWidth: 60,
        className: 'text-center',
      },
      {
        Header: <FormattedMessage id="customer" defaultMessage="Customer"/>,
        accessor: 'customer_name',
        maxWidth: 200,
        minWidth: 200,
        className: 'text-center',
      },
      {
        Header: <FormattedMessage id="personal_org_number" defaultMessage="Personl / Org. #"/>,
        accessor: 'customer_number',
        maxWidth: 200,
        className: 'text-center',
      },
      {
        Header: <FormattedMessage id="service" defaultMessage="Service"/>,
        accessor: 'service_name',
        maxWidth: 120,
        minWidth: 120,
        className: 'text-center',
      },
      {
        Header: <FormattedMessage id="employees_count" defaultMessage="Employee(s)"/>,
        accessor: 'employee_count',
        className: 'text-center',
        maxWidth: 80,
      },
      {
        Header: <FormattedMessage id="vehicle" defaultMessage="Vehicle"/>,
        accessor: 'car_name',
        className: 'text-center',
        Cell: row => row.original.car_name ? row.original.car_name.toString() : '',
      },
    ],
  ];

  if (!this.state.viewInactive) {
    fields = [
      ...fields, ...[
        {
          Header: <FormattedMessage id="actions" defaultMessage="Actions"/>,
          accessor: 'actions',
          className: 'text-center',
          sortable: false,
          minWidth: 250,
          Cell: row => (
            <ButtonGroup size="sm" className="d-table mx-auto">
              {(row.original.status === 'Completed' || row.original.status === 'Canceled') && (
                <Button theme="white" title="Reactivate booking" onClick={() => this.onClickRestoreBooking(row.original.sequence_num)}>
                  <i className="material-icons">restore</i>
                </Button>)
              }
              <Button theme="white" title="Edit Booking" onClick={() => this.onClickEdit(row.original)}>
                <i className="material-icons">edit</i>
              </Button>
              <Button theme={row.original.is_downloaded ? 'secondary' : 'white'} title="Order PDF" onClick={e => this.onClickExport(row.original.sequence_num, e)}>
                <i className="material-icons">save_alt</i>
              </Button>
              <Button theme={row.original.mailing_history_count ? 'secondary' : 'white'} title="Send Confirmation Email" onClick={() => this.onClickSendEmail(row.original.sequence_num)}>
                <i className="material-icons">email</i>
              </Button>
              {row.original.status === 'Active' && (
                <Button theme="white" title="Mark Complete" onClick={() => this.onClickComplete(row.original.sequence_num)}>
                  <i className="material-icons">done</i>
                </Button>)
              }
              {row.original.status === 'Active' && (
                <Button theme="white" title="Mark Cancel" onClick={() => this.onClickCancel(row.original.sequence_num, row.original.items_count)}>
                  <i className="material-icons">cancel</i>
                </Button>)
              }
              {row.original.status !== 'Completed' && (
                <Button theme="white" title="Delete Booking" onClick={() => this.onClickDelete(row.original.sequence_num)}>
                  <i className="material-icons">delete</i>
                </Button>)
              }
            </ButtonGroup>
          ),
        },
      ],
    ];
  }

  if (get('company.fortnox_integrated')(profile)) {
    const fieldsCopy = [...fields];
    fieldsCopy.splice(fields.length - 2, 0, {
      Header: 'Fortnox ID',
      accessor: 'fortnox_id',
      maxWidth: 120,
      className: 'text-center',
      Cell: row => (row.original.fortnox_id ? <span>{row.original.fortnox_id}</span> : '-'),
    });
    return fieldsCopy;
  }

  return fields;
}

const initialFilters = {
  status: 'view_all',
  startDate: moment().format('YYYY-MM-DD'),
  our_reference: '',
};

class Customers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      editingBookingObject: {},
      openAddNewModal: false,
      openEditingCustomerModal: false,
      selectedBookingIds: [],
      tableData: [],
      filters: initialFilters,
      showFilters: false,
      order: 'asc',
      tableSortData: [],
    };
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.viewInactive !== prevState.viewInactive) {
      this.filterBookings();
    }
  }

  componentDidMount () {
    this.filterBookings();
  }

    // Download PDF
    onClickExport = (bookingId, event) => {
      this.props.requstExportBookingPdf(bookingId);
      if (event.currentTarget.className === 'btn btn-white') {
        event.currentTarget.className = 'btn btn-secondary';
      }
    }

  onClickActivate = (customerId) => {
    this.props.requestActivateCustomer(customerId);
  }

  setCollapse = (collapseField) => {
    this.setState({ [collapseField]: !this.state[collapseField] });
  }

  onClickEdit = (editingBookingObject) => {
    this.setState({ editingBookingObject, openAddNewModal: true });
  }

  onClickAddNew = () => {
    this.setState({ openAddNewModal: true });
    this.props.setBookingsFields({ editingBookingObject: { workdescriptions: defaultSelectedWorkDesc } });
  }

  closeModalAndGetBookings = () => {
    this.onClickCloseModal();
    this.componentDidMount();
  }

  onSubmitBookingCallback = (booking) => {
    this.closeModalAndGetBookings();
    // ask user to send confirmation email after booking create/update
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestBookingEmailToCustomer(booking.sequence_num),
      message: <FormattedMessage id="send_email_message.undefined" defaultMessage={`Arbetsorder ${booking.sequence_num} skapad/uppdaterad, vill du skicka bokningsbekräftelse?`}/>,
      open: true,
      title: <FormattedMessage id="send_email" defaultMessage="Send Email"/>,
    });
  }

  onSubmitBooking = (event, bookingId) => {
    const employees = JSON.parse(event.target.employees.value);

    const details = {
      service_id: event.target.service_id.value, // eslint-disable-line
      customer_id: event.target.customer_id.value, // eslint-disable-line
      booking: {},
      employeeIds: employees.map(emp => emp.sequence_num),
      bookings_employee: employees, // eslint-disable-line
      addresses: JSON.parse(event.target.addresses.value), // eslint-disable-line
      items: JSON.parse(event.target.items.value), // eslint-disable-line
      customer_need: JSON.parse(event.target.customer_needs.value),
      seenUs: JSON.parse(event.target.seenUs.value),
      workDescription: JSON.parse(event.target.workDescription.value),
      cars: JSON.parse(event.target.cars.value),
    };

    for (const field of bookingFields) {
      if (event.target[field]) {
        details.booking[field] = event.target[field].type === 'checkbox' ? event.target[field].checked : event.target[field].value;
      }
    }

    if (details.booking.start === 'lunchtid' && details.booking.start === 'flex') {
      details.booking.start_time = null;
    }

    if (bookingId) {
      this.props.requestUpdateBooking(details, bookingId, this.onSubmitBookingCallback);
    } else {
      this.props.requestCreateBooking(details, this.onSubmitBookingCallback);
    }
  }

  onClickDelete = (id) => {
    const idArray = (typeof id === 'object') ? this.state.selectedBookingIds : [id];

    if (!idArray.length) return;
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeleteBookings(idArray, this.closeModalAndGetBookings),
      message: <FormattedMessage id="booking_delete_message" defaultMessage="Are you sure you want to delete the selected booking(s)?"/>,
      open: true,
      title: <FormattedMessage id="delete" defaultMessage="Delete"/>,
    });
  }

  onClickRestoreBooking = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestRestoreBooking(id, this.closeModalAndGetBookings),
      message: <FormattedMessage id="booking_activate_message" defaultMessage="Are you sure you want to reactivate the selected booking(s)?"/>,
      open: true,
      title: <FormattedMessage id="activate_booking" defaultMessage="Re-activate Booking"/>,
    });
  }

  onClickComplete = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestCompleteBooking(id, this.closeModalAndGetBookings),
      message: <FormattedMessage id="booking_complete_message" defaultMessage="Are you sure you want to complete the selected booking?"/>,
      open: true,
      title: <FormattedMessage id="complete_booking" defaultMessage="Complete Booking"/>,
    });
  }

  onClickCancel = (id, items_count = null) => {
    const message_id = (items_count && items_count > 0) ? 'booking_cancel_with_items_message' : 'booking_cancel_message';
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestCancelBooking(id, this.closeModalAndGetBookings),
      message: <FormattedMessage id={message_id} defaultMessage="Are you sure you want to cancel the selected booking?" values={{ item_count: items_count }}/>,
      open: true,
      title: `${items_count > 0 ? this.props.intl.formatMessage({ id: 'cancel_booking_title_with_items', defaultMessage: 'Cancel Booking. Warning!' }) : this.props.intl.formatMessage({ id: 'cancel_booking', defaultMessage: 'Cancel Booking' })}`,
    });
  }

  onClickSendEmail = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestBookingEmailToCustomer(id),
      message: <FormattedMessage id="send_email_message" defaultMessage="Are you sure you want to send the email to customer?"/>,
      open: true,
      title: <FormattedMessage id="send_email" defaultMessage="Send Email"/>,
    });
  }

  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      openEditingCustomerModal: false,
      editingBookingObject: {},
    });
  }

  onSelectCustomer = (id) => {
    const { selectedBookingIds } = this.state;

    this.setState({
      selectedBookingIds: !selectedBookingIds.includes(id)
        ? [...selectedBookingIds, id] : selectedBookingIds.filter(sId => sId !== id),
    });
  }

  handleFilterSearch = (e) => {
    const searchText = e.target.value;

    if (searchText.length < 1) return this.componentDidMount();
    const search = {
      ...this.state.filters,
      search: searchText,
    };
    if (this.state.viewInactive) {
      this.props.requestDeletedBookings(search);
    } else {
      this.props.requstFilteredBookings(search);
    }
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.bookings.pageSize;
    const page = !e.target ? (e + 1) : this.props.bookings.page;

    this.setState({ filters: { ...this.state.filters, page, pageSize } }, this.filterBookings);
  }

  getSuggestions = (txt, type) => {
    if (type === 'services') this.props.requestSearchServices(txt);
    if (type === 'customers') this.props.requestSearchCustomers(txt);
    if (type === 'employees') this.props.requestSearchEmployees(txt);
    if (type === 'items') this.props.requestSearchItems(txt);
    if (type === 'vehicles') this.props.requestSearchVehicles(txt);
  }

  onSelectFilterCustomerId = (customer) => {
    this.setState({ filters: { ...this.state.filters, customerId: customer.id } });
  }

  onSelectFilterEmployeeId = (employee) => {
    this.setState({ filters: { ...this.state.filters, employeeId: employee.id } });
  }

  onSelectServiceId = (service) => {
    this.setState({ filters: { ...this.state.filters, serviceId: service.id } });
  }

  onSelectFilterCarId = (car) => {
    this.setState({ filters: { ...this.state.filters, carId: car.id } });
  }

  setFilterKey = (e) => {
    this.setState({ filters: { ...this.state.filters, [e.target.name]: e.target.value } });
  }

  filterBookings = () => {
    if (this.state.viewInactive) {
      this.props.requestDeletedBookings(this.state.filters);
    } else {
      this.props.requstFilteredBookings(this.state.filters);
    }
  }

  toggleFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  }

  resetBookingFilters = () => {
    this.setState({ filters: initialFilters }, this.filterBookings);
  }

  onClickViewInactive = () => {
    this.setState(prevState => ({ viewInactive: !prevState.viewInactive }));
  }

  getSortedBookings = (array = []) => {
    if (!this.state.tableSortData.length) return array;
    const order = this.state.tableSortData[0].desc ? 'desc' : 'asc';
    return orderBy(b => b[this.state.tableSortData[0].id], order)(array);
  }

  onSortedChange = (newSorted, column, shiftKey) => {
    this.setState({ tableSortData: newSorted });
  }

  render () {
    const { editingBookingObject, viewInactive } = this.state;
    const { bookings, profile: { company = {} }, customers, employees, services, vehicles } = this.props; // eslint-disable-line
    const { pageSizeOptions, pageSize, pages, page } = bookings;
    const records = this.getSortedBookings(bookings.records);
    this.tableColumns = getTableColumns.call(this, this.props.profile);

    return (
      <div>
        <FormModal
          size="lg"
          content={<ModalContent objectId={editingBookingObject.sequence_num} parentProps={this.props} getSuggestions={this.getSuggestions} />}
          title={editingBookingObject.sequence_num ? <FormattedMessage id="edit" defaultMessage="Edit"/> : <FormattedMessage id="add" defaultMessage="Add New Booking"/>}
          onSubmit={this.onSubmitBooking}
          open={this.state.openAddNewModal}
          onClose={this.onClickCloseModal}
          object={editingBookingObject}
          objectId={editingBookingObject.sequence_num}
        />
        <Container fluid className="main-content-container">
          <Row noGutters className="page-header d-flex justify-content-between py-4">
            <PageTitle title={<FormattedMessage id="order" defaultMessage="Order"/>} subtitle={<FormattedMessage id="order_desc" defaultMessage="Select or search Orders below"/>} className="text-sm-left mb-3" />
            <Row noGutters className="page-header py-4">
              <Col sm="12" className="d-flex ml-auto my-auto justify-content-end">
                <button onClick={this.toggleFilters} type="button" className="btn btn-link">{this.state.showFilters ? <FormattedMessage id="hide_filter" defaultMessage="Hide Filter"/> : <FormattedMessage id="show_filter" defaultMessage="Dölj Filter"/>}</button>
                <Button className="mr-2" onClick={this.onClickAddNew}><i className="material-icons mr-2">add</i><FormattedMessage id="add_new_booking" defaultMessage="Add New Booking"/></Button>
                <Button className={`btn-outline-${viewInactive ? 'primary' : 'danger'}`} onClick={this.onClickViewInactive} outline>{viewInactive ? <FormattedMessage id="view_all" defaultMessage="View All"/> : <FormattedMessage id="view_deleted" defaultMessage="View Deleted"/>}</Button>
              </Col>
            </Row>
          </Row>
          {this.state.showFilters && (
            <Row>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="startDate"><FormattedMessage id="start_date" defaultMessage="Start Date"/></label>
                <FormInput
                  id="startDate"
                  name="startDate"
                  type="date"
                  valid={this.state.filters.startDate}
                  value={this.state.filters.startDate}
                  onChange={this.setFilterKey}
                />
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="endDate"><FormattedMessage id="end_date" defaultMessage="End Date"/></label>
                <FormInput
                  id="endDate"
                  name="endDate"
                  type="date"
                  valid={this.state.filters.endDate}
                  onChange={this.setFilterKey}
                />
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="status"><FormattedMessage id="status" defaultMessage="Status"/></label>
                <FormSelect
                  id="status"
                  name="status"
                  valid={this.state.filters.status}
                  onChange={this.setFilterKey}
                >
                  <option value="view_all">Visa alla</option>
                  <option value="active">Aktiv</option>
                  <option value="completed">Slutfört</option>
                  <option value="canceled">Avbokat</option>
                </FormSelect>
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="invoiceType"><FormattedMessage id="invoice_status" defaultMessage="Invoice Status"/></label>
                <FormSelect
                  id="invoiceType"
                  name="invoiceType"
                  valid={this.state.filters.invoiceType}
                  onChange={this.setFilterKey}
                >
                  <option></option>
                  <option value="sent">Skickat</option>
                  <option value="notSent">Ej Skickat</option>
                  <option value="noItem">Artiklar Saknas</option>
                </FormSelect>
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="seen_us_customer"><FormattedMessage id="seen_us" defaultMessage="Seen Us"/></label>
                <FormSelect
                  id="seenUs"
                  name="seenUs"
                  valid={this.state.filters.seenUs}
                  onChange={this.setFilterKey}
                >
                  <option></option>
                  {seenUsOptions.map(op => <option key={`${op}-seenUs`} value={optionsInEnglish[op]}>{op}</option>)}
                </FormSelect>
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="start"><FormattedMessage id="booking_start" defaultMessage="Booking start"/></label>
                <FormSelect name="start" onChange={this.setFilterKey} value={this.state.filters.start}>
                  <option value="view_all">Alla</option>
                  <option value="morgon">Morgon / Faststart</option>
                  <option value="lunchtid">Lunchtid</option>
                  <option value="flex">Flex</option>
                </FormSelect>
              </Col>
              <Col xs="4" md="3" className="form-group">
                <InputWithSuggestions
                  name="customer_id"
                  label={<FormattedMessage id="customer" defaultMessage="Customer"/>}
                  labelName="name"
                  suggestions={customers.records}
                  getSuggestions={txt => this.getSuggestions(txt, 'customers')}
                  onSelect={this.onSelectFilterCustomerId}
                />
              </Col>
              <Col xs="4" md="3" className="form-group">
                <InputWithSuggestions
                  name="employee_id"
                  label={<FormattedMessage id="employee" defaultMessage="Employee"/>}
                  labelName="name"
                  suggestions={employees.records}
                  getSuggestions={txt => this.getSuggestions(txt, 'employees')}
                  onSelect={this.onSelectFilterEmployeeId}
                />
              </Col>
              <Col xs="4" md="3" className="form-group">
                <InputWithSuggestions
                  name="service_id"
                  label={<FormattedMessage id="service" defaultMessage="Service"/>}
                  labelName="title"
                  suggestions={services.records}
                  getSuggestions={txt => this.getSuggestions(txt, 'services')}
                  onSelect={this.onSelectServiceId}
                />
              </Col>
              <Col xs="4" md="3" className="form-group">
                <InputWithSuggestions
                  name="car_id"
                  label={<FormattedMessage id="car" defaultMessage="Car"/>}
                  labelName="name"
                  suggestions={vehicles.records}
                  getSuggestions={txt => this.getSuggestions(txt, 'vehicles')}
                  onSelect={this.onSelectFilterCarId}
                />
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="bookingId"><FormattedMessage id="booking_id" defaultMessage="Booking ID"/></label>
                <FormInput
                  id="bookingId"
                  name="booking_id"
                  type="text"
                  valid={this.state.filters.booking_id}
                  onChange={this.setFilterKey}
                />
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="customerEmail"><FormattedMessage id="booking.customer.email" defaultMessage="Customer Email"/></label>
                <FormInput
                  id="customerEmail"
                  name="customer_email"
                  type="text"
                  valid={this.state.filters.customer_email}
                  onChange={this.setFilterKey}
                />
              </Col>
              <Col xs="4" md="2" className="form-group">
                <label htmlFor="our_reference">{<FormattedMessage id="our_reference" defaultMessage="Our Reference"/>}</label>
                <FormSelect value={this.state.filters.our_reference} name="our_reference" onChange={this.setFilterKey}>
                  <option value=""></option>
                  <option value="henrik">Henrik</option>
                  <option value="daniel">Daniel</option>
                  <option value="jesper">Jesper</option>
                  <option value="mikael">Mikael</option>
                  <option value="benjamin">Benjamin</option>
                  <option value="henric">Henric</option>
                </FormSelect>
              </Col>
              <Col md="12" className="d-flex ml-auto my-auto justify-content-end mb-3">
                <Button onClick={this.resetBookingFilters} theme="white" className="mr-2"><i className="material-icons mr-2">cached</i><FormattedMessage id="reset" defaultMessage="Reset"/></Button>
                <Button onClick={this.filterBookings}><i className="material-icons mr-2">search</i><FormattedMessage id="search" defaultMessage="Search"/></Button>
              </Col>
            </Row>)
          }

          <br/>

          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span><FormattedMessage id="show_rows" defaultMessage="Number of Rows"/></span>
                    <FormSelect
                      size="sm"
                      value={pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  {/* Filters :: Search */}
                  <Col className="file-manager__filters__search d-flex" md="6">
                    <InputGroup seamless size="sm" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput onChange={this.handleFilterSearch} />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  manual
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  columns={this.tableColumns}
                  sorted={this.state.tableSortData}
                  data={records}
                  pages={pages}
                  page={page - 1}
                  pageSize={pageSize}
                  showPageSizeOptions={false}
                  onSortedChange={this.onSortedChange}
                  resizable={false}
                  onPageChange={this.handlePageSizeChange}
                  className="-striped -highlight"
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  services: state.services,
  customers: state.customers,
  bookings: state.bookings,
  profile: state.profile,
  employees: state.employees,
  items: state.items,
  vehicles: state.vehicles,
  locations: state.locations,
});

const mapDispatchToProps = {
  setConfirmModalFields,
  requestBookings,
  requestCreateBooking,
  requestSearchServices,
  requestSearchCustomers,
  requestSearchEmployees,
  requestSearchItems,
  requstExportBookingPdf,
  requestDeleteBookings,
  requestBooking,
  requstFilteredBookings,
  requestUpdateBooking,
  requestCompleteBooking,
  requestCancelBooking,
  requestBookingEmailToCustomer,
  requestSearchVehicles,
  setBookingsFields,
  requestCreateCustomer,
  requestRestoreBooking,
  downloadItemsPdf,
  requestSearchPlaces,
  requestDeletedBookings,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Customers));

import axios from '../../utils/Api';
import { setCalendarFeilds } from '../actions';
import { getNotificationMessage } from './utility';

export const requestCalendarData = () => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get('/api/v1/bookings/calendar');

    if (response.data.errors) {
      notification.notifyError('Calendar', getNotificationMessage(response.data.errors));
    }

    dispatch(setCalendarFeilds(response.data.data));
  } catch (err) {
    notification.notifyError('Calendar', 'Error while getting customer');
  }
};

import axios from '../../utils/Api';
import { setSuperAdminFields, setAuthFields, setSuperAdminErrors } from '../actions';
import { USER_TOKEN_SET, USER_ROLE } from '../../utils/constants';

export const requestCompanies = filters => async (dispatch, getState) => {
  const { notification, superAdmin } = getState();
  try {
    const query = {
      page: filters.page || superAdmin.page,
      per_page: filters.pageSize || superAdmin.pageSize,
    };

    const response = await axios.get('/api/v1/super_admin/companies', { params: query });

    if (response.data.errors) {
      notification.notifyError('SuperAdmin Error ', response.data.errors);
    }

    dispatch(setSuperAdminFields({
      ...response.data.data,
      companies: response.data.data.records,
      records: undefined,
    }));
  } catch (err) {
    notification.notifyError('SuperAdmin Error ', 'Error while requesting companies');
  }
};

export const requestCreateCompanyInvoices = () => async (dispatch, getState) => {
  const { notification } = getState();
  try {

    const response = await axios.get('/api/v1/super_admin/create_invoices');

    if (response.data.errors) {
      notification.notifyError('SuperAdmin Error ', response.data.errors);
    }

    notification.notifySuccess('Super Admin', 'Started creating invoices');
  } catch (err) {
    notification.notifyError('SuperAdmin Error ', 'Error while creating invoices');
  }
};

export const requestLoginCompany = id => async (dispatch, getState) => {
  const { notification } = getState();
  try {

    const { data } = await axios.get(`/api/v1/super_admin/company_signin/${id}`);

    if (data.success && !data.errors) {
      axios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      localStorage.setItem('token', data.data.user.token);
      localStorage.setItem('role', data.data.user.role);
      dispatch({ payload: data.data.user.token, type: USER_TOKEN_SET });
      dispatch({ payload: data.data.user.role, type: USER_ROLE });
      window.location.replace('/');
    } else {
      notification.notifyError('SuperAdmin Error ', 'Error while login company');
      dispatch(setAuthFields({ errors: data.errors }));
    }

  } catch (err) {
    notification.notifyError('SuperAdmin Error ', 'Error while login company');
  }
};

export const requestErrors = params => async (dispatch, getState) => {
  const { notification } = getState();
  try {

    const { data } = await axios.get('/api/v1/super_admin/integration_errors', { params });

    dispatch(setSuperAdminErrors(data.data));

  } catch (err) {
    notification.notifyError('SuperAdmin Error ', 'Error while login company');
  }
};

export const requestInvoices = filters => async (dispatch, getState) => {
  const { notification, superAdmin } = getState();
  try {
    const query = {
      page: filters.page || superAdmin.page,
      per_page: filters.pageSize || superAdmin.pageSize,
    };

    const response = await axios.get('/api/v1/super_admin/invoices', { params: query });
    dispatch(setSuperAdminFields(response.data.data));
  } catch (err) {
    notification.notifyError('SuperAdmin Error ', 'Error while requesting invoices');
  }
};

export const requestMarkPaidInvoice = (id, callBack) => async (dispatch, getState) => {
  const { notification } = getState();
  try {

    const response = await axios.put(`/api/v1/super_admin/invoice_paid/${id}`);

    if (response.data.errors) {
      notification.notifyError('SuperAdmin Error ', response.data.errors);
    }

    callBack();
  } catch (err) {
    notification.notifyError('SuperAdmin Error ', 'Error while marking invoice paid');
  }
};

export const requestDeleteInvoice = (id, callBack) => async (dispatch, getState) => {
  const { notification } = getState();
  try {

    const response = await axios.delete(`/api/v1/super_admin/invoice_delete/${id}`);

    if (response.data.errors) {
      notification.notifyError('SuperAdmin Error ', response.data.errors);
    }

    callBack();
  } catch (err) {
    notification.notifyError('SuperAdmin Error ', 'Error while deleting invoice');
  }
};

import axios from '../../utils/Api';
import { setServices } from '../actions';

export const requestServices = (page = 1, limit = 10) => async (dispatch, getState) => {
  const { notification, services } = getState();
  const query = `?page=${page ? page : services.page}&per_page=${(limit || services.pageSize)}`;
  const response = await axios.get(`/api/v1/services${query}`);

  if (response.data.errors) {
    notification.notifyError('Services error', response.data.errors);
  }

  dispatch(setServices(response.data.data));
};

export const requestCreateService = data => async (dispatch, getState) => {
  const response = await axios.post('/api/v1/services', data);

  if (response.data.errors) {
    getState().notification.notifyError('Services error', response.data.errors);
  }

  dispatch(requestServices());
  getState().notification.notifySuccess('Services', response.data.message);
};

export const requestUpdateService = data => async (dispatch, getState) => {
  const response = await axios.put(`/api/v1/services/${data.serviceId}`, data);

  if (response.data.errors) {
    getState().notification.notifyError('Services error', response.data.errors);
  }

  dispatch(requestServices());
  getState().notification.notifySuccess('Services', response.data.message);
};

export const requestDeleteService = ids => async (dispatch, getState) => {
  const response = await axios.delete('/api/v1/services/bulk_delete', { data: { services: ids } });

  if (response.data.errors) {
    getState().notification.notifyError('Services error', response.data.errors);
  }

  dispatch(requestServices());
  getState().notification.notifySuccess('Services', response.data.message);
};

export const requestSearchServices = text => async (dispatch, getState) => {
  const response = await axios.get(`/api/v1/services/search?query=${text}`);

  if (response.data.data) {
    dispatch(setServices({ records: response.data.data }));
  }

};

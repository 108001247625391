/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { setConfirmModalFields } from '../appRedux/actions';
import FormModal from '../components/FormModal';
import { FormattedMessage } from 'react-intl';
import {
  CardBody,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  FormCheckbox,
  ButtonGroup,
  Button,
} from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import {
  requestVehicles,
  requestCreateVehicle,
  requestUpdateVehicle,
  requestDeleteVehicles,
  requestSearchVehicles,
} from '../appRedux/thunks/vehicles';

function getTableColumns () {
  return [
    {
      Header: () => this.state.selectedVehicleIds.length ? <span onClick={this.onClickDelete} className="text-danger"><FormattedMessage id="delete" defaultMessage="Deletet" /></span> : <FormattedMessage id="select" defaultMessage="Select" />,
      accessor: 'select',
      maxWidth: 60,
      className: 'text-center',
      Cell: row => (
        <FormCheckbox
          className="ml-auto my-auto"
          name="select-service"
          checked={this.state.selectedVehicleIds.includes(row.original.sequence_num)}
          onChange={() => this.onSelectService(row.original.sequence_num)}
        />),
    },
    {
      Header: <FormattedMessage id="id" defaultMessage="ID" />,
      accessor: 'sequence_num',
      maxWidth: 60,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="name" defaultMessage="Name" />,
      accessor: 'name',
      className: 'text-center',
      minWidth: 200,
    },
    {
      Header: <FormattedMessage id="reg_number" defaultMessage="Reg. Number" />,
      accessor: 'reg_number',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="status" defaultMessage="Status" />,
      accessor: 'status',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="actions" defaultMessage="Actions" />,
      accessor: 'actions',
      className: 'text-center',
      maxWidth: 150,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button theme="white" onClick={() => this.onClickEdit(row)}>
            <i className="material-icons">edit</i>
          </Button>
          <Button theme="white" onClick={() => this.onClickDelete(row.original.sequence_num)}>
            <i className="material-icons">delete</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}


class Vehicle extends React.Component {
  constructor () {
    super();
    this.state = {
      editingVehicleObject: {},
      openAddNewModal: false,
      selectedVehicleIds: [],
      errors: {},
    };

    this.tableColumns = getTableColumns.call(this);
  }

  onChangeModalInput = (e) => {
    const valid = e.target.value.length > 2;
    const name = e.target.name;

    this.setState({ errors: { ...this.state.errors, [name]: valid } });
  }

  getModalContent = vehicle => (
    <Row>
      <Col lg="12">
        <Row form>
          <Col lg="12" className="form-group">
            <label htmlFor="name"><FormattedMessage id="name" defaultMessage="Name" /></label>
            <FormInput
              id="name"
              name="name"
              valid={this.state.errors.title}
              invalid={this.state.errors.title === false}
              defaultValue={vehicle.name}
              onChange={this.onChangeModalInput}
              required
            />
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="form-group">
            <label htmlFor="reg_number"><FormattedMessage id="reg_number" defaultMessage="Reg. Number" /></label>
            <FormInput
              id="reg_number"
              name="reg_number"
              defaultValue={vehicle.reg_number}
            />
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="form-group">
            <label htmlFor="status"><FormattedMessage id="status" defaultMessage="Status" /></label>
            <FormInput
              id="status"
              name="status"
              defaultValue={vehicle.status}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )


  componentDidMount () {
    this.props.requestVehicles();
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.vehicles.pageSize;
    const page = !e.target ? (e + 1) : this.props.vehicles.page;

    this.props.requestVehicles(page, pageSize);
  }

  // Edit item
  onClickEdit = (row) => {
    this.onClickAddNew(row.original);
  }

  // Add new item
  onClickAddNew = (editingVehicleObject = {}) => {
    this.setState({ openAddNewModal: true, editingVehicleObject });
  }

  // Update submit
  onSubmitService = (event, vehicleId) => {
    const newVehicle = {
      reg_number: event.target.reg_number.value,
      name: event.target.name.value,
      status: event.target.status.value,
    };

    if (vehicleId) {
      this.props.requestUpdateVehicle(newVehicle, vehicleId);
    } else {
      this.props.requestCreateVehicle(newVehicle);
    }
    this.onClickCloseModal();
  }

  // delete one or multiple services
  onClickDelete = (id) => {
    const idArray = (typeof id === 'object') ? this.state.selectedVehicleIds : [id];

    if (!idArray.length) return;
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeleteVehicles(idArray),
      message: <FormattedMessage id="delete_vehicle_message" defaultMessage="Are you sure you want to delete the selected vehicle(s)?" />,
      open: true,
      title: <FormattedMessage id="delete" defaultMessage="Confirm" />,
    });
  }

  // close modal
  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      editingVehicleObject: {},
    });
  }

  // select multiple items
  onSelectService = (id) => {
    const { selectedVehicleIds } = this.state;

    this.setState({
      selectedVehicleIds: !selectedVehicleIds.includes(id)
        ? [...selectedVehicleIds, id] : selectedVehicleIds.filter(sId => sId !== id),
    });
  }

  handleFilterSearch = (e) => {
    const searchText = e.target.value;

    if (searchText.length <= 1) return this.componentDidMount();
    this.props.requestSearchVehicles(searchText);
  }

  render () {
    const { editingVehicleObject } = this.state;
    const content = this.getModalContent(editingVehicleObject);
    const { records = [], pageSizeOptions, pageSize, pages, page } = this.props.vehicles;


    return (
      <div>
        <FormModal
          content={content}
          title={editingVehicleObject.sequence_num ? <FormattedMessage id="edit" defaultMessage="Edit Vehicle" /> : <FormattedMessage id="add_new_vehicle" defaultMessage="Add New Vehicle" />}
          onSubmit={this.onSubmitService}
          object={editingVehicleObject}
          objectId={editingVehicleObject.sequence_num}
          open={this.state.openAddNewModal}
          onClose={this.onClickCloseModal}
        />
        <Container className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={<FormattedMessage id="vehicle" defaultMessage="Vehicles" />} subtitle={<FormattedMessage id="vehicle_desc" defaultMessage="Select or search vehicle below" />} className="text-sm-left mb-3" />
            <Col sm="4" className="d-flex ml-auto my-auto justify-content-end">
              <Button onClick={this.onClickAddNew}><i className="material-icons mr-2">add</i><FormattedMessage id="add_new_vehicle" defaultMessage="Add New Vehicle" /></Button>
            </Col>
          </Row>
          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>

                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span><FormattedMessage id="show_rows" defaultMessage="Number of Rows"/></span>
                    <FormSelect
                      size="sm"
                      value={pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  <Col className="file-manager__filters__search d-flex" md="6">
                    <InputGroup seamless size="sm" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput onChange={this.handleFilterSearch} />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div>
                <ReactTable
                  manual
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  columns={this.tableColumns}
                  data={records}
                  pageSize={pageSize}
                  pages={pages}
                  page={page - 1}
                  showPageSizeOptions={false}
                  resizable={false}
                  onPageChange={this.handlePageSizeChange}
                  className="-striped -highlight"
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  vehicles: state.vehicles,
});

const mapDispatchToProps = {
  requestVehicles,
  requestCreateVehicle,
  requestUpdateVehicle,
  requestDeleteVehicles,
  setConfirmModalFields,
  requestSearchVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);

/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable no-alert */
/* eslint-disable no-inline-comments */
import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import FormModal from '../components/FormModal';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
} from 'shards-react';
import { setConfirmModalFields, setAdministrator } from '../appRedux/actions';
import PageTitle from '../components/common/PageTitle';
import {
  requestAdmins,
  requestSearchAdmin,
  requestDeleteAdmin,
  requestCreateAdmin,
  requestDeactivateAdmin,
  requestInactiveAdmins,
  requestActivateAdmin,
} from '../appRedux/thunks/administrator';

function getTableColumns () {
  return [
    {
      Header: <FormattedMessage id="id" defaultMessage="ID" />,
      accessor: 'sequence_num',
      maxWidth: 60,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="name" defaultMessage="Name" />,
      accessor: 'name',
      className: 'text-center',
      minWidth: 150,
    },
    {
      Header: <FormattedMessage id="personal_number" defaultMessage="Personal Number" />,
      accessor: 'mobile',
      maxWidth: 150,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="email" defaultMessage="Email" />,
      accessor: 'email',
      maxWidth: 250,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="actions" defaultMessage="actions" />,
      accessor: 'actions',
      maxWidth: 150,
      minWidth: 150,
      sortable: false,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          {row.original.is_active ? (
          <>
          <Button
            theme="white"
            onClick={() => this.onClickDeactivateAdmin(row.original.sequence_num)}
          >
            <i className="material-icons">block</i>
          </Button>
          </>) : (
            <Button theme="white" onClick={() => this.onClickActivateAdmin(row.original.sequence_num)}>
              <i className="material-icons">restore</i>
            </Button>
          )
          }
          <Button theme="white" onClick={() => this.onClickDeleteAdmin(row.original.sequence_num)}>
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}

class Administrator extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      editingAdminObject: {},
      openEditingAdminModal: false,
      errors: {},
      viewInactive: false,
    };

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.onClickEditAdmin = this.onClickEditAdmin.bind(this);
    this.tableColumns = getTableColumns.call(this);

  }

  getModalContent = admin => (
    <Row>
      <Col lg="12">
        <Row form>
          <Col lg="12" className="form-group">
            <label htmlFor="company"><FormattedMessage id="name" defaultMessage="Name" /></label>
            <FormInput
              id="name"
              name="name"
              defaultValue={admin.name}
              valid={this.state.errors.name}
              invalid={this.state.errors.name === false}
              onChange={this.onChangeModalInput}
              required
            />
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="form-group">
            <label htmlFor="company"><FormattedMessage id="email" defaultMessage="Email" /></label>
            <FormInput
              id="email"
              name="email"
              type="email"
              invalid={Boolean(this.props.administrator.errors && this.props.administrator.errors.email)}
              defaultValue={admin.email}
              required
            />
            {this.props.administrator.errors && this.props.administrator.errors.email && (
              <small className="text-danger"><FormattedMessage id="email" defaultMessage="Email" /> {this.props.administrator.errors.email[0]}</small>
            )}
          </Col>
          <Col md="12" className="form-group">
            <label htmlFor="password"><FormattedMessage id="password" defaultMessage="Password" /></label>
            <FormInput
              id="password"
              name="password"
              type="password"
              invalid={Boolean(this.props.administrator.errors && this.props.administrator.errors.password)}
              defaultValue={admin.password}
              required
            />
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="form-group">
            <label htmlFor="mobile"><FormattedMessage id="personal_number" defaultMessage="Personal Number" /></label>
            <FormInput
              id="mobile"
              name="mobile"
              pattern="[0-9]*"
              maxLength="12"
              defaultValue={admin.mobile}
              valid={this.state.errors.mobile}
              invalid={this.state.errors.mobile === false}
              onChange={this.onChangeModalInput}
            />
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="form-group">
            <label htmlFor="is_confirmed">
              <input
                type="checkbox"
                className="ml-auto my-auto mr-2"
                name="is_confirmed"
              />
              <FormattedMessage id="send_email_confirmation" defaultMessage="Send Email Confirmation" />
            </label>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  componentDidMount () {
    this.props.requestAdmins();
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.administrator.pageSize;
    const page = !e.target ? (e + 1) : this.props.administrator.page;
    if (this.state.viewInactive) {
      return this.props.requestInactiveAdmins(page, pageSize);
    }
    this.props.requestAdmins(page, pageSize);
  }

  onChangeModalInput = (e) => {
    const valid = e.target.type === 'number'
      ? parseInt(e.target.value) >= 1 // eslint-disable-line
      : e.target.value.length > 2;
    const name = e.target.name;

    this.setState({ errors: { ...this.state.errors, [name]: valid } });
  }

  onClickDeleteAdmin = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeleteAdmin(id),
      message: <FormattedMessage id="delete_admin_message" defaultMessage="Are you sure you want to permanently delete this Administrator?" />,
      open: true,
      title: <FormattedMessage id="delete" defaultMessage="Delete Administrator" />,
    });
  }

  onClickActivateAdmin = (adminId) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestActivateAdmin(adminId),
      message: <FormattedMessage id="activate_admin_message" defaultMessage="Are you sure you want to activate this Administrator?" />,
      open: true,
      title: <FormattedMessage id="activate" defaultMessage="Activate" />,
    });
  }

  // Submit modal
  onSubmitadmin = (event, adminId) => {
    event.preventDefault();
    event.target.getElementsByClassName('btn-primary')[0].disabled = true;
    const newAdmin = {
      name: event.target.name.value,
      email: event.target.email.value,
      password: event.target.password.value,
      mobile: event.target.mobile.value, // eslint-disable-line
      is_confirmed: event.target.is_confirmed.checked, // eslint-disable-line
    };

    this.props.requestCreateAdmin(newAdmin, this.onClickCloseModal);
    event.target.getElementsByClassName('btn-primary')[0].disabled = false;
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch (e) {
    const text = e.target.value;

    this.props.requestSearchAdmin(text);
  }

  onClickCloseModal = () => {
    this.setState({
      openEditingAdminModal: false,
      editingAdminObject: {},
      errors: {},
    });
    this.props.setAdministrator({ errors: {} });
  }

  /**
   * Mock method for editing Administrator.
   * Original come from reacttable package.
   * Original === Original row of data.
   */
  onClickEditAdmin = (admin = {}) => {
    this.setState({
      editingAdminObject: admin,
      openEditingAdminModal: true,
    });
  }

  /**
   * Mock method for deactivating Administrator.
   */
  onClickDeactivateAdmin = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeactivateAdmin(id),
      message: <FormattedMessage id="deactivate_admin_message" defaultMessage="Are you sure you want to deactivate this Administrator?" />,
      open: true,
      title: <FormattedMessage id="deactivate" defaultMessage="Deactivate Administrator" />,
    });
  }

  toggleViewInactive = () => {
    this.setState({ viewInactive: !this.state.viewInactive }, () => {
      if (this.state.viewInactive) return this.props.requestInactiveAdmins();
      this.componentDidMount();
    });
  }

  render () {
    const { editingAdminObject, viewInactive } = this.state;
    const content = this.getModalContent(editingAdminObject);
    const { records, pageSizeOptions, pageSize, pages, page } = this.props.administrator;

    return (
      <div>
        <FormModal
          content={content}
          objectId={editingAdminObject.sequence_num}
          title={editingAdminObject.sequence_num ? 'Edit' : 'Add'}
          object={editingAdminObject}
          onSubmit={this.onSubmitadmin}
          open={this.state.openEditingAdminModal}
          onClose={this.onClickCloseModal}
        />
        <Container className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={<FormattedMessage id="administrator" defaultMessage="Administrator" />} subtitle={<FormattedMessage id="admin_desc" defaultMessage="Select or search administrator" />} className="text-sm-left mb-3" />
            <Col sm="4" className="d-flex ml-auto my-auto justify-content-end">
              <Button onClick={() => this.onClickEditAdmin()} className="justify-content-end"><i className="material-icons mr-1">add</i><FormattedMessage id="add_new_admin" defaultMessage="Add New Administrator" /></Button>
              <Button onClick={() => this.toggleViewInactive()} className="mx-3 justify-content-end">{viewInactive ? <FormattedMessage id="view_active" defaultMessage="View Active" /> : <FormattedMessage id="add_inactive" defaultMessage="View Inactive" />}</Button>
            </Col>
          </Row>
          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span><FormattedMessage id="show_rows" defaultMessage="Number of Rows"/></span>
                    <FormSelect
                      size="sm"
                      value={pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  {/* Filters :: Search */}
                  <Col className="file-manager__filters__search d-flex" md="6">
                    <InputGroup seamless size="sm" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput onChange={this.handleFilterSearch} />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  manual
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  columns={this.tableColumns}
                  data={records}
                  pageSize={pageSize}
                  page={page - 1}
                  pages={pages}
                  showPageSizeOptions={false}
                  resizable={false}
                  onPageChange={this.handlePageSizeChange}
                  className="-striped -highlight"
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  administrator: state.administrator,
});

const matDispatchToProps = {
  requestAdmins,
  requestDeleteAdmin,
  setConfirmModalFields,
  requestCreateAdmin,
  requestDeactivateAdmin,
  requestSearchAdmin,
  requestInactiveAdmins,
  requestActivateAdmin,
  setAdministrator,
};

export default connect(mapStateToProps, matDispatchToProps)(Administrator);

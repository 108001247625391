/* eslint-disable no-inline-comments */
/* eslint-disable global-require */
/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { userSignUp } from '../appRedux/thunks/auth';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react';

import { Link } from 'react-router-dom';

class RegisterAccount extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      // eslint-disable-next-line camelcase
      password_confirmation: '',
      // eslint-disable-next-line sort-keys
      name: '',
      // eslint-disable-next-line sort-keys
      company_name: '',
      mobile: '',
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.userSignUp(this.state);
  };

  componentDidUpdate () {
    if (this.props.auth.success) {
      this.props.history.push('/login');
    }
  }

  render () {

    const { email, password, password_confirmation, name, company_name, mobile } = this.state;

    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Logo */}
                {/* <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={require('../images/shards-dashboards-logo.svg')}
                  alt="Shards Dashboards - Register Template"
                /> */}

                {/* Title */}
                <h5 className="auth-form__title text-center mb-4">
                  <FormattedMessage id="register.page.title" defaultMessage="Create New Account"/>
                </h5>

                {/* Form Fields */}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1"><FormattedMessage id="email" defaultMessage="Email address"/></label>
                    <FormInput
                      type="email"
                      name="email"
                      id="exampleInputEmail1"
                      placeholder="E-post"
                      autoComplete="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="exampleInputPassword1"><FormattedMessage id="password" defaultMessage="Password"/></label>
                    <FormInput
                      type="password"
                      name="password"
                      id="exampleInputPassword1"
                      placeholder="Lösenord"
                      // autoComplete="new-password"
                      value={password}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password_confirm"><FormattedMessage id="repeat_password" defaultMessage="Repeat Password"/></label>
                    <FormInput
                      type="password"
                      id="password_confirm"
                      name="password_confirmation"
                      placeholder="Lösenord"
                      autoComplete="Password Confirmation"
                      value={password_confirmation}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="name"><FormattedMessage id="name" defaultMessage="Name"/></label>
                    <FormInput
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Namn"
                      autoComplete="name"
                      value={name}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="company_name"><FormattedMessage id="company_name" defaultMessage="Company name"/></label>
                    <FormInput
                      type="text"
                      id="company_name"
                      name="company_name"
                      placeholder="Företag"
                      autoComplete="company name"
                      value={company_name}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="mobile"><FormattedMessage id="mobile" defaultMessage="Mobile"/></label>
                    <FormInput
                      type="text"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobil"
                      autoComplete="mobile"
                      value={mobile}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <label>
                      <input type="checkbox" className="mr-1" required />
                      I agree with the <a href="/">Terms & Conditions</a>.
                    </label>
                  </FormGroup> */}
                  <Button
                    pill
                    theme="accent"
                    className="d-table mx-auto"
                    type="submit"
                  >
                    <FormattedMessage id="button.create_account" defaultMessage="Create Account"/>
                  </Button>
                </Form>
              </CardBody>
            </Card>

            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/forget-password">
                <FormattedMessage id="button.forgot_password" defaultMessage="Forgot your password?"/>
              </Link>
              <Link to="/login" className="ml-auto">
                <FormattedMessage id="button.signin" defaultMessage="Sign In?"/>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>


    );
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { userSignUp })(RegisterAccount);

/* eslint-disable no-inline-comments */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { setConfirmModalFields } from '../appRedux/actions';
import FormModal from '../components/FormModal';
import PageTitle from '../components/common/PageTitle';
import getModalContent from '../components/ModalContent/EmployeeModalContent';
import { FormattedMessage } from 'react-intl';
import {
  CardBody,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  FormCheckbox,
  ButtonGroup,
  Button,
} from 'shards-react';

import {
  requestEmployees,
  requestCreateEmployee,
  requestUpdateEmployee,
  requestActiveEmployees,
  requestInactiveEmployees,
  requestActivateEmployee,
  requestDeactivateEmployee,
  requestEmployee,
  requestSearchEmployees,
  requestDeleteEmployees,
  requestEmployeePdf,
} from '../appRedux/thunks/employees';

const employeeFields = ['name', 'identification_num', 'phone', 'mobile', 'email', 'relative_name', 'relative_telefone', 'account_number', 'clearing_number', 'bank_name', 'salary', 'salary_per_hour', 'commission', 'other_salary', 'address', 'zip_code', 'area'];

function getTableColumns () {
  return [
    {
      Header: () => (this.state.selectedEmployeeIds.length ? <span onClick={this.onClickDelete} className="text-danger"><FormattedMessage id="delete" defaultMessage="Delete"/></span> : <FormattedMessage id="select" defaultMessage="Select"/>), // eslint-disable-line
      accessor: 'select',
      maxWidth: 60,
      className: 'text-center',
      Cell: row => (
        <FormCheckbox
          className="ml-auto my-auto"
          name="select-service"
          checked={this.state.selectedEmployeeIds.includes(row.original.sequence_num)}
          onChange={() => this.onSelectEmployee(row.original.sequence_num)}
        />),
    },
    {
      Header: <FormattedMessage id="id" defaultMessage="ID"/>,
      accessor: 'sequence_num',
      maxWidth: 60,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="name" defaultMessage="Name"/>,
      accessor: 'name',
      maxWidth: 120,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="personal_number" defaultMessage="Personal Number"/>,
      accessor: 'identification_num',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="email" defaultMessage="Email"/>,
      accessor: 'email',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="phone_number" defaultMessage="Phone"/>,
      accessor: 'phone',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="actions" defaultMessage="Actions"/>,
      accessor: 'actions',
      className: 'text-center',
      minWidth: 140,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          {!row.original.is_active ? (
            <Button theme="white" onClick={() => this.onClickActivate(row.original.sequence_num)}>
              <i className="material-icons">restore</i>
            </Button>)
            : (
            <>
              <Button theme="white" onClick={() => this.onClickEdit(row)}>
                <i className="material-icons">edit</i>
              </Button>
              <Button theme="white" onClick={() => this.onClickDeactivate(row.original.sequence_num)}>
                <i className="material-icons">block</i>
              </Button>
            </>)
          }
          <Button theme="white" onClick={() => this.onClickExportEmployee(row.original.sequence_num)}>
            PDF
          </Button>
          <Button theme="white" onClick={() => this.onClickDelete(row.original.sequence_num)}>
            <i className="material-icons">delete</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}

class Employees extends React.Component {
  constructor () {
    super();
    this.state = {
      editingEmployeeObject: {},
      openAddNewModal: false,
      selectedEmployeeIds: [],
      tableData: [],
      errors: {},
    };

    this.tableColumns = getTableColumns.call(this);
  }

  onChangeModalInput = (e) => {
    const valid = e.target.type === 'number'
      ? parseInt(e.target.value) >= 1 // eslint-disable-line
      : e.target.value.length >= 2;
    const name = e.target.name;

    this.setState({ errors: { ...this.state.errors, [name]: valid } });
  }

  componentDidMount () {
    this.props.requestEmployees();
  }

  onClickOpen = (employeeId) => {
    // this.props.requestemployee(employeeId);
  }

  onClickDeactivate = (employeeId) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeactivateEmployee(employeeId),
      message: <FormattedMessage id="deactivate_employee_message" defaultMessage="Are you sure you want to deactivate the selected employee?"/>,
      open: true,
      title: <FormattedMessage id="deactivate" defaultMessage="Deactivate Employee"/>,
    });
  }

  onClickActivate = (employeeId) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestActivateEmployee(employeeId),
      message: <FormattedMessage id="activate_employee_message" defaultMessage="Are you sure you want to activate the selected employee?"/>,
      open: true,
      title: <FormattedMessage id="activate" defaultMessage="Activate Employee"/>,
    });
  }

  setCollapse = (collapseField) => {
    // this.setState({ [collapseField]: true });
  }

  onClickEdit = (row) => {
    this.setState({ editingEmployeeObject: row.original, openEditingEmployeeModal: true });
  }

  onClickAddNew = () => {
    this.setState({ openAddNewModal: true });
  }

  onSubmitEmployee = (event, employeeId) => {
    const newEmployee = {};

    for (const field of employeeFields) {
      if (event.target[field]) {
        newEmployee[field] = event.target[field].value;
      }
    }

    if (employeeId) {
      this.props.requestUpdateEmployee(newEmployee, employeeId, this.onClickCloseModal);
    } else {
      this.props.requestCreateEmployee(newEmployee, this.onClickCloseModal);
    }
  }

  onClickDelete = (id) => {
    const idArray = (typeof id === 'object') ? this.state.selectedEmployeeIds : [id];

    if (!idArray.length) return;
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeleteEmployees(idArray),
      message: <FormattedMessage id="delete_employee_message" defaultMessage="Are you sure you want to delete the selected employee(s)?"/>,
      open: true,
      title: <FormattedMessage id="delete" defaultMessage="Delete"/>,
    });
  }

  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      openEditingEmployeeModal: false,
      editingEmployeeObject: {},
      errors: {},
    });
  }

  onSelectEmployee = (id) => {
    const { selectedEmployeeIds = [] } = this.state;

    this.setState({
      selectedEmployeeIds: !selectedEmployeeIds.includes(id)
        ? [...selectedEmployeeIds, id] : selectedEmployeeIds.filter(sId => sId !== id),
    });
  }

  handleFilterSearch = (e) => {
    const searchText = e.target.value;

    if (searchText.length <= 1) return this.componentDidMount();
    if (searchText.length < 2) return;
    this.props.requestSearchEmployees(searchText);
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.employees.pageSize;
    const page = !e.target ? (e + 1) : this.props.employees.page;
    this.props.requestEmployees(page, pageSize);
  }

  onClickViewInactive = () => {
    if (!this.state.viewInactive) {
      this.props.requestInactiveEmployees();
    } else {
      this.componentDidMount();
    }
    this.setState({ viewInactive: !this.state.viewInactive });
  }

  onClickExportEmployee = (employeeId) => {
    this.props.requestEmployeePdf(employeeId);
  }

  render () {
    const { editingEmployeeObject, viewInactive, errors } = this.state;
    const { records = [], pageSizeOptions, pageSize, pages, page } = this.props.employees;
    const { user } = this.props.profile;
    const content = getModalContent.call(this, editingEmployeeObject, errors, this.onChangeModalInput, user.role);

    return (
      <div>
        <FormModal size="lg" title={<FormattedMessage id="add" defaultMessage="Add New Employee"/>} content={content} onSubmit={this.onSubmitEmployee} open={this.state.openAddNewModal} onClose={this.onClickCloseModal} />
        <FormModal size="lg" title={<FormattedMessage id="edit" defaultMessage="Edit"/>} content={content} objectId={editingEmployeeObject.sequence_num} service={editingEmployeeObject} onSubmit={this.onSubmitEmployee} open={this.state.openEditingEmployeeModal} onClose={this.onClickCloseModal} />
        <Container className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={<FormattedMessage id="employee_pageTitle" defaultMessage="Employees" />} subtitle={<FormattedMessage id="employee_desc" defaultMessage="Select or search employees below" />} className="text-sm-left mb-3" />
            <Col sm="4" className="d-flex ml-auto my-auto justify-content-end">
              <Button onClick={this.onClickAddNew}><i className="material-icons mr-2">add</i><FormattedMessage id="add_new_employee" defaultMessage="Add New Employee"/></Button>
              <Button className="mx-2" onClick={this.onClickViewInactive}>{viewInactive ? <FormattedMessage id="view_active" defaultMessage="View Active"/> : <FormattedMessage id="view_inactive" defaultMessage="View Inactive"/>}</Button>
            </Col>
          </Row>
          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span><FormattedMessage id="show_rows" defaultMessage="Number of Rows"/></span>
                    <FormSelect
                      size="sm"
                      name="pageSize"
                      value={pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  {/* Filters :: Search */}
                  <Col className="file-manager__filters__search d-flex" md="6">
                    <InputGroup seamless size="sm" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput onChange={this.handleFilterSearch} />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  manual
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  columns={this.tableColumns}
                  data={records}
                  pageSize={pageSize}
                  page={(page - 1)}
                  pages={pages}
                  showPageSizeOptions={false}
                  resizable={false}
                  onPageChange={this.handlePageSizeChange}
                  className="-striped -highlight"
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employees: state.employees,
  profile: state.profile,
});

const mapDispatchToProps = {
  setConfirmModalFields,
  requestEmployees,
  requestCreateEmployee,
  requestUpdateEmployee,
  requestActiveEmployees,
  requestInactiveEmployees,
  requestActivateEmployee,
  requestDeactivateEmployee,
  requestEmployee,
  requestSearchEmployees,
  requestDeleteEmployees,
  requestEmployeePdf,
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);

/* eslint-disable newline-before-return */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Role } from '../utils/roles';

const homeRoutes = { [Role.SuperAdmin]: '/admin/companies', [Role.Owner]: '/', [Role.Admin]: '/' };

const PrivateRoute = ({ component: Component, roles, authToken, userRole, ...rest }) => (
  // eslint-disable-next-line arrow-parens
  <Route {...rest} render={props => {
    if (!authToken) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }
    // eslint-disable-next-line spaced-comment
    //check if user is restricted by role
    // eslint-disable-next-line no-magic-numbers
    if (roles && roles.indexOf(userRole) === -1) {
      // role not authorised so redirect to home page
      return <Redirect to={{ pathname: homeRoutes[localStorage.role] }} />;
    }
    // authorised so return component
    // eslint-disable-next-line semi
    return <Component {...props} />;
  }} />
);

const mapStateToProps = state => ({
  authToken: state.auth.token,
  userRole: state.auth.role,
});

export default connect(mapStateToProps, null)(PrivateRoute);

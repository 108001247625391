/* eslint-disable no-console */
import axios from '../../utils/Api';
import { setBookingsFields } from '../actions';
import { getNotificationMessage } from './utility';
import FileSaver from 'file-saver';

export const downloadItemsPdf = params => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get(`/api/v1/bookings/${params.booking_id}/items_pdf`, { params });
    if (response.data.data) {
      fetch(`data:image/png;base64,${response.data.data.pdf_file.content}`)
        .then(res => res.blob())
        .then(blob => FileSaver.saveAs(blob, response.data.data.pdf_file.name));
    }
  } catch (err) {
    notification.notifyError('Bookings Error', 'Error while getting the pdf');
    console.log(err);
  }
};

export const requestBookings = (page, limit) => async (dispatch, getState) => {
  try {
    const { notification, administrator } = getState();
    const query = `?page=${page ? page : administrator.page}&per_page=${(limit || administrator.pageSize)}`;
    const response = await axios.get(`/api/v1/bookings${query}`);

    if (response.data.errors) {
      return notification.notifyError('Bookings Error ', getNotificationMessage(response.data.errors, 'Booking'));
    }

    dispatch(setBookingsFields(response.data.data));
  } catch (err) {
    console.log(err);
  }
};

export const requestCreateBooking = (details, closeModal) => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.post('/api/v1/bookings', details);

    if (response.data.errors) {
      notification.notifyError('Bookings Error ', getNotificationMessage(response.data.errors, 'Booking'));
    }

    dispatch(setBookingsFields(response.data.data));
    closeModal(response.data.data);
  } catch (err) {
    console.log(err);
  }
};

export const requestUpdateBooking = (details, bookingId, closeModal) => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.put(`/api/v1/bookings/${bookingId}`, details);

    if (response.data.errors) {
      return notification.notifyError('Bookings Error ', getNotificationMessage(response.data.errors, 'Booking'));
    }

    closeModal(response.data.data);
  } catch (err) {
    console.log(err);
  }
};

export const requestBooking = bookingId => async (dispatch, getState) => {
  try {
    dispatch(setBookingsFields({ fetchingBooking: true }));
    const { notification } = getState();
    const response = await axios.get(`/api/v1/bookings/${bookingId}`);

    if (response.data.errors) {
      return notification.notifyError('Bookings Error ', getNotificationMessage(response.data.errors, 'Booking'));
    }

    dispatch(setBookingsFields({ editingBookingObject: response.data.data, fetchingBooking: false }));
  } catch (err) {
    console.log(err);
  }
};

export const requestDeleteBookings = (ids, getBookings) => async (dispatch, getState) => {
  try {
    const response = await axios.delete('/api/v1/bookings/bulk_delete', { data: { bookings: ids } }); // eslint-disable-line

    if (response.data.errors) {
      getState().notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Booking'));
    }

    getBookings();
    getState().notification.notifyError('Deleted', 'Booking(s) deleted');
  } catch (err) {
    console.log(err);
  }
};

export const requstExportBookingPdf = bookingId => async (dispatch, getState) => {
  try {
    const { data: { data: booking } } = await axios.get(`/api/v1/bookings/${bookingId}`);
    const bookingItemsPrices = booking.booking_items.reduce((res, acc) => {
      const { total = 0, vat = 0, rut = 0, toPay = 0 } = getBookingItemsPrice(acc, booking.customer.vat_included);
      return {
        total: Number(total) + res.total,
        vat: Number(vat) + res.vat,
        rut: Number(rut) + res.rut,
        toPay: Number(toPay) + res.toPay,
      };
    }, { total: 0, vat: 0, rut: 0, toPay: 0 });

    const response = await axios.get(`/api/v1/bookings/${bookingId}/send_pdf`, { params: bookingItemsPrices });

    fetch(`data:image/png;base64,${response.data.data.pdf_file.content}`)
      .then(res => res.blob())
      .then(blob => FileSaver.saveAs(blob, response.data.data.pdf_file.name));

    if (response.data.errors) {
      getState().notification.notifyError('Booking error', 'errror while downloading booking');
    }
  } catch (err) {
    console.log(err);
  }
};

export const requstFilteredBookings = params => async (dispatch, getState) => {
  const { notification, bookings } = getState();
  try {
    const parameters = {
      'start': params.start,
      'customer_id': params.customerId || '',
      'start_date': params.startDate || '',
      'end_date': params.endDate || '',
      'employee_id': params.employeeId || '',
      'service_id': params.serviceId || '',
      'status': params.status || '',
      'car_id': params.carId || '',
      'invoice_type': params.invoiceType || '',
      'per_page': params.pageSize || bookings.pageSize,
      'page': params.page || bookings.page,
      'seen_us': params.seenUs,
      'booking_id': params.booking_id,
      'customer_name': params.customer_name,
      'customer_email': params.customer_email,
      'search': params.search,
      'our_reference': params.our_reference,
    };
    const response = await axios.get('/api/v1/bookings/search', { params: parameters });

    if (response.data.errors) {
      notification.notifyError('Search Result', 'No bookings found');
    }
    dispatch(setBookingsFields(response.data.data));
  } catch (err) {
    notification.notifyError('Booking error', 'Error while filtering bookings');
  }
};

export const requestCompleteBooking = (bookingId, getBookings) => async (dispatch, getState) => {
  try {
    const response = await axios.put(`/api/v1/bookings/${bookingId}/mark_completed`);

    if (response.data.errors) {
      getState().notification.notifyError('Booking error', 'Error completing booking');
    }

    getBookings();
  } catch (err) {
    console.log(err);
  }
};

export const requestRestoreBooking = (bookingId, getBookings) => async (dispatch, getState) => {
  try {
    const response = await axios.put(`/api/v1/bookings/${bookingId}/mark_active`);

    if (response.data.errors) {
      getState().notification.notifyError('Booking error', 'Error activating booking');
    }

    getBookings();
  } catch (err) {
    console.log(err);
  }
};

export const requestCancelBooking = (bookingId, getBookings) => async (dispatch, getState) => {
  try {
    const response = await axios.put(`/api/v1/bookings/${bookingId}/mark_canceled`);

    if (response.data.errors) {
      getState().notification.notifyError('Booking error', 'Error cancelling booking');
    }

    getBookings();
  } catch (err) {
    console.log(err);
  }
};

export const requestBookingEmailToCustomer = bookingId => async (dispatch, getState) => {
  const { notification, bookings } = getState();
  try {
    await axios.get(`/api/v1/bookings/${bookingId}/send_email`);
    const records = bookings.records.map(b => b.sequence_num !== bookingId
      ? b : ({ ...b, mailing_history_count: b.mailing_history_count + 1 }));
    dispatch(setBookingsFields({ records }));
  } catch (err) {
    notification.notifyError('Booking error', 'Error sending booking email');
    console.log(err);
  }
};

export const requestDeletedBookings = params => async (dispatch, getState) => {
  const { notification, bookings } = getState();
  const parameters = {
    'start': params.start,
    'customer_id': params.customerId,
    'start_date': params.startDate,
    'end_date': params.endDate,
    'employee_id': params.employeeId,
    'service_id': params.serviceId,
    'status': params.status,
    'car_id': params.carId,
    'invoice_type': params.invoiceType,
    'per_page': params.pageSize || bookings.pageSize,
    'page': params.page || bookings.page,
    'seen_us': params.seenUs,
    'booking_id': params.booking_id,
    'customer_name': params.customer_name,
    'customer_email': params.customer_email,
    'search': params.search,
    'our_reference': params.our_reference,
  };
  try {
    const response = await axios.get('/api/v1/bookings/deleted_bookings', { params: parameters });

    if (response.data.errors) {
      notification.notifyError('Booking error', getNotificationMessage(response.data.errors, 'Booking'));
    }
    dispatch(setBookingsFields(response.data.data));
  } catch (err) {
    notification.notifyError('Booking error', 'Error while getting deleted bookings');
  }
};

function getBookingItemsPrice (item, vatIncluded) {
  if (item.quantity === undefined || item.per_unit === undefined) return { total: 0, toPay: 0 };
  const { per_unit = 0, quantity = 0 } = item;
  if (!vatIncluded && !item.is_house_work) {
    return {
      vat: (Number(per_unit) * 0.25 * Number(quantity)).toFixed(2),
      total: (Number(per_unit) * 1.25 * Number(quantity)).toFixed(2),
      rut: 0,
      toPay: (Number(per_unit) * 1.25 * Number(quantity)).toFixed(2),
    };
  }
  if (vatIncluded && !item.is_house_work) {
    return {
      vat: (((Number(per_unit) * 25) / 125) * Number(quantity)).toFixed(2),
      total: (Number(per_unit) * Number(quantity)).toFixed(2),
      rut: 0,
      toPay: (Number(per_unit) * Number(quantity)).toFixed(2),
    };
  }
  if (!vatIncluded && item.is_house_work) {
    return {
      vat: ((Number(per_unit) * 0.25) * Number(quantity)).toFixed(2),
      total: ((Number(per_unit) * 1.25) * Number(quantity)).toFixed(2),
      rut: ((Number(per_unit) * 1.25 * Number(quantity)) / 2),
      toPay: Math.abs(((Number(per_unit) * 1.25) * Number(quantity)) - ((((Number(per_unit) * 1.25) * Number(quantity)) / 2))).toFixed(2),
    };
  }
  if (vatIncluded && item.is_house_work) {
    return {
      vat: (((Number(per_unit) * 25) / 125) * Number(quantity)).toFixed(2),
      total: (Number(per_unit) * Number(quantity)).toFixed(2),
      rut: Number(per_unit) * Number(quantity) / 2,
      toPay: Math.abs((Number(per_unit) * Number(quantity)) - (((Number(per_unit) * Number(quantity)) / 2))).toFixed(2),
    };
  }
}

import axios from '../../utils/Api';
import { setAdministrator } from '../actions';
import { FETCH_ERROR } from '../../utils/constants';

export const requestAdmins = (page, limit) => async (dispatch, getState) => {
  const { notification, administrator } = getState();
  try {
    const query = `?page=${page ? page : administrator.page}&per_page=${(limit || administrator.pageSize)}`;

    const response = await axios.get(`/api/v1/administrators${query}`);

    if (response.data.errors) {
      notification.notifyError('Admin Error ', response.data.errors);
    }

    dispatch(setAdministrator(response.data.data));
  } catch (err) {
    notification.notifyError('Deactivate ', 'Error while creating admin');
  }
};

// Create one admin
export const requestCreateAdmin = (data, closeModal) => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.post('/api/v1/administrators', { administrator: data });
    if (response.data.errors) {
      return dispatch(setAdministrator({ errors: response.data.errors }));
    }

    notification.notifySuccess('Admin created ', 'Admin is created');
    dispatch(requestAdmins());
    closeModal();
  } catch (err) {
    notification.notifyError('Deactivate ', 'Error while creating admin');
  }
};

// get one admin by id
export const requestAdminDetails = id => async (dispatch) => {
  const response = await axios.post(`/api/v1/administrators/${id}`);

  if (response.data.errors) dispatch({ payload: response.data.errors, type: FETCH_ERROR });

};

/*
 * Deactivate
 * Add sequence_num which you wanna Deactivate
 */
export const requestDeactivateAdmin = id => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/administrators/${id}/deactivate`);

    if (response.data.errors) {
      notification.notifyError('Deactivate ', response.data.errors);
    }

    notification.notifyWarning('Deactivate ', 'Admin is deactivated');
    dispatch(requestInactiveAdmins());
  } catch (err) {
    notification.notifyError('Deactivate ', 'Error while deactivating admin');
  }
};

/*
 * Activate
 * Add sequence_num which you wanna deactivate
 */
export const requestActivateAdmin = id => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/administrators/${id}/activate`);

    if (response.data.errors) {
      notification.notifyError('Activate', response.data.errors);
    }

    notification.notifySuccess('Activated ', 'Admin is Activated');
    dispatch(requestAdmins());
  } catch (err) {
    notification.notifyError('Deactivate ', 'Error while activating admin');
  }
};

// Get all inactivated admin
export const requestInactiveAdmins = (page, limit) => async (dispatch, getState) => {
  const { notification, administrator } = getState();
  try {
    const query = { page: page || administrator.page, per_page: limit || administrator.pageSize };
    const response = await axios.get('/api/v1/administrators/inactive', { params: query });

    if (response.data.errors) dispatch({ payload: response.data.errors, type: FETCH_ERROR });
    dispatch(setAdministrator({ records: response.data.data }));
  } catch (err) {
    notification.notifyError('Search error', 'Error while getting admins');
  }
};

// Async search
export const requestSearchAdmin = text => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get(`/api/v1/administrators/search?query=${text}`);

    if (response.data.data) {
      dispatch(setAdministrator({ records: response.data.data }));
    }
  } catch (err) {
    notification.notifyError('Search error', 'Erro while searching employees');
  }

};

// activities for one admin
export const requestAdminActivities = adminId => async (dispatch) => {
  const response = await axios.get(`/api/v1/administrators/${adminId}/activities`);

  if (response.data.errors) dispatch({ payload: response.data.errors, type: FETCH_ERROR });
};

// Delete one admin by
export const requestDeleteAdmin = adminId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.delete(`/api/v1/administrators/${adminId}`);

    if (response.data.errors) {
      notification.notifyError('Delete error', response.data.errors);
    }

    dispatch(requestAdmins());
    notification.notifyError('Removed ', 'Admin is deleted');
  } catch (err) {
    notification.notifyError('Search error', 'Erro while deleting employee');
  }
};

/*
 * delete multiple admins
 * We are not gonna use that yet
 */
export const requestDeleteAdmins = adminIds => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get('/api/v1/administrators/bulk_delete', { data: { sequence_nums: adminIds } }); //eslint-disable-line

    if (response.data.errors) {
      notification.notifyError('Delete error', response.data.errors);
    }

    notification.notifyError('Removed ', 'Admins deleted');
  } catch (err) {
    notification.notifyError('Search error', 'Erro while deleting employees');
  }
};

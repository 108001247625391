/* eslint-disable no-inline-comments */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import moment from 'moment';
import PageTitle from '../../components/common/PageTitle';
import { setConfirmModalFields } from '../../appRedux/actions';
import {
  CardBody,
  Container,
  Row,
  Card,
  CardHeader,
} from 'shards-react';
import { requestErrors } from '../../appRedux/thunks/superAdmin';

function getTableColumns (profile) {
  return [
    {
      Header: 'Integration',
      accessor: 'integration',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: 'Booking ID',
      accessor: 'booking_id',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: 'Customer ID',
      accessor: 'company_id',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: 'Message',
      accessor: 'error_message',
      manWidth: 350,
      className: 'text-center',
    },
    {
      Header: 'Code',
      accessor: 'error_code',
      maxWidth: 80,
      className: 'text-center',
    },
    {
      Header: 'Date',
      accessor: 'employees_count',
      maxWidth: 130,
      className: 'text-center',
      Cell: row => <span>{moment(row.original.created_at).format('YYYY/MM/DD HH:mm')}</span>,
    },
  ];
}

class Errors extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: {
        page: 1,
        per_page: 20,
      },
    };

    this.tableColumns = getTableColumns.call(this, props.profile);
  }

  componentDidMount () {
    this.props.requestErrors(this.state.filters);
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.superAdmin.integrationErrors.pageSize;
    const page = !e.target ? (e + 1) : this.props.superAdmin.integrationErrors.page;

    this.setState({ filters: { ...this.state.filters, pageSize, page } }, this.componentDidMount);
  }

  render () {
    const { records = [], pages, page } = this.props.superAdmin.integrationErrors;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Errors" subtitle="View companies errors below" className="text-sm-left mb-3" />
        </Row>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                manual
                columns={this.tableColumns}
                data={records}
                pageSize={20}
                page={(page - 1)}
                pages={pages}
                showPageSizeOptions={false}
                resizable={false}
                onPageChange={this.handlePageSizeChange}
                className="-striped -highlight"
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  superAdmin: state.superAdmin,
});

const mapDispatchToProps = {
  requestErrors,
  setConfirmModalFields,
};

export default connect(mapStateToProps, mapDispatchToProps)(Errors);

/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-empty-function */
/* eslint-disable no-inline-comments */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { setConfirmModalFields } from '../appRedux/actions';
import PageTitle from '../components/common/PageTitle';
import InputWithSuggestions from '../components/InputWithSuggestions';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import {
  Badge,
  CardBody,
  FormSelect,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  Button,
} from 'shards-react';
import {
  requestBookings,
  requestUpdateBooking,
  requestCreateBooking,
  requstExportBookingPdf,
  requestDeleteBookings,
  requestBooking,
  requstFilteredBookings,
  requestCompleteBooking,
  requestCancelBooking,
  requestBookingEmailToCustomer,
} from '../appRedux/thunks/bookings';
import { requestSearchServices } from '../appRedux/thunks/services';
import { requestSearchCustomers } from '../appRedux/thunks/customers';
import { requestSearchEmployees } from '../appRedux/thunks/employees';
import { requestSearchItems } from '../appRedux/thunks/items';
import { requestExportExcelBookings, requestFilteredBookings } from '../appRedux/thunks/reports';
import { requestSearchVehicles } from '../appRedux/thunks/vehicles';

// const bookingFields = ['booking_date', 'service', 'housework_type', 'vat_included', 'order_date', 'start_time', 'vehicle', 'desciption', 'employee_count', 'employee_names'];

function getTableColumns (profile) {
  const fields = [
    {
      Header: <FormattedMessage id="id" defaultMessage="ID"/>,
      accessor: 'sequence_num',
      maxWidth: 50,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="status" defaultMessage="Status"/>,
      accessor: 'status',
      maxWidth: 100,
      className: 'text-center',
      Cell: (row) => {
        const themes = { Active: 'secondary', Completed: 'success', Canceled: 'danger' };
        return <Badge pill theme={themes[row.original.status]}>{row.original.status}</Badge>;
      },
    },
    {
      Header: <FormattedMessage id="order_date" defaultMessage="Order Date"/>,
      accessor: 'order_date',
      maxWidth: 120,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="customer" defaultMessage="Customer"/>,
      accessor: 'customer_name',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="service" defaultMessage="Service"/>,
      accessor: d => d.service_name,
      id: 'service-column',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="employee" defaultMessage="Employee(s)"/>,
      accessor: 'employee_name',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="employee_internal" defaultMessage="Internal"/>,
      Cell: row => row.original.internal ? 'Ja' : '-',
      maxWidth: 70,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="time" defaultMessage="Time"/>,
      accessor: 'workinghours',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="vehicle" defaultMessage="Vehicle"/>,
      Cell: row => row.original.car_name ? row.original.car_name.toString() : '',
      className: 'text-center',
    },
  ];

  return fields;
}

class Customers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      editingBookingObject: {},
      openAddNewModal: false,
      openEditingCustomerModal: false,
      selectedBookingIds: [],
      tableData: [],
      filters: { startDate: moment().format('YYYY-MM-DD'), start: 'view_all', pageSize: 30, page: 1, pages: 1 },
      showFilters: true,
    };

    this.tableColumns = getTableColumns.call(this, props.profile);
    this.requestExportExcelBookings = this.requestExportExcelBookings.bind(this);
  }

  componentDidMount () {
    this.filterBookings();
  }

  static getDerivedStateFromProps (props, state) {
    const { per_page = 30, page = 1, total_records = 1 } = props.reports.records || {};

    return {
      filters: {
        ...state.filters,
        pageSize: per_page,
        pages: Math.ceil(total_records / per_page),
        page,
      },
    };
  }

  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      openEditingCustomerModal: false,
      editingBookingObject: {},
    });
  }

  handleFilterSearch = (e) => {
    const searchText = e.target.value;

    if (searchText.length <= 1) return this.componentDidMount();
    if (searchText.length < 2) return;
    this.props.requestFilteredBookings({ ...this.state.filters, advance_search: searchText });
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.state.filters.pageSize;
    const page = !e.target ? (e + 1) : this.state.filters.page;
    const filters = { ...this.state.filters, page, pageSize };
    this.props.requestFilteredBookings(filters);
    this.setState({ filters });
  }

  getSuggestions = (txt, type) => {
    if (type === 'services') this.props.requestSearchServices(txt);
    if (type === 'customers') this.props.requestSearchCustomers(txt);
    if (type === 'employees') this.props.requestSearchEmployees(txt);
    if (type === 'items') this.props.requestSearchItems(txt);
    if (type === 'vehicles') this.props.requestSearchVehicles(txt);
  }

  onSelectFilterCustomerId = (customer) => {
    this.setState({ filters: { ...this.state.filters, customerId: customer.id } });
  }

  onSelectFilterEmployeeId = (employee) => {
    this.setState({ filters: { ...this.state.filters, employeeId: employee.id } });
  }

  onSelectServiceId = (service) => {
    this.setState({ filters: { ...this.state.filters, serviceId: service.id } });
  }

  onSelectFilterCarId = (car) => {
    this.setState({ filters: { ...this.state.filters, carId: car.id } });
  }

  setFilterKey = (e) => {
    this.setState({ filters: { ...this.state.filters, [e.target.name]: e.target.value } });
  }

  filterBookings = () => {
    this.props.requestFilteredBookings(this.state.filters);
  }

  toggleFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  }

  requestExportExcelBookings () {
    this.props.requestExportExcelBookings(this.state.filters);
  }

  render () {
    const { reports, profile: { company = {} }, customers, employees, services, vehicles = {} } = this.props; // eslint-disable-line
    const { records = {}, pageSizeOptions } = reports;
    const { pageSize, page, pages } = this.state.filters;
    return (
      <div>
        <Container fluid className="main-content-container">
          <Row noGutters className="page-header d-flex justify-content-between py-4">
            <PageTitle title={<FormattedMessage id="report" defaultMessage="Reports" />} subtitle={<FormattedMessage id="employee_desc" defaultMessage="Search and download excel report for employees below" />} className="text-sm-left mb-3" />
            <Row noGutters className="page-header py-4">
              <Col sm="12" className="d-flex ml-auto my-auto justify-content-end">
                <button onClick={this.toggleFilters} type="button" className="btn btn-link">{this.state.showFilters ? <FormattedMessage id="hide_filter" defaultMessage="Hide Filter"/> : <FormattedMessage id="show_filter" defaultMessage="Show Filter"/>}</button>
              </Col>
            </Row>
          </Row>
          {this.state.showFilters && (
            <Fragment>
              <Row>
                <Col xs="4" md="3" className="form-group">
                  <label htmlFor="startDate"><FormattedMessage id="start_date" defaultMessage="Start Date"/></label>
                  <FormInput
                    id="startDate"
                    name="startDate"
                    type="date"
                    valid={this.state.filters.startDate}
                    value={this.state.filters.startDate}
                    onChange={this.setFilterKey}
                  />
                </Col>
                <Col xs="4" md="3" className="form-group">
                  <label htmlFor="endDate"><FormattedMessage id="end_date" defaultMessage="End Date" /></label>
                  <FormInput
                    id="endDate"
                    name="endDate"
                    type="date"
                    valid={this.state.filters.endDate}
                    onChange={this.setFilterKey}
                  />
                </Col>
                <Col xs="4" md="2" className="form-group">
                  <label htmlFor="endDate"><FormattedMessage id="status" defaultMessage="Booking Status" /></label>
                  <FormSelect
                    id="status"
                    name="status"
                    valid={this.state.filters.status}
                    onChange={this.setFilterKey}
                  >
                    <option value="view_all">Visa alla</option>
                    <option value="active">Aktiva</option>
                    <option value="completed">Slutförda</option>
                    <option value="canceled">Avbokade</option>
                  </FormSelect>
                </Col>
                <Col xs="4" md="2" className="form-group">
                  <label htmlFor="start"><FormattedMessage id="start" defaultMessage="Start" /></label>
                  <FormSelect name="start" onChange={this.setFilterKey} value={this.state.filters.start}>
                    <option value="view_all">Visa alla</option>
                    <option value="morgon">Morgon / Faststart</option>
                    <option value="lunchtid">Lunchtid</option>
                    <option value="flex">Flex</option>
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col xs="4" md="3" className="form-group">
                  <InputWithSuggestions
                    name="customer_id"
                    label={<FormattedMessage id="customer" defaultMessage="Customer" />}
                    labelName="name"
                    suggestions={customers.records}
                    getSuggestions={txt => this.getSuggestions(txt, 'customers')}
                    onSelect={this.onSelectFilterCustomerId}
                  />
                </Col>
                <Col xs="4" md="3" className="form-group">
                  <InputWithSuggestions
                    name="employee_id"
                    label={<FormattedMessage id="employee" defaultMessage="Employee" />}
                    labelName="name"
                    suggestions={employees.records}
                    getSuggestions={txt => this.getSuggestions(txt, 'employees')}
                    onSelect={this.onSelectFilterEmployeeId}
                  />
                </Col>
                <Col xs="4" md="3" className="form-group">
                  <InputWithSuggestions
                    name="service_id"
                    label={<FormattedMessage id="service" defaultMessage="Service" />}
                    labelName="title"
                    suggestions={services.records}
                    getSuggestions={txt => this.getSuggestions(txt, 'services')}
                    onSelect={this.onSelectServiceId}
                  />
                </Col>
                <Col xs="4" md="3" className="form-group">
                  <InputWithSuggestions
                    name="car_id"
                    label={<FormattedMessage id="vehicle" defaultMessage="Vehicle" />}
                    labelName="name"
                    suggestions={vehicles.records}
                    getSuggestions={txt => this.getSuggestions(txt, 'vehicles')}
                    onSelect={this.onSelectFilterCarId}
                  />
                </Col>
                <Col md="12" className="d-flex ml-auto my-auto justify-content-between mb-3">
                  <Col md="6">
                    <Button className="my-2" outline theme="secondary" onClick={this.requestExportExcelBookings}><i className="material-icons mr-2">cloud_download</i><FormattedMessage id="export_excel_report" defaultMessage="Export Excel Report"/></Button>
                  </Col>
                  <Col md="6" className="d-flex justify-content-end">
                    <div>
                      <Button onClick={this.filterBookings}><i className="material-icons mr-2">search</i>{<FormattedMessage id="search" defaultMessage="Search" />}</Button>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Fragment>)
          }

          <br/>

          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span><FormattedMessage id="show_rows" defaultMessage="Number of Rows"/></span>
                    <FormSelect
                      size="sm"
                      value={this.state.filters.pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  manual
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  columns={this.tableColumns}
                  data={records.records}
                  pages={pages}
                  page={page - 1}
                  pageSize={pageSize}
                  showPageSizeOptions={false}
                  resizable={false}
                  onPageChange={this.handlePageSizeChange}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  services: state.services,
  customers: state.customers,
  reports: state.reports,
  profile: state.profile,
  employees: state.employees,
  items: state.items,
  vehicles: state.vehicles,
});

const mapDispatchToProps = {
  setConfirmModalFields,
  requestBookings,
  requestCreateBooking,
  requestSearchServices,
  requestSearchCustomers,
  requestSearchEmployees,
  requestSearchItems,
  requstExportBookingPdf,
  requestDeleteBookings,
  requestBooking,
  requstFilteredBookings,
  requestUpdateBooking,
  requestCompleteBooking,
  requestCancelBooking,
  requestBookingEmailToCustomer,
  requestExportExcelBookings,
  requestFilteredBookings,
  requestSearchVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);

import React from 'react';
import ProtectedRoute from '../privatRoute/privateRoutes';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Role } from '../utils/roles';
import get from 'lodash/fp/get';
import { parseQueryString } from '../utils/string';
import urls from '../utils/urls';

import Companies from './SuperAdmin/Companies';
import SuperAdminErrors from './SuperAdmin/Errors';
import SuperAdminInvoices from './SuperAdmin/Invoices';

import HeaderNav from '../layouts/HeaderNavigation';

// Route Views
import Calendar from './Calendar';
import Settings from './settings';
import Administrator from './Admin';
import Services from './Services';
import Items from './Items';
import Customers from './Customers';
import Employees from './Employees';
import Bookings from './Bookings';
import Vehicle from './Vehicle';
import Reports from './Reports';
import Invoices from './Invoices';
import Notifications from './Notifications';

import { requestProfile, requestFortnoxIntegration } from '../appRedux/thunks/profile';
import { requestNotifcations } from '../appRedux/thunks/notifications';

class MainContentArea extends React.Component {

  componentDidMount () {
    const { location = {} } = this.props;
    this.props.requestProfile();
    this.props.requestNotifcations();

    const queryString = location.search || get('state.from.search')(location) || window.location.search;
    const queryItems = parseQueryString(queryString);
    if (queryItems.state === urls.fortnoxIntegrationUrlState) {
      this.props.requestFortnoxIntegration({ code: queryItems.code });
    }
  }

  render () {
    if (!this.props.profile.user) return <div className="text-center">Loading ...</div>;

    return (
      <HeaderNav>
        <Switch>
          <ProtectedRoute exact path="/notifications" roles={[Role.Admin, Role.Owner, Role.User]} component={Notifications}/>
          <ProtectedRoute exact path="/invoices" roles={[Role.Admin, Role.Owner]} component={Invoices}/>
          <ProtectedRoute exact path="/reports" roles={[Role.Admin, Role.Owner]} component={Reports}/>
          <ProtectedRoute exact path="/calendar" roles={[Role.Admin, Role.Owner, Role.User]} component={Calendar}/>
          <ProtectedRoute exact path="/vehicle" roles={[Role.Admin, Role.Owner]} component={Vehicle}/>
          <ProtectedRoute exact path="/employees" roles={[Role.Admin, Role.Owner]} component={Employees}/>
          <ProtectedRoute exact path="/administrator" roles={[Role.Owner]} component={Administrator}/>
          <ProtectedRoute exact path="/customer" roles={[Role.Admin, Role.Owner]} component={Customers}/>
          <ProtectedRoute exact path="/settings" roles={[Role.Admin, Role.Owner, Role.User]} component={Settings}/>
          <ProtectedRoute exact path="/services" roles={[Role.Admin, Role.Owner]} component={Services}/>
          <ProtectedRoute exact path="/items" roles={[Role.Admin, Role.Owner]} component={Items}/>
          <ProtectedRoute exact path="/admin/companies" roles={[Role.SuperAdmin]} component={Companies}/>
          <ProtectedRoute exact path="/admin/errors" roles={[Role.SuperAdmin]} component={SuperAdminErrors}/>
          <ProtectedRoute exact path="/admin/invoices" roles={[Role.SuperAdmin]} component={SuperAdminInvoices}/>
          <ProtectedRoute exact path="/" roles={[Role.Admin, Role.Owner]} component={Bookings}/>
        </Switch>
      </HeaderNav>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
});

const mapDispatch = {
  requestProfile,
  requestNotifcations,
  requestFortnoxIntegration,
};

export default connect(mapStateToProps, mapDispatch)(MainContentArea);

import axios from '../../utils/Api';
import { setVehiclesFields } from '../actions';
import { getNotificationMessage } from './utility';

export const requestVehicles = (page = 1, limit = 10) => async (dispatch, getState) => {
  try {
    const { vehicles, notification } = getState();
    const query = `?page=${page ? page : vehicles.page}&per_page=${(limit || vehicles.pageSize)}`;
    const response = await axios.get(`/api/v1/cars${query}`);

    if (response.data.errors) {
      notification.notifyError('Vehicles error', getNotificationMessage(response.data.errors, 'Vehicles'));
    }

    dispatch(setVehiclesFields(response.data.data));
  } catch (err) {
    console.log(err);
  }
};

export const requestCreateVehicle = details => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.post('/api/v1/cars', { car: details });

    if (response.data.errors) {
      notification.notifyError('Vehicles error', getNotificationMessage(response.data.errors, 'Vehicles'));
    }
    dispatch(requestVehicles());
    notification.notifySuccess('Vehicles', 'Vehicle Created');
  } catch (err) {
    console.log(err);
  }
};

export const requestUpdateVehicle = (details, vehicleId) => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.put(`/api/v1/cars/${vehicleId}`, { car: details });

    if (response.data.errors) {
      notification.notifyError('Vehicles error', getNotificationMessage(response.data.errors, 'Vehicles'));
    }
    dispatch(requestVehicles());
    notification.notifySuccess('Vehicles', 'Vehicle updated');
  } catch (err) {
    console.log(err);
  }
};

export const requestDeleteVehicles = ids => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.delete('/api/v1/cars/bulk_delete', { data: { cars: ids } }); // eslint-disable-line

    if (response.data.errors) {
      notification.notifyError('Vehicle error', getNotificationMessage(response.data.errors, 'Vehicles'));
    }

    dispatch(requestVehicles());
    notification.notifySuccess('Deleted', 'Vehicle(s) deleted');
  } catch (err) {
    console.log(err);
  }
};

export const requestSearchVehicles = text => async (dispatch, getState) => {
  try {
    const response = await axios.get(`/api/v1/cars/search?query=${text}`); // eslint-disable-line

    dispatch(setVehiclesFields({ records: response.data.data }));
  } catch (err) {
    console.log(err);
  }
};

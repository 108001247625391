class Urls {
  constructor () {
    const { REACT_APP_ENV } = process.env; // eslint-disable-line
    this.devEnv = REACT_APP_ENV === 'development';
    this.fortnoxClientId = this.devEnv ? 'ccSdg0hH86Di' : 'vzMRg5Jyg24p';
    this.fortnoxRedirectUrl = this.devEnv ? 'https://test.bokning-mex.se' : 'https://bokning-mex.se';
    this.fortnoxIntegrationUrlState = 'f07n0x_au75_c063';
  }

  getApiBaseUrl = () => this.devEnv ? 'https://test-backend.bokning-mex.se' : 'https://backend.bokning-mex.se';

  getFortnoxIntegrationUrl = () => `https://apps.fortnox.se/oauth-v1/auth?client_id=${this.fortnoxClientId}&redirect_uri=${this.fortnoxRedirectUrl}&scope=article%20bookkeeping%20companyinformation%20customer%20invoice%20print&state=${this.fortnoxIntegrationUrlState}&access_type=offline&response_type=code` //eslint-disable-line

}

export default new Urls();

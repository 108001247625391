import React from 'react';
import { setProfile } from '../../../appRedux/actions';

import { connect } from 'react-redux';
class NavbarToggle extends React.Component {

  toggleSideNav = () => {
    this.props.setProfile({ openSideNav: !this.props.profile.openSideNav });
  }

  render () {
    return (
      <nav className="nav">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={this.toggleSideNav} className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center">
          <i className="material-icons">&#xE5D2;</i>
        </a>
      </nav>
    );
  }
}

const mapState = state => ({
  profile: state.profile,
});

export default connect(mapState, { setProfile })(NavbarToggle);

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { setProfile } from '../../../appRedux/actions';
import {
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'shards-react';

class MenuItem extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown () {
    this.setState({
      open: !this.state.open,
    }, () => {
      if (!this.state.open) {
        this.hideSideNavBar();
      }
    });
  }

  hideSideNavBar = () => {
    this.props.setProfile({ openSideNav: false });
  }

  render () {
    const { item } = this.props;

    if (item.items) {
      return (
        <Dropdown className="nav-item" open={this.state.open} toggle={this.toggleDropdown}>
          <DropdownToggle nav caret>
            {item.htmlBefore && (
              <div
                className="d-inline-block"
                dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
              />
            )}
            <FormattedMessage id={item.id || ''} defaultMessage={item.title} />
          </DropdownToggle>
          <DropdownMenu small>
            {item.items.map((subItem, idx) => (
              <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to}>
                <FormattedMessage id={subItem.id} defaultMessage={subItem.title} />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      );
    }

    return (
      <NavItem open={this.state.open} onClick={this.hideSideNavBar}>
        <NavLink exact tag={RouteNavLink} to={item.to} className="text-nowrap">
          {item.htmlBefore && (
            <div
              className="d-inline-block"
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          <FormattedMessage id={item.id} defaultMessage={item.title} />
        </NavLink>
      </NavItem>
    );
  }
}

MenuItem.propTypes = {

  /**
   * The menu item object.
   */
  item: PropTypes.object,
};

const mapState = state => ({
  profile: state.profile,
});

export default connect(mapState, { setProfile })(MenuItem);

import React from 'react';
import { makeData } from '../utils/dummyData';
import { connect } from 'react-redux';
import moment from 'moment';
// Import React Table
import ReactTable from 'react-table';
import InputWithSuggestions from '../components/InputWithSuggestions';
import PageTitle from '../components/common/PageTitle';
import { setConfirmModalFields } from '../appRedux/actions';
import { requestInvoices, requestCreateInvoices } from '../appRedux/thunks/invoices';
import { requestSearchServices } from '../appRedux/thunks/services';
import { requestSearchCustomers, requestExportFortnoxCustomer } from '../appRedux/thunks/customers';
import { requestSearchEmployees } from '../appRedux/thunks/employees';
import { requestSearchItems } from '../appRedux/thunks/items';
import { requestSearchVehicles } from '../appRedux/thunks/vehicles';
import { FormattedMessage } from 'react-intl';
import UiSppinnerButton, { UiButtonSize, UiButtonTheam } from '../components/Ui/UiSppinerButton';

import {
  Col,
  Row,
  Container,
  FormCheckbox,
  Card,
  CardBody,
  FormInput,
  Button,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react';

function getColumns (profile) {
  return [
    {
      Header: <FormattedMessage id="select" defaultMessage="Select"/>,
      accessor: 'select',
      maxWidth: 60,
      className: 'text-center',
      Cell: row => (
        <FormCheckbox
          className="ml-auto my-auto"
          name="select-service"
          checked={this.state.selectedCustomers.includes(row.original.sequence_num)}
          onChange={() => this.onSelectCustomer(row.original.sequence_num)}
        />),
    },
    {
      Header: <FormattedMessage id="customer_id" defaultMessage="Customer ID"/>,
      accessor: 'sequence_num',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="name" defaultMessage="Name"/>,
      accessor: 'name',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="fortnox" defaultMessage="Fortnox ID"/>,
      accessor: 'fortnox_id',
      className: 'text-center',
      maxWidth: 250,
      Cell: row => (row.original.fortnox_id
        ? <span>{row.original.fortnox_id}</span> : (
          <Button theme="white" onClick={() => this.onClickAddToFortnox(row.original.sequence_num)}>
            <FormattedMessage id="export_to_fortnox" defaultMessage="Export"/>
          </Button>)
      ),
    },
  ];
}

function getFilteredBookingsColumns () {
  return [
    {
      Header: <FormattedMessage id="select" defaultMessage="Select"/>,
      accessor: 'select',
      maxWidth: 60,
      className: 'text-center',
      Cell: row => (
        <FormCheckbox
          className="ml-auto my-auto"
          name="select-service"
          checked={this.state.selectedBookings.includes(row.original.sequence_num)}
          onChange={() => this.onSelectBooking(row.original.sequence_num)}
        />),
    },
    {
      Header: <FormattedMessage id="booking_id" defaultMessage="Booking ID"/>,
      accessor: 'sequence_num',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="status" defaultMessage="Status"/>,
      className: 'text-center',
      Cell: row => (
        <span className="d-flex aling-item-center">
          {row.original.status}
          {row.original.integration_errors && (
            <i
              title="Fortnox Error"
              onClick={() => this.showFortnoxError(row.original.integration_errors)}
              className="ml-2 material-icons text-danger cursor-pointer font-large">
                error
            </i>)
          }
        </span>),
    },
    {
      Header: <FormattedMessage id="vat_included" defaultMessage="VAT Included"/>,
      accessor: 'vat_included',
      className: 'text-center',
      Cell: row => row.original.vat_included ? <FormattedMessage id="yes" defaultMessage="Yes"/> : <FormattedMessage id="no" defaultMessage="No"/>,
    },
    {
      Header: <FormattedMessage id="rut_deducation" defaultMessage="RUT Deducation"/>,
      accessor: 'housework_type',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="date" defaultMessage="Date"/>,
      accessor: 'order_date',
      className: 'text-center',
    },
  ];
}
class Invoices extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: makeData(),
      filters: {
        start_date: moment().format('YYYY-MM-DD'),
        invoice_date: moment().format('YYYY-MM-DD'),
      },
      selectedBookings: [],
      selectedCustomers: [],
      isLoading: false,
    };

    this.filteredBookingsColumns = getFilteredBookingsColumns.call(this);
    this.columns = getColumns.call(this, props.profile);
  }

  static getDerivedStateFromProps (nextProps, _prevState) {

    return {
      isLoading: nextProps.application && nextProps.application.isLoading,
    };
  }

  componentDidMount () {
    this.filterBookings();
  }

  showFortnoxError = (err) => {
    this.props.setConfirmModalFields({
      callBack: () => { }, // eslint-disable-line
      message: err,
      open: true,
      title: <FormattedMessage id="invoice_error" defaultMessage="Invoice Error"/>,
    });
  }

  onClickAddToFortnox = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestExportFortnoxCustomer(id, this.filterBookings),
      message: <FormattedMessage id="export_customer_message" defaultMessage="This will export this customer to your Fortnox account and both will be linked." />,
      open: true,
      title: <FormattedMessage id="export" defaultMessage="Export to Fortnox" />,
    });
  }

  onSelectFilterCustomerId = (customer) => {
    this.setState({ filters: { ...this.state.filters, customer_id: customer.id } });
  }

  onSelectFilterEmployeeId = (employee) => {
    this.setState({ filters: { ...this.state.filters, employee_id: employee.id } });
  }

  onSelectServiceId = (service) => {
    this.setState({ filters: { ...this.state.filters, service_id: service.id } });
  }

  onSelectFilterCarId = (car) => {
    this.setState({ filters: { ...this.state.filters, car_id: car.id } });
  }

  setFilterKey = (e) => {
    this.setState({ filters: { ...this.state.filters, [e.target.name]: e.target.value } });
  }

  filterBookings = () => {
    this.props.requestInvoices(this.state.filters);
  }

  getSuggestions = (txt, type) => {
    if (type === 'services') this.props.requestSearchServices(txt);
    if (type === 'customers') this.props.requestSearchCustomers(txt);
    if (type === 'employees') this.props.requestSearchEmployees(txt);
    if (type === 'items') this.props.requestSearchItems(txt);
    if (type === 'vehicles') this.props.requestSearchVehicles(txt);
  }

  onSelectCustomer = (id) => {
    const customerBooking = (this.props.invoices.records || []).find(cb => cb.sequence_num === Number(id));
    const bookingIds = customerBooking.booking_invoices.map(bi => bi.sequence_num);
    const selectedCustomers = this.state.selectedCustomers.filter(bid => bid !== Number(id));
    let selectedBookings = [...this.state.selectedBookings];
    if (selectedCustomers.length === this.state.selectedCustomers.length) {
      selectedBookings = [...selectedBookings, ...bookingIds];
      selectedCustomers.push(id);
    } else {
      selectedBookings = selectedCustomers.filter(bid => !bookingIds.includes(bid));
    }
    this.setState({ selectedBookings, selectedCustomers });
  }

  onSelectBooking = (id) => {
    const selectedBookings = this.state.selectedBookings.filter(bid => bid !== id);
    let selectedCustomers = this.state.selectedCustomers.slice();
    if (selectedBookings.length === this.state.selectedBookings.length) {
      selectedBookings.push(id);
    } else {
      const customerBooking = (this.props.invoices.records || []).find(cb => cb.booking_invoices.find(bi => bi.sequence_num === Number(id)));
      selectedCustomers = this.state.selectedCustomers.filter(bid => bid !== customerBooking.sequence_num);
    }
    this.setState({ selectedBookings, selectedCustomers });
  }

  createInvoices = () => {
    const bookings = this.state.selectedBookings.reduce((res, acc) => {
      const customerBooking = (this.props.invoices.records || []).find(cb => cb.booking_invoices.find(bi => bi.sequence_num === Number(acc)));
      if (!res[customerBooking.sequence_num]) res[customerBooking.sequence_num] = [];
      res[customerBooking.sequence_num].push(acc);
      return res;
    }, {});

    this.props.setConfirmModalFields({
      callBack: () => this.props.requestCreateInvoices(bookings, this.state.filters.invoice_date, this.requestAfterCreatedInvoicesCallback), // eslint-disable-line
      message: <FormattedMessage id="create_invoice_message" defaultMessage="Invoices will be created for selected customers. Are you sure that you want to create invoices?" />,
      open: true,
      title: <FormattedMessage id="confirm" defaultMessage="Confirm Invoice"/>,
    });
  }

  requestAfterCreatedInvoicesCallback = () => {
    this.setState({ selectedBookings: [], selectedCustomers: [] });
    this.filterBookings();
  }

  render () {
    const { records = [] } = this.props.invoices;
    const { employees, customers, services, vehicles } = this.props;
    const { isLoading } = this.state;

    return (
      <Container className="main-content-container">
        <Row noGutters className="page-header d-flex justify-content-between py-4">
          <PageTitle title={<FormattedMessage id="invoices" defaultMessage="EmpInvoicesloyee"/>} subtitle={<FormattedMessage id="invoice_desc" defaultMessage="Select or search Invoices below"/>} className="text-sm-left mb-3" />
        </Row>
        <Row className="mt-3 mb-3">
          <Col xs="4" md="3" className="form-group">
            <label htmlFor="startDate"><FormattedMessage id="start_date" defaultMessage="Start Date"/></label>
            <FormInput
              id="start_date"
              name="start_date"
              type="date"
              onChange={this.setFilterKey}
              value={this.state.filters.start_date}
            />
          </Col>
          <Col xs="4" md="3" className="form-group">
            <label htmlFor="end_date"><FormattedMessage id="end_date" defaultMessage="End Date"/></label>
            <FormInput
              id="end_date"
              name="end_date"
              type="date"
              onChange={this.setFilterKey}
            />
          </Col>
          <Col xs="4" md="3" className="form-group">
            <InputWithSuggestions
              name="customer_id"
              label={<FormattedMessage id="customer" defaultMessage="Customer"/>}
              labelName="name"
              suggestions={customers.records}
              getSuggestions={txt => this.getSuggestions(txt, 'customers')}
              onSelect={this.onSelectFilterCustomerId}
            />
          </Col>
          <Col xs="4" md="3" className="form-group">
            <InputWithSuggestions
              name="employee_id"
              label={<FormattedMessage id="employee" defaultMessage="Employee"/>}
              labelName="name"
              suggestions={employees.records}
              getSuggestions={txt => this.getSuggestions(txt, 'employees')}
              onSelect={this.onSelectFilterEmployeeId}
            />
          </Col>
          <Col xs="4" md="3" className="form-group">
            <InputWithSuggestions
              name="service_id"
              label={<FormattedMessage id="service" defaultMessage="Service"/>}
              labelName="title"
              suggestions={services.records}
              getSuggestions={txt => this.getSuggestions(txt, 'services')}
              onSelect={this.onSelectServiceId}
            />
          </Col>
          <Col xs="4" md="3" className="form-group">
            <InputWithSuggestions
              name="car_id"
              label={<FormattedMessage id="car" defaultMessage="Car"/>}
              labelName="name"
              suggestions={vehicles.records}
              getSuggestions={txt => this.getSuggestions(txt, 'vehicles')}
              onSelect={this.onSelectFilterCarId}
            />
          </Col>
          <Col md="3" className="d-flex ml-auto my-auto justify-content-end mb-3">
            <Button onClick={this.filterBookings}><i className="material-icons mr-2">search</i><FormattedMessage id="search" defaultMessage="Search"/></Button>
          </Col>
        </Row>
        <Card className="">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col md="2">
                  {this.state.selectedBookings.length > 0 &&
                    <UiSppinnerButton
                      isDisabled={isLoading}
                      labelId={'create-invoice'}
                      FormattedMessageID={'sending-invoice'}
                      labelDefaultMessage={'Create Invoice'}
                      onClick={this.createInvoices}
                      buttonSize={UiButtonSize.small}
                      buttonTheam={UiButtonTheam.primary} />}
                  {/* <Button className="align-self-center" onClick={this.createInvoices}>Skapa Faktura</Button> */}
                </Col>

                <Col className="file-manager__filters__rows" md="5">
                  <Row>
                    <Col md="6" className="d-flex justify-content-end align-items-end">
                      <label><FormattedMessage id="invoice_date" defaultMessage="Invoice Date"/></label>
                    </Col>
                    <Col md="6">
                      <FormInput
                        value={this.state.filters.invoice_date}
                        id="invoice_date"
                        name="invoice_date"
                        onChange={e => e.target.value && this.setFilterKey(e)}
                        type="date"
                      />
                    </Col>
                  </Row>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="5">
                  <InputGroup seamless size="sm" className="ml-auto align-self-end">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <Row>
              <Col>
                <ReactTable
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  data={records}
                  columns={this.columns}
                  showPageSizeOptions={false}
                  className="-highlight"
                  SubComponent={row => (
                    <div style={{ paddingLeft: '35px', paddingRight: '40px', paddingTop: '10px', paddingBottom: '10px' }}>
                      <ReactTable
                        data={row.original.booking_invoices}
                        columns={this.filteredBookingsColumns}
                        defaultPageSize={row.original.booking_invoices.length}
                        showPagination={false}
                        showPageSizeOptions={false}
                        resizable={false}
                        className="-highlight"
                      />
                    </div>
                  )}
                />
                <br />
              </Col>

            </Row>
          </CardBody>
        </Card>

      </Container>
    );
  }
}

const matStateToProps = state => ({
  invoices: state.invoices,
  services: state.services,
  customers: state.customers,
  bookings: state.bookings,
  profile: state.profile,
  employees: state.employees,
  items: state.items,
  vehicles: state.vehicles,
  application: state.application,
});

const mapDispatch = {
  setConfirmModalFields,
  requestSearchServices,
  requestSearchCustomers,
  requestSearchEmployees,
  requestSearchItems,
  requestSearchVehicles,
  requestInvoices,
  requestCreateInvoices,
  requestExportFortnoxCustomer,
};

export default connect(matStateToProps, mapDispatch)(Invoices);

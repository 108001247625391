/* eslint-disable no-inline-comments */
/* eslint-disable indent */
import React from 'react';
import get from 'lodash/fp/get';
import InputWithSuggestions from '../../InputWithSuggestions';
import FormModal from '../../FormModal';
import CustomerModalContent from '../CostumerModalContent';
import { Row, Col, Button, FormInput, FormSelect } from 'shards-react';
import { FormattedMessage } from 'react-intl';

const customerFields = ['utc', 'name', 'customer_number', 'is_organisation', 'contact_name', 'email', 'email2', 'mobile', 'telephone', 'fax', 'address', 'address2', 'area', 'city', 'zip_code', 'country', 'door_code', 'additional_info', 'interest_included', 'vat_included', 'payment_terms', 'vat_number', 'vat_type', 'our_reference', 'your_reference', 'invoice_delivery_method', 'invoice_type', 'payment_method', 'latitude', 'longitude'];

export default class Details extends React.PureComponent {
  state = {
    openCustomerModal: false,
  };

  componentDidMount () {
    if (this.props.booking.sequence_num) {
      this.setState({ start: this.props.booking.start });
    }
  }

  static getDerivedStateFromProps (props, state) {
    return { booking: (!state.booking && props.booking.sequence_num)
      ? {
        ...props.booking,
        cars: (props.booking.cars || []).map(c => c.sequence_num) || [],
      } : state.booking };
  }

  openCustomerModal = () => this.setState({ openCustomerModal: true })

  closeCustomerModal = () => this.setState({ openCustomerModal: false })

  removeCar = (ind) => {
    const booking = { ...this.state.booking };
    booking.cars = booking.cars.filter((c, i) => i !== ind);
    booking.car_name = booking.car_name.filter((c, i) => i !== ind);
    this.setState({ booking });
  }

  onSelectBookingCars = (car) => {
    const booking = { cars: [], ...this.state.booking };
    const isCarPresent = booking.cars.find(bId => bId === car.sequence_num);
    if (isCarPresent || !car.sequence_num) return;
    booking.cars = booking.cars || [];
    booking.car_name = booking.car_name || [];
    booking.cars.push(car.sequence_num);
    booking.car_name.push(car.name);

    this.setState({ booking });
  }

  onSelectService = (service) => {
    this.setState({ booking: { ...this.state.booking, service_name: service.title, service_id: service.id } });
  }

  onSelectCustomer = (customer) => {
    this.setState({ booking: { ...this.state.booking, customer_name: customer.name, customer_id: customer.id } });
    this.props.setModalData('customer', customer);
  }

  createCustomerCallback = (customer) => {
    this.setState({ booking: { ...this.state.booking, customer_name: customer.name, customer_id: customer.id } });
    this.props.setModalData('customer', customer);
  }

  onSubmitCustomer = (event) => {
    event.stopPropagation();
    const newCustomer = {};

    for (const field of customerFields) {
      if (event.target[field]) {
        newCustomer[field] = event.target[field].value;
      }
    }

    this.props.requestCreateCustomer(newCustomer, this.createCustomerCallback);
    this.closeCustomerModal();
  }

  onChangeStart = (event) => {
    this.setState({ start: event.target.value });
  }

  onChangeCarCharges = () => {
    this.setState({ booking: { ...this.state.booking, car_charges: !this.state.booking.car_charges } });
  }

  onChangeCongestionCharge = () => {
    this.setState({ booking: { ...this.state.booking, congestion_charge: !this.state.booking.congestion_charge } });
  }

  onChangeWorkType = (e) => {
    this.setState({ booking: { ...this.state.booking, work_type: e.target.value } });
  }

  render () {
    const { active, getSuggestions, servicesSuggestion, customersSuggestion, vehiclesSuggestions, customer = {} } = this.props;
    const { booking = {} } = this.state;
    const customerUc = get('utc')(customer) || get('customer.utc')(this.props);
    const customerExtraComponent = <span>UC: {customerUc ? customerUc : '-'} | Mobil: {customer.mobile ? customer.mobile : '-'} | Telefon: {customer.telephone ? customer.telephone : '-'}</span>;

    return (
      <>
        <FormModal
          size="md"
          content={<CustomerModalContent customer={{}} errors={{}} handleChange={this.onChangeModalInput} locations={this.props.locations} />}
          title={'Lägg till ny kund'}
          onSubmit={this.onSubmitCustomer}
          open={this.state.openCustomerModal}
          onClose={this.closeCustomerModal}
        />
        <Row style={{ overflowY: 'auto', display: active ? 'block' : 'none' }}>
          <Col lg="12">
            <div className="tab-content p-2" id="pills-tabContent">

              <div className="tab-pane fade show active" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
              <Row form>
              <Col xs="12" md="6">
              <label htmlFor="work_type">{<FormattedMessage id="work_type" defaultMessage="Work type"/>}</label>
                    <FormSelect defaultValue={booking.work_type} name="work_type">
                      <option value="moving_services">Flytthjälp</option>
                      <option value="packing_services">Packhjälp</option>
                      <option value="lifting_servies">Bärhjälp</option>
                    </FormSelect>
                </Col>
                <Col xs="12" md="6">
                <InputWithSuggestions
                  name="service_id"
                  label={<FormattedMessage id="service" defaultMessage="Service"/>}
                  labelName="title"
                  object={{ title: booking.service_name, service_id: booking.service_id }}
                  suggestions={servicesSuggestion}
                  getSuggestions={txt => getSuggestions(txt, 'services')}
                  onSelect={this.onSelectService}
                  required
                />
                </Col>
                </Row>
                <Row form>
                  <Col xs="10">
                  <InputWithSuggestions
                    name="customer_id"
                    label={<FormattedMessage id="customer" defaultMessage="Customer"/>}
                    showSuggestionId
                    labelName="name"
                    object={{ name: booking.customer_name, customer_id: booking.customer_id, customer_sequence_num: customer.sequence_num }}
                    suggestions={customersSuggestion}
                    onSelect={this.onSelectCustomer}
                    getSuggestions={txt => getSuggestions(txt, 'customers')}
                    extraComponent={customerExtraComponent}
                    required
                  />
                  </Col>
                  <Col xs="2" className="d-flex align-items-center">
                    <Button type="button" onClick={this.openCustomerModal}>
                      <i class="material-icons">add</i>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12" className="mb-2">
                  {this.props.customer && this.props.customer.warning_icon && (<>
                      <i class="material-icons mr-2 text-danger">warning</i>
                    <span>{this.props.customer.warning}</span></>)
                    }
                  </Col>
                </Row>
                <Row form>
                  {/* Today's date. Autofilled */}
                <Col lg="6" className="form-group">
                    <label htmlFor="booking_date">{<FormattedMessage id="booking_date" defaultMessage="Booking Date"/>}</label>
                    <FormInput
                      id="start_date"
                      name="start_date"
                      type="date"
                      value={booking.start_date || new Date().toLocaleDateString().split('/').reverse().join('-')} // eslint-disable-line
                      required
                      disabled
                    />
                  </Col>
                  <Col lg="6" className="form-group">
                    <label htmlFor="order_date">{<FormattedMessage id="order_date" defaultMessage="Order Date"/>}</label>
                    <FormInput
                      id="order_date"
                      name="order_date"
                      type="date"
                      defaultValue={booking.order_date}
                      required
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col lg="6" className="form-group">
                    <label htmlFor="start">{<FormattedMessage id="start" defaultMessage="Start"/>}</label>
                    <FormSelect name="start" defaultValue={booking.start} onChange={this.onChangeStart}>
                      <option value="morgon">Morgon / Faststart</option>
                      <option value="lunchtid">Lunchtid</option>
                      <option value="flex">Flex</option>
                    </FormSelect>
                  </Col>
                  {(this.state.start === undefined || this.state.start === 'morgon') && (
                    <Col lg="6" className="form-group">
                      <label htmlFor="start_time">{<FormattedMessage id="time" defaultMessage="Time (24 h format)"/>}</label>
                      <FormInput
                        id="start_time"
                        name="start_time"
                        type="time"
                        defaultValue={booking.start_time}
                        required
                      />
                    </Col>)
                  }
                </Row>
                <Row form>
                  <Col lg="6" className="form-group">
                    <label htmlFor="our_reference">{<FormattedMessage id="our_reference" defaultMessage="Our Reference"/>}</label>
                    <FormSelect defaultValue={booking.our_reference} name="our_reference">
                      <option value=""></option>
                      <option value="henrik">Henrik</option>
                      <option value="daniel">Daniel</option>
                      <option value="oscar">Oscar</option>
                      <option value="jesper">Jesper </option>
                      <option value="mikael">Mikael</option>
                      <option value="benjamin">Benjamin</option>
                      <option value="henric">Henric</option>
                      <option value="Philip">Philip</option>
                    </FormSelect>
                  </Col>
                  <Col lg="6" className="form-group">
                    <label htmlFor="employee_price">{<FormattedMessage id="price" defaultMessage="Price"/>}</label>
                    <FormInput
                      id="employee_price"
                      name="employee_price"
                      type="number"
                      defaultValue={booking.employee_price}
                    />
                    <small>{<FormattedMessage id="price_message" defaultMessage="This price will only show in email and PDF"/>}</small>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="form-group">
                    <label htmlFor="car_charges">
                      <input
                        type="checkbox"
                        className="mr-2"
                        name="car_charges"
                        onChange={this.onChangeCarCharges}
                        defaultChecked={booking.car_charges}
                        checked={booking.car_charges}
                        value={booking.car_charges}
                      />
                      <FormattedMessage id="car_charges" defaultMessage="Car milage charges"/>
                    </label>
                  </Col>
                  <Col lg="6" className="form-group">
                    <label htmlFor="congestion_charge">
                      <input
                        type="checkbox"
                        className="mr-2"
                        name="congestion_charge"
                        onChange={this.onChangeCongestionCharge}
                        defaultChecked={booking.congestion_charge}
                        checked={booking.congestion_charge}
                        value={booking.congestion_charge}
                      />
                      <FormattedMessage id="congestion_charge" defaultMessage="Congestion charges"/>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" className="form-group">
                    <InputWithSuggestions
                      label={<FormattedMessage id="vehicle" defaultMessage="Vehicle"/>}
                      labelName="name"
                      object={{}}
                      suggestions={vehiclesSuggestions}
                      getSuggestions={txt => getSuggestions(txt, 'vehicles')}
                      onSelect={this.onSelectBookingCars}
                    />
                    {booking.car_name && booking.car_name.map((carName, index) => (
                      <div key={carName} className="d-flex">
                         <div className="pr-3">
                          {carName}
                         </div>
                        <span className="cursor-pointer" onClick={() => this.removeCar(index)}>
                          <i className="material-icons text-danger">delete</i>
                        </span>
                      </div>))
                    }
                  </Col>
                </Row>
                <Row form>
                <Col lg="12" className="form-group">
                    <label htmlFor="description"><FormattedMessage id="description" defaultMessage="Description"/></label>
                    <FormInput
                      id="notes"
                      name="notes"
                      defaultValue={booking.notes}
                      size="lg"
                    />
                  </Col>
                </Row>
                <Row form>
                <Col lg="12">
                <label htmlFor="damage_history"><FormattedMessage id="history_damages" defaultMessage="History / Damages"/></label>
                  <FormInput
                    id="damage_history"
                    name="damage_history"
                    defaultValue={booking.damage_history}
                    size="lg"
                  />
                </Col>
                </Row>
                <FormInput
                  id="cars"
                  name="cars"
                  value={JSON.stringify(booking.cars || [])}
                  readOnly
                  hidden
                />
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

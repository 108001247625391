export const LAYOUT_TYPES = {
  DEFAULT: 'DEFAULT',
  HEADER_NAVIGATION: 'HEADER_NAVIGATION',
  ICON_SIDEBAR: 'ICON_SIDEBAR',
};

// Auth const
export const SET_AUTH_FIELDS = 'SET_AUTH_FIELDS';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_ROLE = 'user_role';

// Bookings
export const SET_BOOKINGS_FIELDS = 'SET_BOOKINGS_FIELDS';

// Calendar
export const SET_CALENDAR_FIELDS = 'SET_CALENDAR_FIELDS';

// Locations
export const SET_LOCATIONS_FIELDS = 'SET_LOCATIONS_FIELDS';

// Customers
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMERS_FIELDS = 'SET_CUSTOMERS_FIELDS';

// Profile
export const SET_PROFILE = 'SET_PROFILE';

// Confirm Modal
export const SET_CONFIRM_MODAL_FIELDS = 'SET_CONFIRM_MODAL_FIELDS';

// Services
export const SET_SERVICES = 'SET_SERVICES';

// UPDATE
export const UPDATE_USER = 'UPDATE_USER';

// Administrator
export const SET_ADMINISTRATOR = 'SET_ADMINISTRATOR';

// Employees
export const SET_EMPLOYEES_FIELDS = 'SET_EMPLOYEES_FIELDS';

// Notifications
export const SET_NOTIFICATION_FIELDS = 'SET_NOTIFICATION_FIELDS';

// Invoices
export const SET_INVOICES_FIELDS = 'SET_INVOICES_FIELDS';

// Item
export const SET_ITEMS = 'SET_ITEMS';

// Reports
export const SET_REPORTS_FIELDS = 'SET_REPORTS_FIELDS';

// Vehicles
export const SET_VEHICLES_FIELDS = 'SET_VEHICLES_FIELDS';

// Super Admin
export const SET_SUPERADMIN_FIELDS = 'SET_SUPERADMIN_FIELDS';
export const SET_SUPER_ADMIN_ERRORS = 'SET_SUPER_ADMIN_ERRORS';

// Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Application
export const SET_APPLICATION_FIELDS = 'SET_APPLICATION_FIELDS';
export const SET_LOADING = 'SET_LOADING';

import React from 'react';
import { Row, Col, FormInput } from 'shards-react';

export default class InputWithSuggestions extends React.Component {
  constructor () {
    super();
    this.state = {
      text: '',
      selected: {},
      showSuggestions: false,
    };

    this.searchInput = React.createRef();
    this.valueInput = React.createRef();

    this.intervalRef = null;
  }

  onSelectSuggestion = (selected) => {
    this.setState({ selected, showSuggestions: false, text: this.getNameWithId(selected, 'sequence_num') });
    this.props.onSelect && this.props.onSelect(selected);
  }

  handleMouseDown = (e) => {
    if (!e.target.className.includes('suggestion-item')) {
      if (this.searchInput.current) {
        this.searchInput.current.value = '';
        this.valueInput.current.value = '';
      }
      this.setState({ showSuggestions: false });
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleMouseDown);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleMouseDown);
  }

  onChangeInput = (e) => {
    const text = e.target.value;

    if (this.intervalRef) clearTimeout(this.intervalRef);

    if (this.state.text && text.length < this.state.text.length) {
      this.props.onSelect && this.props.onSelect({});
      return this.setState({ text: null, selected: {}, showSuggestions: true });
    }

    this.intervalRef = setTimeout(() => this.props.getSuggestions(text), 1000);
    this.setState({ text, selected: {}, showSuggestions: true });
  }

  onFocus = () => this.props.onFocus && this.setState({ showSuggestions: true }, () => this.props.onFocus(this.state.text))

  getNameWithId = (selected, labelId) => this.props.showSuggestionId && selected[this.props.labelName] ? `${selected[labelId]} - ${selected[this.props.labelName]}` : ''

  render () {
    const { required, suggestions = [], label, name, object = {}, labelName, showSuggestionId } = this.props;
    const { selected, showSuggestions, text } = this.state;

    return (
      <Row form>
        <Col lg="12" className="form-group">
          <label htmlFor={name}>{label}</label>
          <FormInput
            type="text"
            key={name}
            name={labelName || 'name'}
            value={text !== null ? (text || selected[labelName] || (object.customer_sequence_num ? this.getNameWithId(object, 'customer_sequence_num') : object[labelName])) : text}
            onChange={this.onChangeInput}
            autoComplete="off"
            innerRef={this.searchInput}
            required={required}
            onFocus={this.onFocus}
          />
          {this.props.extraComponent && this.props.extraComponent}
          {selected && (
            <FormInput
              type="text"
              id={name}
              name={name}
              value={text !== null ? (object[name] || selected.sequence_num || selected[name]) : null}
              innerRef={this.valueInput}
              required
              readOnly
              hidden
            />)
          }
          {showSuggestions && suggestions.length > 0 && (
            <div className="suggestion-container">
              {suggestions.map((sug, i) => (
                <div key={`suggestion-item-${i}`} onClick={() => this.onSelectSuggestion(sug)} className="p-1 suggestion-item">
                  {showSuggestionId ? `${(selected[name] || sug.sequence_num)} - ` : ''}
                  {(sug.title || sug.name)}
                </div>))
              }
            </div>)
          }
        </Col>
      </Row>
    );
  }
}

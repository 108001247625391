import axios from '../../utils/Api';
import { setInvoicesFields } from '../actions';
import { getNotificationMessage } from './utility';

export const requestInvoices = filters => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get('/api/v1/customers/multiple_invoices', { params: filters });

    if (response.data.errors) {
      notification.notifyError('Invoices Error ', getNotificationMessage(response.data.errors, 'Invoices'));
    }

    dispatch(setInvoicesFields(response.data.data));
  } catch (err) {
    notification.notifyError('Invoices Error ', 'Error while getting invoices');
    console.log(err);
  }
};

export const requestCreateInvoices = (bookingIds, invoice_date, getBookings) => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put('/api/v1/customers/mass_send_invoice', { bookings: bookingIds, invoice_date });

    if (response.data.errors) {
      notification.notifyError('Invoices Error ', getNotificationMessage(response.data.errors, 'Invoices'));
    }

    dispatch(setInvoicesFields(response.data.data));
    getBookings();
  } catch (err) {
    notification.notifyError('Invoices Error ', 'Error while getting invoices');
    console.log(err);
  }
};

/* eslint-disable no-inline-comments */
import React from 'react';
import moment from 'moment';
import InputWithSuggestions from '../../InputWithSuggestions';
import FormModal from '../../FormModal';
import { FormattedMessage } from 'react-intl';
import {
  Row,
  Col,
  Button,
  FormInput,
  FormSelect,
  FormCheckbox,
} from 'shards-react';

const employeeDetail = ['employee_id', 'name', 'comment', 'start_time', 'end_time', 'break_time', 'paid_time', 'workinghours', 'heavy_lift', 'ob_type', 'ob_time', 'parking_ticket', 'sequence_num', 'internal'];
const getArrayFromNumber = (num) => Array(num).fill().map((e, i) => i + 1); // eslint-disable-line

function timeStringToFloat (time) {
  if (!time.split) return time;
  const hoursMinutes = time.split(/[.:]/u);
  const hours = parseFloat(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseFloat(hoursMinutes[1], 10) : 0;

  return (hours + minutes / 60).toFixed(2);
}

export default class Employees extends React.Component {
  constructor () {
    super();
    this.state = {
      openModal: false,
      // employees: null,
      editingEmployee: { start_time: '00:00', end_time: '00:00', break_time: '00:00', internal: false },
      // undetailedEmployees: [],
    };

    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  static getDerivedStateFromProps (props, state) {
    if (props.booking.employees) {
      const undetailedEmployees = state.undetailedEmployees || getArrayFromNumber(Math.abs(props.booking.employees.length - props.booking.employee_count));
      const editingEmployeeObject = props.booking.employees.find(emp => emp.id === parseInt(state.editingEmployee.employee_id, 10)) || {};

      return {
        ...state,
        employees: state.employees || props.booking.bookings_employees.map(emp => ({ ...emp, sequence_num: props.booking.employees.find(bemp => bemp.id === parseInt(emp.employee_id, 10)).sequence_num })),
        editingEmployee: { ...state.editingEmployee, sequence_num: state.editingEmployee.sequence_num || editingEmployeeObject.sequence_num },
        undetailedEmployees: undetailedEmployees, // eslint-disable-line
      };
    }

    return null;
  }

  getFormInputRow = (name, title, type = 'text', handleChange) => ( // eslint-disable-line
    <Row form>
      <Col md="12" className="form-group">
        <label htmlFor={name}>{title}</label>
        <FormInput
          id={name}
          name={name}
          defaultValue={this.state.editingEmployee[name]}
          value={handleChange ? this.state.editingEmployee[name] : undefined}
          valid={this.props.booking[name]}
          invalid={this.props.booking[name] === false}
          onChange={handleChange}
          type={type}
          step={type === 'number' ? 0.01 : undefined}
        />
      </Col>
    </Row>
  );

  getCalculatedTime = (editingEmployee) => {
    const { start_time, end_time, break_time } = editingEmployee;
    const start = moment.utc(start_time, 'HH:mm');
    const end = moment.utc(end_time, 'HH:mm');
    const total = moment.duration(end.diff(start));
    const totalInFormat = moment.utc(Number(total)).format('HH:mm');

    const noStartEnd = parseInt(totalInFormat, 10) === 0 && Number(editingEmployee.paid_time) > 0;

    const workinghours = noStartEnd ? editingEmployee.workinghours : timeStringToFloat(moment.utc(Number(total)).format('HH:mm'));
    let paid_time = noStartEnd ? editingEmployee.paid_time : timeStringToFloat(moment.utc(Number(total)).format('HH:mm'));
    // if (timeStringToFloat(break_time) > 0) {
    paid_time = Math.abs(timeStringToFloat(break_time) - workinghours);
    // } else {
    if (!noStartEnd) total.subtract(break_time, 'HH:mm');
    // }

    this.setState({ editingEmployee: { ...editingEmployee, workinghours, paid_time, break_time } });
  }

  handleChangeTime = (field, value) => {
    const editingEmployee = { ...this.state.editingEmployee, [field]: value };

    if (field === 'paid_time') {
      editingEmployee.start_time = '00:00';
      editingEmployee.end_time = '00:00';
      editingEmployee.workinghours = Math.abs(Number(timeStringToFloat(editingEmployee.break_time)) + Number(editingEmployee.paid_time));
    }

    if (field === 'break_time') {
      const paidTime = editingEmployee.paid_time;
      const breakTime = timeStringToFloat(value);
      if (paidTime <= breakTime) return;
    }
    this.getCalculatedTime(editingEmployee);
  }

  onSelectEmployeeId = (empl) => {
    const editingEmployee = {
      ...this.state.editingEmployee,
      employee_id: empl.id,
      sequence_num: empl.sequence_num,
    };

    this.setState({ editingEmployee });
  }

  handleChange = () => {
    const editingEmployee = {
      ...this.state.editingEmployee,
      internal: !this.state.editingEmployee.internal,
    };
    this.setState({ editingEmployee });
  }

  modalContent = (editingEmployee = {}) => (
    <>
    <InputWithSuggestions
      name="sequence_num"
      label={<FormattedMessage id="employee" defaultMessage="Employee"/>}
      labelName="name"
      object={editingEmployee}
      suggestions={this.props.employeesSuggestions}
      onSelect={this.onSelectEmployeeId}
      getSuggestions={txt => this.props.getSuggestions(txt, 'employees')}
      required
    />
    <FormInput
      id={'employee_id'}
      name={'employee_id'}
      type="text"
      value={editingEmployee.employee_id}
      hidden
      readOnly
    />
    <Row>
      <Col xs="4">
        <FormCheckbox
          name={'internal'}
          checked={editingEmployee.internal}
          onChange={() => this.handleChange()}
        >
          <FormattedMessage id="employee_internal" defaultMessage="Internal"/>
        </FormCheckbox>
      </Col>
    </Row>
    <Row>
      <Col xs="4" className="form-group">
        {this.getFormInputRow('start_time', <FormattedMessage id="start_time" defaultMessage="Start time(hh:mm)"/>, 'time', e => this.handleChangeTime('start_time', e.target.value))}
      </Col>
      <Col xs="4" className="form-group">
        {this.getFormInputRow('end_time', <FormattedMessage id="end_time" defaultMessage="End time(hh:mm)"/>, 'time', e => this.handleChangeTime('end_time', e.target.value))}
      </Col>
      <Col xs="4" className="form-group">
        {this.getFormInputRow('break_time', <FormattedMessage id="break_time" defaultMessage="Break(hh:mm)"/>, 'time', e => this.handleChangeTime('break_time', e.target.value))}
      </Col>
    </Row>

    <Row form>
      <Col md="6" className="form-group">
        <label htmlFor={'paid_time'}><FormattedMessage id="working_time" defaultMessage="Working Time"/></label>
        <FormInput
          id={'paid_time'}
          name={'paid_time'}
          type="number"
          step="0.01"
          defaultValue={editingEmployee.paid_time}
          value={editingEmployee.paid_time}
          onChange={e => this.handleChangeTime('paid_time', e.target.value)}
        />
      </Col>
      <Col md="6" className="form-group">
        <label htmlFor={'workinghours'}><FormattedMessage id="total_time" defaultMessage="Total"/></label>
        <FormInput
          id={'workinghours'}
          name={'workinghours'}
          type="number"
          value={editingEmployee.workinghours}
          readOnly
        />
      </Col>
    </Row>
    <div className="text-title"><FormattedMessage id="heavy_lifiting_parking_ticket" defaultMessage="Heavy Lifting / Parking Ticket"/></div>
    <Row>
      <Col xs="6" >
        {this.getFormInputRow('heavy_lift', <FormattedMessage id="heavy_lift" defaultMessage="Heavy Lifting(kr)"/>, 'number')}
      </Col>
      <Col xs="6" className="form-group">
        {this.getFormInputRow('parking_ticket', <FormattedMessage id="parking_ticket" defaultMessage="Parking Ticket(kr)"/>, 'number')}
      </Col>
    </Row>

    <div className="text-title"><FormattedMessage id="ob" defaultMessage="OB"/></div>
    <Row>
      <Col xs="6" className="form-group">
        <label htmlFor="ob_type"><FormattedMessage id="select_ob" defaultMessage="Select OB type"/></label>
        <FormSelect defaultValue={editingEmployee.ob_type} name="ob_type">
          <option value="OB1">OB1</option>
          <option value="OB2">OB2</option>
          <option value="Helg">Helg</option>
          <option value="Helg OB1">Helg OB1</option>
        </FormSelect>
      </Col>
      <Col xs="6" className="form-group">
        {this.getFormInputRow('ob_time', <FormattedMessage id="time" defaultMessage="Time"/>, 'number')}
      </Col>
    </Row>

    {this.getFormInputRow('comment', <FormattedMessage id="other" defaultMessage="Others"/>)}
    </>
  )

  onClickCloseModal = () => {
    this.setState({
      openModal: false,
      editingEmployee: { start_time: '00:00', end_time: '00:00', break_time: '00:00' },
    });
  }

  onSubmitModal (e) {
    e.stopPropagation();
    e.preventDefault();
    const employee = {};
    const { employees = [], undetailedEmployees = [] } = this.state;
    const undetailed = [...undetailedEmployees];

    for (const field of employeeDetail) {
      if (e.target[field]) {
        employee[field] = field === 'internal' ? e.target[field].checked : e.target[field].value;
      }
    }

    const updatedEmployees = typeof this.state.editingIndex === 'number'
      ? employees.slice() : employees.filter(emp => Number(emp.employee_id) !== Number(employee.employee_id));
    if (typeof this.state.editingIndex === 'number') {
      updatedEmployees[this.state.editingIndex] = employee;
    } else {
      undetailed.pop();
      updatedEmployees[updatedEmployees.length] = employee;
    }

    this.setState(
      { employees: updatedEmployees, editingIndex: null, undetailedEmployees: undetailed },
      () => this.props.setModalData('employees', this.state.employees)
    );
    this.onClickCloseModal();
  }

  onClickEditBooking = (editingEmployee, editingIndex) => {
    this.setState({ editingEmployee, editingIndex, openModal: true });
  }

  onClickAddNewEmployee = () => {
    this.setState({ openModal: true });
  }

  onRemoveEmployee = (empId) => {
    this.setState({ employees: this.state.employees.filter(emp => emp.sequence_num !== empId) }, () => this.props.setModalData('employees', this.state.employees));
  }

  incrementundetailedEmployees = () => {
    const { undetailedEmployees = [] } = this.state;

    const updateUndetailed = [...undetailedEmployees, undetailedEmployees.length + 1];

    this.setState({ undetailedEmployees: updateUndetailed });
  }

  decrementundetailedEmployees = () => {
    const { undetailedEmployees = [] } = this.state;
    const updateUndetailed = [...undetailedEmployees];

    updateUndetailed.pop();
    this.setState({ undetailedEmployees: updateUndetailed });
  }

  render () {
    const { active } = this.props;
    const { employees, undetailedEmployees } = this.state;
    // const editingEmployee = this.getCalculatedTime(this.state.editingEmployee);

    return (
      <>
        <FormModal
          title={<FormattedMessage id="add_edit" defaultMessage="Add/Edit"/>}
          content={this.modalContent(this.state.editingEmployee)}
          onSubmit={this.onSubmitModal}
          open={this.state.openModal}
          onClose={this.onClickCloseModal}
          size="lg"
        />
        <Row style={{ overflowY: 'auto', display: active ? 'block' : 'none' }}>
          <Col lg="12">
            <div className="tab-content p-2" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
                {/* Just add new row when user click here. User will not add employee by clicking on this button */}
                <Row form>
                  <Col lg="6" className="d-flex justify-content-start mb-3">
                    <Button onClick={this.incrementundetailedEmployees}><i className="material-icons mr-2">add</i><FormattedMessage id="add_new_employee" defaultMessage="Add New Employee"/></Button>
                  </Col>
                </Row>
                {/* Add this when user click on Add new Employee, User can remove this row. */}
                {undetailedEmployees && undetailedEmployees.map(te => (
                  <Row key={`undetailed-emp-${te}`}>
                    <Col lg="12" className="d-flex justify-content-between">
                      <span onClick={this.onClickAddNewEmployee} lg="4" className="text-danger cursor-pointer"><FormattedMessage id="choose_employee" defaultMessage="Choose Employee"/></span>
                      <span onClick={this.decrementundetailedEmployees} lg="4" className="text-danger cursor-pointer"><FormattedMessage id="delete" defaultMessage="Remove"/></span>
                    </Col>
                  </Row>))
                }
                <hr></hr>
                {/* Employees added/choosen */}
                <Row>
                  <Col lg="12">
                    <span style={{ fontWeight: 'bold' }}><FormattedMessage id="employee_added" defaultMessage="Employees added"/></span>
                  </Col>
                </Row>
                <hr></hr>
                {employees && employees.map((e, ind) => (
                  <Row key={e.name}>
                    <Col lg="12" className="d-flex justify-content-between">
                      <span lg="4">{e.name}</span>
                      <span className="mr-2" lg="4">{e.start_time} - {e.end_time}</span>
                      <span className="cursor-pointer" onClick={() => this.onClickEditBooking(e, ind)}><i className="material-icons">edit</i></span>
                      <span className="text-danger cursor-pointer" onClick={() => this.onRemoveEmployee(e.sequence_num)} lg="4">Remove</span>
                    </Col>
                  </Row>))
                }
                <FormInput
                  name="employees"
                  value={JSON.stringify((employees || []))}
                  onChange={() => { }} // eslint-disable-line
                  hidden
                />
                <FormInput
                  name="employee_count"
                  value={(undetailedEmployees || []).length + (employees ? employees.length : 0)}
                  onChange={() => { }} // eslint-disable-line
                  hidden
                />
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

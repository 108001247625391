/* eslint-disable no-inline-comments */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import PageTitle from '../../components/common/PageTitle';
import { setConfirmModalFields } from '../../appRedux/actions';
import {
  CardBody,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  ButtonGroup,
  Button,
} from 'shards-react';
import {
  requestInvoices,
  requestDeleteInvoice,
  requestMarkPaidInvoice,
  requestCreateCompanyInvoices,
} from '../../appRedux/thunks/superAdmin';

const pageSizeOptions = [5, 10, 15, 20, 25, 30];

function getTableColumns (profile) {
  return [
    {
      Header: 'Company',
      accessor: 'company_name',
      minWidth: 160,
      className: 'text-center',
    },
    {
      Header: 'Monthly',
      accessor: 'monthly_rent',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: 'Admins',
      accessor: 'admins_rent',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: 'Employees',
      accessor: 'employees_rent',
      maxWidth: 100,
      className: 'text-center',
    },
    {
      Header: 'To Pay',
      accessor: 'total',
      maxWidth: 90,
      className: 'text-center',
    },
    {
      Header: 'Duration',
      accessor: 'duration',
      className: 'text-center',
    },
    {
      Header: 'Status',
      accessor: 'employees_count',
      maxWidth: 70,
      className: 'text-center',
      Cell: row => <span>{row.original.paid ? 'Paid' : 'Unpaid'}</span>,
    },
    {
      Header: 'Actions',
      className: 'text-center',
      maxWidth: 180,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button onClick={() => this.onClickMarkPaid(row.original.id)} theme="white" title="Mark Paid">
            Mark Paid
          </Button>
          <Button onClick={() => this.onClickDelete(row.original.id)} theme="white" title="Delete Invoice">
            Delete
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}

class Invoices extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: {},
    };

    this.tableColumns = getTableColumns.call(this, props.profile);
  }

  componentDidMount () {
    this.requestFilteredInvoices();
  }

  onClickMarkPaid = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestMarkPaidInvoice(id, this.requestFilteredInvoices),
      message: 'Are you sure you want to mark this invoice paid?',
      open: true,
      title: 'Mark Paid',
    });
  }

  onClickDelete = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeleteInvoice(id, this.requestFilteredInvoices),
      message: 'Are you sure you want to delete this invoice?',
      open: true,
      title: 'Delete Invoice',
    });
  }

  onClickCreateInvoices = () => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestCreateCompanyInvoices(), // eslint-disable-line
      message: 'Are you sure you want to create invoices?',
      open: true,
      title: 'Create Invoices',
    });
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.superAdmin.pageSize;
    const page = !e.target ? (e + 1) : this.props.superAdmin.page;
    this.setState({ filters: { ...this.state.filters, page, pageSize } }, this.requestFilteredInvoices);
  }

  requestFilteredInvoices = () => {
    this.props.requestInvoices(this.state.filters);
  }

  render () {
    const { invoices = {} } = this.props.superAdmin;
    const { records = [], pageSize, page, pages } = invoices;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Invoices" subtitle="Select or search invoices below" className="text-sm-left mb-3" />
          <Col md="12" className="d-flex justify-content-end">
            <Button onClick={this.onClickCreateInvoices}>Create Invoices</Button>
          </Col>
        </Row>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    value={pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                manual
                columns={this.tableColumns}
                data={records}
                pageSize={pageSize}
                page={(page - 1)}
                pages={pages}
                showPageSizeOptions={false}
                resizable={false}
                onPageChange={this.handlePageSizeChange}
                className="-striped -highlight"
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  superAdmin: state.superAdmin,
});

const mapDispatchToProps = {
  requestInvoices,
  setConfirmModalFields,
  requestDeleteInvoice,
  requestMarkPaidInvoice,
  requestCreateCompanyInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);

/* eslint-disable no-inline-comments */
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import get from 'lodash/fp/get';
import { setConfirmModalFields } from '../appRedux/actions';
import { requestSearchPlaces } from '../appRedux/thunks/googleMap';
import FormModal from '../components/FormModal';
import PageTitle from '../components/common/PageTitle';
import { FormattedMessage } from 'react-intl';
import CustomerModalContent from '../components/ModalContent/CostumerModalContent';
import {
  CardBody,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  FormCheckbox,
  ButtonGroup,
  Button,
} from 'shards-react';
import {
  requestCustomers,
  requestCreateCustomer,
  requestUpdateCustomer,
  requestActiveCustomers,
  requestInactiveCustomers,
  requestActivateCustomer,
  requestDeactivateCustomer,
  requestCustomer,
  requestSearchCustomers,
  requestDeleteCustomers,
  requestImportFortnoxCustomers,
  requestExportFortnoxCustomer,
  requestUpdateToFortnoxCustomer,
} from '../appRedux/thunks/customers';

const customerFields = ['utc', 'warning', 'name', 'customer_number', 'is_organisation', 'contact_name', 'email', 'email2', 'mobile', 'telephone', 'fax', 'address', 'address2', 'area', 'city', 'zip_code', 'country', 'door_code', 'additional_info', 'payment_terms', 'vat_number', 'vat_type', 'our_reference', 'your_reference', 'invoice_delivery_method', 'invoice_type', 'warning_icon', 'our_reference', 'email_invoice_bcc'];

function getTableColumns (profile) {
  const fields = [
    {
      Header: () => (this.state.selectedCustomerIds.length ? <span onClick={this.onClickDelete} className="text-danger"><FormattedMessage id="delete" defaultMessage="Delete"/></span> : <FormattedMessage id="select" defaultMessage="Select"/>), // eslint-disable-line
      accessor: 'select',
      maxWidth: 60,
      className: 'text-center',
      Cell: row => (
        <FormCheckbox
          className="ml-auto my-auto"
          name="select-service"
          checked={this.state.selectedCustomerIds.includes(row.original.sequence_num)}
          onChange={() => this.onSelectCustomer(row.original.sequence_num)}
        />),
    },
    {
      Header: <FormattedMessage id="id" defaultMessage="ID"/>,
      accessor: 'sequence_num',
      maxWidth: 60,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="name" defaultMessage="Name"/>,
      accessor: 'name',
      maxWidth: 250,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="personal_org_number" defaultMessage="Personl / Org. #"/>,
      accessor: 'customer_number',
      maxWidth: 150,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="mobile_number" defaultMessage="Mobile Number"/>,
      accessor: 'mobile',
      maxWidth: 120,
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="address" defaultMessage="Address"/>,
      accessor: 'address',
      className: 'text-center',
    },
    {
      Header: <FormattedMessage id="actions" defaultMessage="Actions"/>,
      accessor: 'actions',
      className: 'text-center',
      maxWidth: 180,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          {!row.original.is_active ? (
            <Button theme="white" onClick={() => this.onClickActivate(row.original.sequence_num)}>
              <i className="material-icons">restore</i>
            </Button>)
            : (
            <>
              <Button theme="white" title="Edit" onClick={() => this.onClickOpenModal(row.original)}>
                <i className="material-icons">edit</i>
              </Button>
              {profile.company.fortnox_integrated && (
                <Button disabled={!row.original.fortnox_id} theme="white" title="Update to fortnox" onClick={() => this.onClickUpdateToFortnox(row.original.sequence_num)}>
                  <i className="material-icons">update</i>
                </Button>)
              }
              <Button theme="white" title="Block" onClick={() => this.onClickDeactivate(row.original.sequence_num)}>
                <i className="material-icons">block</i>
              </Button>
            </>)
          }
          <Button theme="white" title="Delete customer" onClick={() => this.onClickDelete(row.original.sequence_num)}>
            <i className="material-icons">delete</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  if (get('company.fortnox_integrated')(profile)) {
    const fieldsCopy = [...fields];
    fieldsCopy.splice(fields.length - 2, 0, {
      Header: <FormattedMessage id="fortnox_id" defaultMessage="Fortnox ID"/>,
      accessor: 'fortnox_id',
      className: 'text-center',
      Cell: row => (row.original.fortnox_id
        ? <span>{row.original.fortnox_id}</span> : (
          <Button theme="white" onClick={() => this.onClickAddToFortnox(row.original.sequence_num)}>
            <FormattedMessage id="export_to_fortnox" defaultMessage="Export to Fortnox"/>
          </Button>)
      ),
    });
    return fieldsCopy;
  }

  return fields;
}

class Customers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      editingCustomerObject: {},
      openCustomerModal: false,
      selectedCustomerIds: [],
      tableData: [],
      errors: {},
    };

    this.tableColumns = getTableColumns.call(this, props.profile);
  }

  componentDidMount () {
    this.props.requestActiveCustomers();
  }

  onChangeModalInput = (e) => {
    const valid = e.target.type === 'number'
      ? parseInt(e.target.value) >= 1 // eslint-disable-line
      : e.target.value.length > 2;
    const name = e.target.name;

    this.setState({ errors: { ...this.state.errors, [name]: valid } });
  }

  onClickOpen = (customerId) => {
    this.props.requestCustomer(customerId);
  }

  onClickDeactivate = (customerId) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeactivateCustomer(customerId),
      message: <FormattedMessage id="deactivate_customer_message" defaultMessage="Are you sure you want to deactivate the selected customer?"/>,
      open: true,
      title: <FormattedMessage id="deactivate" defaultMessage="Confirm"/>,
    });
  }

  onClickImportCustomers = () => {
    const import_message = 'This will import all new Fortnox customers into our system and the existing customers which are linked with your Fortnox account will be updated. If you confirm this, we will start importing your Fortnox customers. This could take few minutes, meanwhile you can continue working on other tasks.';
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestImportFortnoxCustomers(),
      message: <FormattedMessage id="import_customer_message" defaultMessage={import_message}/>,
      open: true,
      title: <FormattedMessage id="confirm_import_customer" defaultMessage="Confirm Import"/>,
    });
  }

  onClickActivate = (customerId) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestActivateCustomer(customerId),
      message: <FormattedMessage id="activate_customer_message" defaultMessage="Are you sure you want to activate the selected customer?"/>,
      open: true,
      title: <FormattedMessage id="activate" defaultMessage="Activate"/>,
    });
  }

  setCollapse = (collapseField) => {
    this.setState({ [collapseField]: !this.state[collapseField] });
  }

  onClickOpenModal = (object) => {
    this.setState({
      editingCustomerObject: object,
      openEditingCustomerModal: true,
      openCustomerModal: true,
    });
  }

  onSubmitCustomer = (event, customerId) => {
    const newCustomer = {
      interest_included: event.target.interest_included.checked,
      vat_included: event.target.vat_included.checked,
      latitude: event.target.latitude.value,
      longitude: event.target.longitude.value,
    };

    for (const field of customerFields) {
      if (event.target[field]) {
        newCustomer[field] = event.target[field].value;
      }
    }

    if (customerId) {
      this.props.requestUpdateCustomer(newCustomer, customerId);
    } else {
      this.props.requestCreateCustomer(newCustomer);
    }
    this.onClickCloseModal();
  }

  onClickAddToFortnox = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestExportFortnoxCustomer(id),
      message: <FormattedMessage id="export_customer_message" defaultMessage="This will export this customer to your Fortnox account and both will be linked." />,
      open: true,
      title: <FormattedMessage id="export" defaultMessage="Export to Fortnox" />,
    });
  }

  onClickUpdateToFortnox = (id) => {
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestUpdateToFortnoxCustomer(id),
      message: <FormattedMessage id="confirm_update_fortnox_message" defaultMessage="This will export this customer to your Fortnox account and both will be linked."/>,
      open: true,
      title: <FormattedMessage id="confirm_update_fortnox" defaultMessage="Update Customer"/>,
    });
  }

  onClickDelete = (id) => {
    const idArray = (typeof id === 'object') ? this.state.selectedCustomerIds : [id];

    if (!idArray.length) return;
    this.props.setConfirmModalFields({
      callBack: () => this.props.requestDeleteCustomers(idArray, this.state.viewInactive),
      message: <FormattedMessage id="delete_customer_message" defaultMessage="Are you sure you want to delete the selected customer(s)?"/>,
      open: true,
      title: <FormattedMessage id="delete" defaultMessage="Confirm Delete"/>,
    });
  }

  onClickCloseModal = () => {
    this.setState({
      openCustomerModal: false,
      editingCustomerObject: {},
      errors: {},
    });
  }

  onSelectCustomer = (id) => {
    const { selectedCustomerIds } = this.state;

    this.setState({
      selectedCustomerIds: !selectedCustomerIds.includes(id)
        ? [...selectedCustomerIds, id] : selectedCustomerIds.filter(sId => sId !== id),
    });
  }

  handleFilterSearch = (e) => {
    const searchText = e.target.value;

    if (searchText.length <= 1) return this.componentDidMount();
    if (searchText.length < 2) return;
    this.props.requestSearchCustomers(searchText);
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.customers.pageSize;
    const page = !e.target ? (e + 1) : this.props.customers.page;
    if (this.state.viewInactive) {
      return this.props.requestInactiveCustomers(page, pageSize);
    }
    this.props.requestActiveCustomers(page, pageSize);
  }

  onClickViewInactive = () => {
    if (!this.state.viewInactive) {
      this.props.requestInactiveCustomers();
    } else {
      this.componentDidMount();
    }
    this.setState({ viewInactive: !this.state.viewInactive });
  }

  render () {
    const { editingCustomerObject, viewInactive, errors } = this.state;
    const { customers, profile: { company = {} } } = this.props;
    const { records = [], pages, pageSizeOptions, pageSize, page } = customers;

    return (
      <div>
        <FormModal
          object={editingCustomerObject}
          objectId={editingCustomerObject.sequence_num}
          size="md"
          content={<CustomerModalContent customer={editingCustomerObject} errors={errors} handleChange={this.onChangeModalInput} locations={this.props.locations} requestSearchPlaces={this.props.requestSearchPlaces} />}
          title={editingCustomerObject.sequence_num ? <FormattedMessage id="edit" defaultMessage="Edit"/> : <FormattedMessage id="add_new_customer" defaultMessage="Add New Customer"/>}
          onSubmit={this.onSubmitCustomer}
          open={this.state.openCustomerModal}
          onClose={this.onClickCloseModal}
        />
        <Container className="main-content-container px-4 pb-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={<FormattedMessage id="customers" defaultMessage="Customers"/>} subtitle={<FormattedMessage id="customer_desc" defaultMessage="Select or search Customer below" />} className="text-sm-left mb-3" />
            <Col sm="6" className="d-flex ml-auto my-auto justify-content-end">
              <Button className="mr-2" onClick={() => this.onClickOpenModal({})}><i className="material-icons mr-2">add</i><FormattedMessage id="add_new_customer" defaultMessage="Add New Customer"/></Button>
              {company.fortnox_integrated && <Button className="btn-success mr-2" onClick={this.onClickImportCustomers}><i className="material-icons mr-2">import_export</i><FormattedMessage id="import_fortnox_customer" defaultMessage="Import Fortnox Customers"/></Button>}
              <Button className="" onClick={this.onClickViewInactive}>{viewInactive ? <FormattedMessage id="view_active" defaultMessage="View Active"/> : <FormattedMessage id="view_inactive" defaultMessage="View Inactive"/>}</Button>
            </Col>
          </Row>
          <Card className="p-0">
            <CardHeader className="p-0">
              <Container fluid className="file-manager__filters border-bottom">
                <Row>
                  {/* Filters :: Page Size */}
                  <Col className="file-manager__filters__rows d-flex" md="6">
                    <span><FormattedMessage id="show_rows" defaultMessage="Number of Rows"/></span>
                    <FormSelect
                      size="sm"
                      value={pageSize}
                      onChange={this.handlePageSizeChange}
                    >
                      {pageSizeOptions.map((size, idx) => (
                        <option key={idx} value={size}>
                          {size}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>

                  {/* Filters :: Search */}
                  <Col className="file-manager__filters__search d-flex" md="6">
                    <InputGroup seamless size="sm" className="ml-auto">
                      <InputGroupAddon type="prepend">
                        <InputGroupText>
                          <i className="material-icons">search</i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput onChange={this.handleFilterSearch} />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
            </CardHeader>
            <CardBody className="p-0">
              <div className="">
                <ReactTable
                  manual
                  nextText={<FormattedMessage id="next_page" defaultMessage="Next"/>}
                  previousText={<FormattedMessage id="previous_page" defaultMessage="Previous"/>}
                  pageText={<FormattedMessage id="page" defaultMessage="Page"/>}
                  ofText={<FormattedMessage id="of_text" defaultMessage="of"/>}
                  columns={this.tableColumns}
                  data={records}
                  pageSize={pageSize}
                  page={(page - 1)}
                  pages={pages}
                  showPageSizeOptions={false}
                  resizable={false}
                  onPageChange={this.handlePageSizeChange}
                  className="-striped -highlight"
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customers: state.customers,
  profile: state.profile,
  locations: state.locations,
});

const mapDispatchToProps = {
  setConfirmModalFields,
  requestCustomers,
  requestCreateCustomer,
  requestUpdateCustomer,
  requestActiveCustomers,
  requestInactiveCustomers,
  requestActivateCustomer,
  requestDeactivateCustomer,
  requestCustomer,
  requestSearchCustomers,
  requestDeleteCustomers,
  requestImportFortnoxCustomers,
  requestExportFortnoxCustomer,
  requestUpdateToFortnoxCustomer,
  requestSearchPlaces,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);

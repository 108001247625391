import { SET_LOCATIONS_FIELDS } from '../../utils/constants';

const initialState = {
};

function locations (state = initialState, action) {
  switch (action.type) {
  case SET_LOCATIONS_FIELDS:
    return {
      ...state,
      ...action.payload,
    };

  default: return state;
  }
}

export default locations;

import { SET_PROFILE } from '../../utils/constants';
import { getLanguage } from '../../utils/language';

const initialState = {
  language: getLanguage(),
};

function profile (state = initialState, action) {
  switch (action.type) {
  case SET_PROFILE:
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
}

export default profile;

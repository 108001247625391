/* eslint-disable no-inline-comments */
import React from 'react';
import TagsInput from 'react-tagsinput';
import RichTextEditor from '../../RichTextEditor';
import { FormInput, Row, Col, Button } from 'shards-react';
import optionsInEnglish from '../../../translations/se/options.json';
import optionsInSwedish from '../../../translations/en/options.json';
import { FormattedMessage } from 'react-intl';

const needOptions = ['Verktyg', 'Städ', 'Kartonger', 'Bubbleplast', 'Makulaturpapper', 'Tavelkartong', 'Byte med annan bil', 'kristallkartong', 'Tippkort', 'Klädgaderob', 'Magasinering', 'Med tipKont'];
const workDescriptionOptions = ['Vid avb. senare än 72 h deb. kunden för 3 h', 'Vardag, 50% efter 17:00', 'Swish', 'Skada inom 7 dagar', 'Tunglyftkostnad', 'Försäkringavillkor', 'Mailat oss', '3h minideb.', 'Restid', 'Helg 50% efter 18:00'];
const seenUsOptions = ['Annons', 'Cykel', 'Mäklare', 'Visitkort', 'Bil', 'City Self Storage', 'Eniro', 'Facebook', 'Hitta.se', 'Flyer', 'Tidigare kund', 'Nätet', 'Reco', 'Rekommenderad', 'Lokal & trottoarpratare', 'Landningssida-Uppsala', 'Landningssida-Stockholm'];

function getTagsFromBookingObject (tagObject) {
  if (!tagObject || !tagObject[0]) return null;
  const arr = [];
  for (const k in tagObject[0]) {
    if (tagObject[0][k] && optionsInSwedish[k]) {
      arr.push(optionsInSwedish[k]);
    }
  }
  return arr;
}

export default class Comments extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      notes: '',
      tags: [],
    };
    this.handleTagsChange = this.handleTagsChange.bind(this);
  }

  static getDerivedStateFromProps (props, state) {

    return {
      customerNeeds: state.customerNeeds || getTagsFromBookingObject(props.booking.customer_needs),
      seenUs: state.seenUs || getTagsFromBookingObject(props.booking.seen_us_customers),
      workDescription: state.workDescription || getTagsFromBookingObject(props.booking.workdescriptions),
      notes: state.notes || props.booking.notes,
      customerConversation: state.customerConversation || props.booking.customer_conversation,
    };
  }

  handleClickEvent = (e) => {
    if (!e.target.className.includes('suggestion-item')) {
      this.hideAllSuggestions();
    }
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.active && this.props.active) {
      document.addEventListener('mousedown', this.handleClickEvent);
    } else if (prevProps.active && !this.props.active) {
      document.removeEventListener('mousedown', this.handleClickEvent);
    }
  }

  hideAllSuggestions = () => {
    this.setState({ showNeedsOptions: false, showSeenUsOptions: false, showWorkDescriptionOptions: false });
  }

  setValues = (option, keyName) => {
    const values = this.state[keyName] || [];

    this.setState({ [keyName]: [...values, option] });
  }

  getOptions = (options, selectKeys) => (
    <div className="suggestion-container">
      {options.filter(op => !((this.state[selectKeys] || []).includes(op))).map((op, i) => (
        <div key={`suggestion-item-${i}`} onClick={() => this.setValues(op, selectKeys)} className="p-1 suggestion-item">
          {op}
        </div>))
      }
    </div>
  )

  handleTagsChange = (tags, tagKeys) => {
    if (this.state[tagKeys].length < tags) return;
    this.setState({ [tagKeys]: tags });
  }

  setCustomerConversation = (customerConversation) => {
    this.setState({ customerConversation });
  }

  onClickShowOptions = (keyName) => {
    this.setState({ [keyName]: true });
  }


  getObjectFromArray (valueArray) {
    if (!valueArray || !Array.isArray(valueArray)) return {};
    return valueArray.reduce((res, acc) => {
      res[optionsInEnglish[acc]] = true;
      // res[optionsInEnglish[acc.toLowerCase().replace(/\s/ug, '_')]] = true;
      return res;
    }, {});
  }

  render () {
    const { booking, active } = this.props;

    return (
      <Row style={{ overflowY: 'auto', display: active ? 'block' : 'none' }}>
        <Col lg="12">
          <div className="tab-content p-2" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
              <Row form>
                <Col lg="12" className="form-group">
                  {/* Customer requied */}
                  <Row form onSubmit={this.handleFormSubmit}>
                    <Col lg="10" className="form-group">
                      {/* Customer required */}
                      <label htmlFor="userTags"><FormattedMessage id="customer_required" defaultMessage="Customer Required"/></label>
                      <TagsInput
                        name="customer_needs"
                        placeHolder= "hh a tag"
                        value={this.state.customerNeeds || []}
                        onChange={tags => this.handleTagsChange(tags, 'customerNeeds')}
                        inputProps={{ disabled: true }}
                      />
                      {this.state.showNeedsOptions && this.getOptions(needOptions, 'customerNeeds')}
                    </Col>
                    <Col lg="2" className="d-flex align-self-center">
                      <Button onClick={() => this.onClickShowOptions('showNeedsOptions')}><i className="material-icons">add</i></Button>
                    </Col>
                  </Row>
                  <Row form onSubmit={this.handleFormSubmit}>
                    {/* Seen us */}
                    <Col lg="10" className="form-group">
                      {/* User Tags */}
                      <label htmlFor="userTags"><FormattedMessage id="seen_us" defaultMessage="Seen us"/></label>
                      <TagsInput
                        name="seenUs"
                        value={this.state.seenUs || []}
                        onChange={tags => this.handleTagsChange(tags, 'seenUs')}
                      />
                      {this.state.showSeenUsOptions && this.getOptions(seenUsOptions, 'seenUs')}
                    </Col>
                    <Col lg="2" className="d-flex align-self-center">
                      <Button onClick={() => this.onClickShowOptions('showSeenUsOptions')}><i className="material-icons">add</i></Button>
                    </Col>
                  </Row>
                  {/* Work Description */}
                  <Row form>
                    <Col lg="10" className="form-group">
                      {/* Seeen us */}
                      <label htmlFor="userTags"><FormattedMessage id="work_description" defaultMessage="Work Description"/></label>
                      <TagsInput
                        name="workDescription"
                        value={this.state.workDescription || []}
                        onChange={tags => this.handleTagsChange(tags, 'workDescription')}
                      />
                      {this.state.showWorkDescriptionOptions && this.getOptions(workDescriptionOptions, 'workDescription')}
                    </Col>
                    <Col lg="2" className="d-flex align-self-center">
                      <Button onClick={() => this.onClickShowOptions('showWorkDescriptionOptions')}><i className="material-icons">add</i></Button>
                    </Col>
                  </Row>

                  <Row form>
                    <Col lg="12" className="form-group">
                      <label htmlFor="notes"><FormattedMessage id="customer_conversation" defaultMessage="Customer Conversation"/></label>
                      <RichTextEditor
                        onChange={this.setCustomerConversation}
                        value={this.state.customerConversation || booking.customer_conversation}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormInput
                name="customer_needs"
                value={JSON.stringify(this.getObjectFromArray(this.state.customerNeeds))}
                hidden
              />
              <FormInput
                name="seenUs"
                value={JSON.stringify(this.getObjectFromArray(this.state.seenUs))}
                hidden
              />
              <FormInput
                name="workDescription"
                value={JSON.stringify(this.getObjectFromArray(this.state.workDescription))}
                hidden
              />
              <FormInput
                name="customer_conversation"
                value={this.state.customerConversation}
                hidden
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

/* eslint-disable key-spacing */
import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'shards-react';
import { FormattedMessage } from 'react-intl';

export default class FormModal extends React.Component {

  onSubmit = (event) => {
    event.preventDefault();
    const { objectId } = this.props;

    this.props.onSubmit(event, objectId);
  }

  onToggle = () => { /* Do nothing when click outside modal */ }

  render () {

    const { open, content, title, icon, size, onClose } = this.props;
    // Three size of modal md, lg, and xl
    return (
      <Modal size={size} open={open} toggle={this.onToggle}>
        <ModalHeader style={{ padding: '10px' }}>{title}<i className="material-icons ml-2">{icon}</i></ModalHeader>
        <Form onSubmit={this.onSubmit}>
          <ModalBody style={{ overflowY: 'auto', maxHeight: '70vh', padding: '10px' }}>
            {content}
          </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-link"onClick={onClose}><FormattedMessage id="cancel" defaultMessage="Cancel"/></button>
            <Button type="save"><FormattedMessage id="save_changes" defaultMessage="Submit"/></Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

import axios from '../../utils/Api';
import { setCustomers, setCustomersFields } from '../actions';
import { getNotificationMessage } from './utility';

export const requestCustomers = () => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get('/api/v1/customers');

    if (response.data.errors) {
      notification.notifyError('Get customer ', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(setCustomers(response.data.data));
  } catch (err) {
    notification.notifyError('Get customer ', 'Error while getting customer');
  }
};

export const requestCreateCustomer = (details, callBack) => async (dispatch, getState) => {
  const { notification, customers } = getState();
  try {
    const response = await axios.post('/api/v1/customers', { customer: details });

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }
    if (callBack) callBack(response.data.data);
    const records = customers.records ? [...customers.records, response.data.data] : [response.data.data];
    dispatch(setCustomersFields({ records }));
    notification.notifySuccess('Customer created ', 'Customer is created');
  } catch (err) {
    notification.notifyError('Customer error', 'Error while creating customer');
  }
};

export const requestUpdateCustomer = (details, customerId) => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/customers/${customerId}/`, { customer: details });

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(requestActiveCustomers());
    notification.notifySuccess('Customer updated ', 'Customer is updated');
  } catch (err) {
    notification.notifyError('Customer error', 'Error while updating customer');
  }
};

export const requestActivateCustomer = customerId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/customers/${customerId}/activate`);

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(requestInactiveCustomers());
    notification.notifySuccess('Activated', 'Customer is activated');
  } catch (err) {
    notification.notifyError('Customer error', 'Error while activating customer');
  }
};

export const requestDeactivateCustomer = customerId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/customers/${customerId}/deactivate`);

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(requestActiveCustomers());
    notification.notifyWarning('Deactivated', 'Customer is deactivated');
  } catch (err) {
    notification.notifyError('Customer error', 'Error while deactivating customer');
  }
};

export const requestCustomer = customerId => async (dispatch, getState) => {
  const response = await axios.get(`/api/v1/customers/${customerId}`);

  if (response.data.errors) {
    getState().notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
  }

  dispatch(setCustomersFields(response.data.data));
};

export const requestInactiveCustomers = (page, pageSize) => async (dispatch, getState) => {
  const { notification, customers } = getState();
  try {
    const query = { page: page || customers.page, per_page: pageSize || customers.pageSize };
    const response = await axios.get('/api/v1/customers/inactive_customers', { params: query });

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(setCustomers(response.data.data));
  } catch (err) {
    notification.notifyError('Customer error', 'Error while getting customer');
  }
};

export const requestActiveCustomers = (page, pageSize) => async (dispatch, getState) => {
  const { notification, customers } = getState();
  try {
    const query = `?page=${page || customers.page}&per_page=${pageSize || customers.pageSize}`;
    const response = await axios.get(`/api/v1/customers/active_customers${query}`);

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(setCustomers(response.data.data));
  } catch (err) {
    notification.notifyError('Customer error', 'Error while getting customer');
  }
};

export const requestSearchCustomers = text => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get(`/api/v1/customers/search?query=${text}`);

    if (response.data.data) {
      dispatch(setCustomersFields({ records: response.data.data }));
    }

  } catch (err) {
    notification.notifyError('Customer error', 'Error while searching customer');
  }
};

export const requestActivitiesCustomers = customerId => async (dispatch, getState) => {
  const response = await axios.get(`api/v1//customers/${customerId}/activities`);

  if (response.data.errors) {
    getState().notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
  }

  dispatch(setCustomersFields({ customerActivity: response.data.data }));
};

export const requestDeleteCustomers = (ids, viewInactiveCustomers) => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.delete('/api/v1/customers/bulk_delete', { data: { sequence_nums: ids } }); // eslint-disable-line

    if (response.data.data.connected_customer) {
      notification.notifyError('Customer error', 'Can\'t delete customer(s) with booking.');
    }

    if (response.data.data && response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    if (viewInactiveCustomers) return dispatch(requestInactiveCustomers());
    dispatch(requestActiveCustomers());
    notification.notifyError('Deleted', 'Customer(s) deleted');
  } catch (err) {
    notification.notifyError('Customer error', 'Error while importing customer from fortnox');
  }
};

export const requestImportFortnoxCustomers = () => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get('/api/v1/customers/import');

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(requestActiveCustomers());
  } catch (err) {
    notification.notifyError('Customer error', 'Error while importing customer from fortnox');
  }
};

export const requestExportFortnoxCustomer = (customerSeqId, callBack) => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/customers/${customerSeqId}/export`);

    if (response.data.errors) {
      getState().notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(requestActiveCustomers());
    if (callBack) return callBack();
  } catch (err) {
    notification.notifyError('Customer error', 'Error while exporting customer to fortnox');
  }
};

export const requestUpdateToFortnoxCustomer = customerSeqId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/customers/${customerSeqId}/update_on_fortnox`);

    if (response.data.errors) {
      notification.notifyError('Customer error', getNotificationMessage(response.data.errors, 'Customer'));
    }

    dispatch(requestActiveCustomers());
    getState().notification.notifySuccess('Customer updated', 'Customer is updated');
  } catch (err) {
    notification.notifyError('Customer error', 'Error while updating customer to fortnox');
  }
};

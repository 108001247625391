import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSignIn } from '../appRedux/thunks/auth';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react';

export class Login extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.userSignIn(this.state);
  };

  componentDidUpdate () {
    if ((this.props.token) !== null) {
      this.props.history.push('/');
    }
  }


  render () {

    const { email, password } = this.state;

    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Card className="mt-5">
              <CardBody>
                <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={require('../images/mex-without-description.jpeg')} // eslint-disable-line
                  alt="MEX LOGO"
                />

                <h5 className="auth-form__title text-center mb-4">
                  <FormattedMessage id="login_title" defaultMessage="Access Your Account" />
                </h5>

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">
                      <FormattedMessage id="email" defaultMessage="Email" />
                    </label>
                    <FormInput
                      type="email"
                      id="exampleInputEmail1"
                      placeholder="Enter email"
                      autoComplete="email"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="exampleInputPassword1">
                      <FormattedMessage id="password" defaultMessage="Password" />
                    </label>
                    <FormInput
                      type="password"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      autoComplete="password"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <label><input type="checkbox" className="mr-2" /><FormattedMessage id="remember_me" defaultMessage="Remember me" /></label>
                  </FormGroup> */}
                  <Button
                    pill
                    theme="accent"
                    className="d-table mx-auto"
                    type="submit"
                  >
                    <FormattedMessage id="login_btn" defaultMessage="Access Your Account" />
                  </Button>
                </Form>
              </CardBody>
            </Card>

            <div className="auth-form__meta d-flex mt-4 justify-content-center">
              <Link to="/forget-password">
                <FormattedMessage id="forget_password" defaultMessage="Forgot your password?" />
              </Link>
              {/* <Link to="/register" className="ml-auto">
              Create a new account?
              </Link> */}
            </div>
          </Col>
        </Row>
      </Container>


    );
  }

}


const mapStateToProps = state => ({
  role: state.auth.role,
  token: state.auth.token,
});

export default connect(mapStateToProps, { userSignIn })(Login);

/* eslint-disable no-console */
import axios from '../../utils/Api';
import { setNotificationFields } from '../actions';
import { getNotificationMessage } from './utility';

export const requestNotifcations = (page, per_page) => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const { records = [] } = notification;
    const query = { per_page: per_page || notification.pageSize, page: page || notification.page };
    const response = await axios.get('/api/v1/notifications', { params: query });

    if (response.data.errors) {
      return notification.notifyError('Notification Error ', getNotificationMessage(response.data.errors, 'Notification'));
    }

    if (page) {
      const res = { ...response.data.data, records: [...records, ...response.data.data.records] };

      return dispatch(setNotificationFields(res));
    }

    dispatch(setNotificationFields(response.data.data));

  } catch (err) {
    console.log(err);
  }
};

export const requestNotificationMarkRead = notificationId => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.put(`/api/v1/notifications/${notificationId}/mark_read`);

    if (response.data.errors) {
      return notification.notifyError('Notification Error ', getNotificationMessage(response.data.errors, 'Notification'));
    }

    const records = notification.records.map(n => Number(n.id) === Number(notificationId) ? { ...n, unread: false } : n);

    dispatch(setNotificationFields({ records }));
  } catch (err) {
    console.log(err);
  }
};

export const requestNotificationMarkReadAll = notificationId => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.put('/api/v1/notifications/mark_all_read');

    if (response.data.errors) {
      return notification.notifyError('Notification Error ', getNotificationMessage(response.data.errors, 'Notification'));
    }

    const records = notification.records.map(n => ({ ...n, unread: false }));
    dispatch(setNotificationFields({ records }));

  } catch (err) {
    console.log(err);
  }
};

export const requestDeleteNotification = notificationId => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.delete(`/api/v1/notifications/${notificationId}`);

    if (response.data.errors) {
      return notification.notifyError('Notification Error ', getNotificationMessage(response.data.errors, 'Notification'));
    }

    const records = notification.records.filter(n => Number(n.id) !== Number(notificationId));

    dispatch(setNotificationFields({ records }));

  } catch (err) {
    console.log(err);
  }
};

export const requestDeleteNotificationAll = notificationId => async (dispatch, getState) => {
  try {
    const { notification } = getState();
    const response = await axios.delete('/api/v1/notifications/bulk_delete');

    if (response.data.errors) {
      return notification.notifyError('Notification Error ', getNotificationMessage(response.data.errors, 'Notification'));
    }

    dispatch(requestNotifcations());

  } catch (err) {
    console.log(err);
  }
};

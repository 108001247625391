import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/main.scss';
import { HashRouter } from 'react-router-dom';
// import { history } from './utils/history';
import Routes from './routes';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en.js';
import se from 'react-intl/locale-data/se.js';

import messagesSe from './translations/se';
import messagesEn from './translations/en';
import { connect } from 'react-redux';

addLocaleData([...en, ...se]);

const messages = {
  'se': messagesSe,
  'en': messagesEn,
};

class App extends React.Component {
  render () {
    const { language } = this.props;
    return (
      <IntlProvider locale={language} messages={messages[language]}>
        <HashRouter basename="/">
          <Routes />
        </HashRouter>
      </IntlProvider>
    );
  }
}

const mapState = state => ({
  language: state.profile.language,
});

export default connect(mapState, null)(App);

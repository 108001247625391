import { SET_NOTIFICATION_FIELDS } from '../../utils/constants';
import { getPages } from './utility';

const commonOptions = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: { duration: 2000 },
  dismissable: { click: true },
};

const initialState = {
  addNotification: () => { console.log('notify not initialized'); }, // eslint-disable-line
  notify (type) {
    this.addNotification({ ...type, ...commonOptions });
  },
  notifyError (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'danger' });
  },
  notifySuccess (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'success' });
  },
  notifyInfo (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'info' });
  },
  notifyWarning (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'warning' });
  },
  pageSizeOptions: [5, 10, 15, 20, 25, 30],
  pageSize: 5,
  pages: 1,
  page: 1,
};

/*
 * Example of adding notification
 *  this.notificationDOMRef.current.addNotification({
 *   title: 'Awesomeness',
 *   message: 'Awesome Notifications!',
 *   type: 'success',
 *   insert: 'top',
 *   container: 'top-right',
 *   animationIn: ['animated', 'fadeIn'],
 *   animationOut: ['animated', 'fadeOut'],
 *   dismiss: { duration: 2000 },
 *   dismissable: { click: true },
 *  });
 */

function items (state = initialState, action) {
  switch (action.type) {
  case SET_NOTIFICATION_FIELDS:
    return {
      ...state,
      ...action.payload,
      page: action.payload.page ? parseInt(action.payload.page, 10) : state.page,
      pages: getPages(state, action.payload),
      pageSize: action.payload.per_page ? parseInt(action.payload.per_page, 10) : state.pageSize,
    };
  default:
    return state;
  }
}

export default items;

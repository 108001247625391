import React from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { connect } from 'react-redux';
import { setBookingsFields } from '../appRedux/actions';
import { Container, Card, CardBody } from 'shards-react';
import { requestCalendarData } from '../appRedux/thunks/calendar';
import getCalendarEventsData from '../data/calendar-events-data';
import ModalContent from '../components/ModalContent/BookingsModalContent';
import FormModal from '../components/FormModal';
import { FormattedMessage } from 'react-intl';

import {
  requestUpdateBooking,
  requestBooking,
} from '../appRedux/thunks/bookings';

import { requestSearchServices } from '../appRedux/thunks/services';
import { requestSearchItems } from '../appRedux/thunks/items';
import { requestSearchCustomers, requestCreateCustomer } from '../appRedux/thunks/customers';
import { requestSearchEmployees } from '../appRedux/thunks/employees';
import { requestSearchVehicles } from '../appRedux/thunks/vehicles';

const bookingFields = ['damage_history', 'congestion_charge', 'car_charges', 'start_date', 'customer_conversation', 'notes', 'service', 'housework_type', 'vat_included', 'order_date', 'start_time', 'vehicle', 'desciption', 'employee_count', 'employee_price', 'our_reference', 'start'];

class Calendar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      events: null,
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount () {
    this.props.requestCalendarData();
  }

  componentWillMount () {
    const events = getCalendarEventsData();
    this.setState({
      ...this.state,
      events,
    });
  }

  onClickEdit = (editingBookingObject, event) => {
    this.setState({ editingBookingObject, openAddNewModal: true });
  }

  getSuggestions = (txt, type) => {
    if (type === 'services') this.props.requestSearchServices(txt);
    if (type === 'customers') this.props.requestSearchCustomers(txt);
    if (type === 'employees') this.props.requestSearchEmployees(txt);
    if (type === 'items') this.props.requestSearchItems(txt);
    if (type === 'vehicles') this.props.requestSearchVehicles(txt);
  }

  onSubmitBooking = (event, bookingId) => {
    const employees = JSON.parse(event.target.employees.value);

    const details = {
      service_id: event.target.service_id.value, // eslint-disable-line
      customer_id: event.target.customer_id.value, // eslint-disable-line
      booking: {},
      employeeIds: employees.map(emp => emp.sequence_num),
      bookings_employee: employees, // eslint-disable-line
      addresses: JSON.parse(event.target.addresses.value), // eslint-disable-line
      items: JSON.parse(event.target.items.value), // eslint-disable-line
      customer_need: JSON.parse(event.target.customer_needs.value),
      seenUs: JSON.parse(event.target.seenUs.value),
      workDescription: JSON.parse(event.target.workDescription.value),
      cars: JSON.parse(event.target.cars.value),
    };

    for (const field of bookingFields) {
      if (event.target[field]) {
        details.booking[field] = event.target[field].type === 'checkbox' ? event.target[field].checked : event.target[field].value;
      }
    }

    if (bookingId) {
      this.props.requestUpdateBooking(details, bookingId, this.onClickCloseModal);
    } else {
      this.props.requestCreateBooking(details, this.onClickCloseModal);
    }
  }

  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      openEditingCustomerModal: false,
      editingBookingObject: {},
    });
  }

  render () {
    const { bookings = [] } = this.props.calendar;
    const { editingBookingObject = {} } = this.state;
    const allViews = ['month', 'day', 'agenda'] || Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);
    const localizer = BigCalendar.momentLocalizer(moment);

    return (
      <>
        <FormModal
          size="md"
          content={<ModalContent objectId={editingBookingObject.sequence_num} parentProps={this.props} getSuggestions={this.getSuggestions} locations={this.props.locations} />}
          title={<FormattedMessage id="edit" defaultMessage="Edit"/>}
          onSubmit={this.onSubmitBooking}
          open={this.state.openAddNewModal}
          onClose={this.onClickCloseModal}
          object={editingBookingObject}
          objectId={editingBookingObject.sequence_num}
        />
        <Container fluid className="main-content-container p-4">
          <Card className="p-0">
            <CardBody className="py-4">
              <BigCalendar
                ref={this.calendarRef}
                onSelectEvent={this.onClickEdit}
                events={bookings}
                views={allViews}
                step={60}
                showMultiDayTimes
                titleAccessor="customer_name"
                startAccessor={book => new Date(book.order_date)}
                endAccessor={book => new Date(book.order_date)}
                defaultDate={new Date()}
                localizer={localizer} />
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

const mapDispatch = {
  requestCalendarData,
  setBookingsFields,
  requestUpdateBooking,
  requestBooking,
  requestSearchServices,
  requestSearchItems,
  requestSearchCustomers,
  requestSearchEmployees,
  requestSearchVehicles,
  requestCreateCustomer,
};

const mapState = state => ({
  calendar: state.calendar,
  bookings: state.bookings,
  services: state.services,
  customers: state.customers,
  profile: state.profile,
  employees: state.employees,
  items: state.items,
  vehicles: state.vehicles,
  locations: state.locations,
});

export default connect(mapState, mapDispatch)(Calendar);

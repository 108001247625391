import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_TOKEN_SET,
  USER_ROLE,
} from '../../utils/constants';
import axios from '../../utils/Api';
import { setAuthFields } from '../actions';
import { getNotificationMessage } from './utility';

export const setInitUrl = url => ({
  payload: url,
  type: INIT_URL,
});


// Sign out
export const userSignOut = () => (dispatch) => {
  dispatch({ type: FETCH_START });
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  dispatch({ type: FETCH_SUCCESS });
  dispatch({ type: SIGNOUT_USER_SUCCESS });
  window.location.reload();
};

// Signin

export const userSignIn = ({ email, password }) => (dispatch, getState) => {
  dispatch({ type: FETCH_START });
  axios.post('/api/v1/account/login', {
    email,
    password,
  }).then(({ data }) => {
    if (data.success && !data.errors) {
      axios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      localStorage.setItem('token', data.data.user.token);
      localStorage.setItem('role', data.data.user.role);
      dispatch({ payload: data.data.user.token, type: USER_TOKEN_SET });
      dispatch({ payload: data.data.user.role, type: USER_ROLE });
      window.location.replace('/');
    } else {
      getState().notification.notifyError('Signin failed ', getNotificationMessage(data.errors, 'Login'));
      dispatch(setAuthFields({ errors: data.errors }));
    }
  })
    .catch((error) => {
      getState().notification.notifyError('Signin Failed ', 'Error in signing in');
      if (error.response) dispatch(setAuthFields({ errors: error.response.data.errors }));
    });
};

// sign up

export const userSignUp = ({ email, password, password_confirmation, name, company_name, mobile }) => { //eslint-disable-line
  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    axios.post('/api/v1/account/signup', {
      user: {
        company_name, //eslint-disable-line
        email,
        mobile,
        name,
        password,
        password_confirmation, //eslint-disable-line
      },
    }).then(({ data }) => {
      if (data.success) {
        window.location.replace('/login');
      } else {
        dispatch({ payload: 'Network Error', type: FETCH_ERROR });
      }
    })
      .catch((error) => {
        getState().notification.notifyError('Signup Failed ', error.response.data.errors);
        dispatch(setAuthFields({ errors: error.response.data.errors }));
      });
  };
};

export const requestResetPassword = email => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.post('/users/password', { user: { email } });

    if (response.data.errors) {
      notification.notifyError('Reset Password', getNotificationMessage(response.data.errors));
    }

  } catch (err) {
    notification.notifyError('Calendar', 'Error while requesting to reset password');
  }
};

export const requestChangePassword = (data, history) => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put('/users/password', { user: data });

    if (response.data.errors) {
      return notification.notifyError('Change Password', getNotificationMessage(response.data.errors));
    }

    notification.notifySuccess('Change Password', 'Password changed successfully.');
    history.push('/login');

  } catch (err) {
    notification.notifyError('Change Password', err.message);
  }
};

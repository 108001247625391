/* eslint-disable no-inline-comments */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import PageTitle from '../../components/common/PageTitle';
import { setConfirmModalFields } from '../../appRedux/actions';
import {
  requestCompanies,
  requestLoginCompany,
} from '../../appRedux/thunks/superAdmin';
import {
  CardBody,
  InputGroup,
  FormSelect,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  FormInput,
  ButtonGroup,
  Button,
} from 'shards-react';

function getTableColumns (profile) {
  return [
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 60,
      className: 'text-center',
    },
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: 200,
      className: 'text-center',
    },
    {
      Header: 'Trial',
      Cell: row => <span>{row.original.on_trial ? 'On Trial' : 'On Contract'}</span>,
      maxWidth: 120,
      className: 'text-center',
    },
    {
      Header: 'Contract',
      maxWidth: 120,
      className: 'text-center',
      Cell: row => <span>{row.original.contract_length} Month</span>,
    },
    {
      Header: 'Invoices Pending',
      accessor: 'active_bookings_count',
      className: 'text-center',
    },
    {
      Header: 'Status',
      className: 'text-center',
      Cell: row => <span>{row.original.is_active ? 'Active' : 'Inactive'}</span>,
    },
    {
      Header: 'Employees',
      accessor: 'employees_count',
      className: 'text-center',
    },
    {
      Header: 'Actions',
      className: 'text-center',
      maxWidth: 180,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button theme="white" title="Sign In" onClick={() => this.onClickSignIn(row.original.id)}>
            Sign In
          </Button>
        </ButtonGroup>
      ),
    },
  ];
}

class Companies extends React.Component {
  constructor (props) {
    super(props);
    this.state = { filters: {} };

    this.tableColumns = getTableColumns.call(this, props.profile);
  }

  componentDidMount () {
    this.getCompanies();
  }

  getCompanies = () => {
    this.props.requestCompanies(this.state.filters);
  }

  handlePageSizeChange = (e) => {
    const pageSize = e.target ? e.target.value : this.props.superAdmin.pageSize;
    const page = !e.target ? (e + 1) : this.props.superAdmin.page;
    this.setState({ filters: { ...this.state.filters, page, pageSize } }, this.getCompanies);
  }

  onClickSignIn = (id) => {
    this.props.requestLoginCompany(id);
  }


  render () {
    const { companies: records, pageSize, pageSizeOptions, page, pages } = this.props.superAdmin;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="Companies" subtitle="Select or search companies below" className="text-sm-left mb-3" />
        </Row>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    value={pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                manual
                columns={this.tableColumns}
                data={records}
                pageSize={pageSize}
                page={(page - 1)}
                pages={pages}
                showPageSizeOptions={false}
                resizable={false}
                onPageChange={this.handlePageSizeChange}
                className="-striped -highlight"
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  superAdmin: state.superAdmin,
});

const mapDispatchToProps = {
  requestCompanies,
  requestLoginCompany,
  setConfirmModalFields,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Companies));

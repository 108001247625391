import React from 'react';
import PlacesAutocomplete from '../../PlacesAutocomplete';
import { getZipCode, getArea } from '../../../utils/locations';
import { FormattedMessage } from 'react-intl';
import {
  FormSelect,
  Row,
  Col,
  FormInput,
} from 'shards-react';


export default class CustomerModalContent extends React.Component {
  state = {}

  componentDidMount () {
    if (!this.props.customer.sequence_num) {
      this.setState({
        is_organisation: false,
        payment_terms: 'kontant',
        invoice_type: 'cash',
        our_reference: 'benjamin',
        invoice_delivery_method: 'email',
        interest_included: false,
        vat_included: true,
      });
    } else {
      this.setState({ ...this.props.customer });
    }
  }

  onChangeCheckbox = (keyName) => {
    this.setState({ [keyName]: !this.state[keyName] });
  }

  onChangeCustomerField = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onChangeIsOrganisation = (is_organisation) => {
    this.setState({
      is_organisation,
      payment_terms: is_organisation ? 'days15' : 'kontant',
      invoice_type: is_organisation ? 'invoice' : 'cash',
      our_reference: 'benjamin',
      invoice_delivery_method: 'email',
      interest_included: is_organisation,
      vat_included: !is_organisation,
    });
  }

  getFormInputRow = (name, title) => (
    <Row form>
      <Col md="12" className="form-group">
        <label htmlFor={name}>{title}</label>
        <FormInput
          id={name}
          name={name}
          defaultValue={this.props.customer[name]}
          valid={this.props.errors[name]}
          invalid={this.props.errors[name] === false}
          onChange={this.props.handleChange}
        />
      </Col>
    </Row>
  );

  getLocationSuggestions = (txt) => {
    if (txt.length <= 3) return;
    this.props.requestSearchPlaces(txt);
  }

  onSelectAddress = (lat_long, address, fullAddress) => {
    const zip_code = getZipCode(fullAddress);
    const addresses = address.split(',');

    this.setState({
      address: addresses[0],
      latitude: lat_long.lat,
      longitude: lat_long.lng,
      zip_code,
      area: addresses[1] || getArea(address),
    });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render () {
    const { customer, errors, handleChange, locations = {} } = this.props;
    const { records = [] } = locations;
    const placesSuggestions = records.map(sug => ({ ...sug, title: sug.description }));

    return (
      <Row style={{ overflowY: 'auto' }}>
        <Col lg="12">
          <Row form>
            <Col lg="12" className="form-group">
              <label htmlFor="name"><FormattedMessage id="name" defaultMessage="Name"/> </label>
              <FormInput
                id="Name"
                name="name"
                defaultValue={customer.name}
                valid={errors.name}
                invalid={errors.name === false}
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
          <Row form>
            <Col lg="10">
              {this.getFormInputRow('warning', <FormattedMessage id="warning" defaultMessage="Warning"/>, customer)}
            </Col>
            <Col lg="2" form className="d-flex align-items-center pt-2">
              <label className="text-nowrap" htmlFor="warning_icon">
                <input
                  type="checkbox"
                  name="warning_icon"
                  className="mr-2"
                  onClick={() => this.onChangeCheckbox('warning_icon')}
                  checked={this.state.warning_icon !== undefined ? this.state.warning_icon : customer.warning_icon}
                  value={this.state.warning_icon !== undefined ? this.state.warning_icon : customer.warning_icon}
                />
                <i class="material-icons mr-2 text-danger">warning</i>
              </label>
            </Col>
          </Row>
          <Row form>
            <Col lg="6" className="form-group d-flex justify-content-between">
              <label htmlFor="is_organisation">
                <input
                  type="radio"
                  className="mr-2"
                  onClick={() => this.onChangeIsOrganisation(false)}
                  checked={this.state.is_organisation !== undefined ? !this.state.is_organisation : !customer.is_organisation}
                  value={this.state.is_organisation !== undefined ? !this.state.is_organisation : !customer.is_organisation}
                />
                <FormattedMessage id="is_individual" defaultMessage="Is Individual"/>
              </label>
              <label htmlFor="is_organisation">
                <input
                  type="radio"
                  className="mr-2"
                  onClick={() => this.onChangeIsOrganisation(true)}
                  checked={this.state.is_organisation !== undefined ? this.state.is_organisation : customer.is_organisation}
                  value={this.state.is_organisation !== undefined ? this.state.is_organisation : customer.is_organisation}
                />
                <FormattedMessage id="is_organisation" defaultMessage="Is Organisation"/>
              </label>
            </Col>
          </Row>
          <Row form>
            <Col lg="6">
              {this.getFormInputRow('customer_number', <FormattedMessage id="personal_org_number" defaultMessage="Personal/Org. #"/>, customer)}
            </Col>
            <Col lg="6">
              {this.getFormInputRow('contact_name', <FormattedMessage id="contact_person" defaultMessage="Contact Person"/>, customer)}
            </Col>
          </Row>
          <Row form>
            <Col lg="6">
              <label htmlFor="email"><FormattedMessage id="invoice_email" defaultMessage="Invoice E-mail"/></label>
              <FormInput
                id="email"
                name="email"
                type="email"
                defaultValue={customer.email}
              />
            </Col>
            <Col lg="6">
              {this.getFormInputRow('email2', <FormattedMessage id="email2" defaultMessage="Email 2"/>, customer)}
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="email_invoice_bcc">
                    <FormattedMessage id="email_invoice_bcc" defaultMessage="EmailInvoiceBCC"/>
                  </label>
                  <FormInput
                    disabled
                    id="email_invoice_bcc"
                    name="email_invoice_bcc"
                    value="kvitto@mex.se"
                    onChange={this.props.handleChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="utc"><FormattedMessage id="uc" defaultMessage="UC"/></label>
              <FormSelect
                id="utc"
                name="utc"
                defaultValue={customer.utc}
              >
                <option value={null}></option>
                <option value="Ok">Ok</option>
                <option value="Ej Ok">Ej Ok</option>
              </FormSelect>
            </Col>
          </Row>
          <Row form>
            <Col lg="6">
              <label htmlFor="mobile"><FormattedMessage id="mobile" defaultMessage="Mobile"/></label>
              <FormInput
                type="text"
                id="mobile"
                name="mobile"
                defaultValue={customer.mobile}
                valid={errors.mobile}
                invalid={errors.mobile === false}
                onChange={handleChange}
              />
            </Col>
            <Col lg="6">
              <label htmlFor="mobile"><FormattedMessage id="telephone" defaultMessage="Telephone"/></label>
              <FormInput
                type="text"
                id="telephone"
                name="telephone"
                defaultValue={customer.telephone}
                valid={errors.telephone}
                invalid={errors.telephone === false}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row form>
            <Col lg="12">
              {this.getFormInputRow('additional_info', <FormattedMessage id="additional_info" defaultMessage="Additional Info"/>, customer)}
            </Col>
          </Row>

          {/* Invoicing detail */}
          <div className="text-title"><FormattedMessage id="invoice_detail_title" defaultMessage="Invoice Details"/></div>
          <Row form>
            <Col lg="6" className="form-group">
              <label htmlFor="interest_included"><FormattedMessage id="interest_included" defaultMessage="Interest Included"/></label>
              <input
                type="checkbox"
                defaultChecked={customer.interest_included}
                className="mx-2 my-auto"
                id="interest_included"
                name="interest_included"
                onChange={() => this.onChangeCheckbox('interest_included')}
                checked={this.state.interest_included !== undefined ? this.state.interest_included : customer.interest_included}
                value={this.state.interest_included !== undefined ? this.state.interest_included : customer.interest_included}
              />
            </Col>
            <Col lg="6" className="form-group">
              <label htmlFor="vat_included"><FormattedMessage id="vat_included" defaultMessage="VAT Included"/></label>
              <input
                type="checkbox"
                onChange={() => this.onChangeCheckbox('vat_included')}
                checked={this.state.vat_included !== undefined ? this.state.vat_included : customer.vat_included}
                value={this.state.vat_included !== undefined ? this.state.vat_included : customer.vat_included}
                className="mx-2 my-auto"
                id="vat_included"
                name="vat_included"
              />
            </Col>
          </Row>
          <Row form>
            <Col md="4" className="form-group">
              <label htmlFor="payment_terms"><FormattedMessage id="payment_terms" defaultMessage="Payment Terms"/></label>
              <FormSelect
                id="payment_terms"
                name="payment_terms"
                defaultValue={customer.payment_terms}
                value={this.state.payment_terms !== undefined ? this.state.payment_terms : customer.payment_terms}
                onChange={this.onChangeCustomerField}
              >
                <option value="days0">0 dagar</option>
                <option value="days10">10 dagar</option>
                <option value="days15">15 dagar</option>
                <option value="days20">20 dagar</option>
                <option value="days30">30 dagar</option>
                <option value="autogiro">Autogiro</option>
                <option value="postforskott">Postförskott</option>
                <option value="kontant">Kontant</option>
              </FormSelect>
            </Col>
            <Col md="4" className="form-group">
              <label htmlFor="invoice_type"><FormattedMessage id="payment_type" defaultMessage="Payment Type"/></label>
              <FormSelect
                id="invoice_type"
                name="invoice_type"
                defaultValue={customer.invoice_type}
                value={this.state.invoice_type === undefined ? customer.invoice_type : this.state.invoice_type}
                onChange={this.onChangeCustomerField}
              >
                <option value="invoice">Faktura</option>
                <option value="cash">Swish</option>
              </FormSelect>
            </Col>
            <Col md="4" className="form-group">
              <label htmlFor="our_reference"><FormattedMessage id="our_reference" defaultMessage="Our Reference"/></label>
              <FormSelect onChange={this.onChangeCustomerField} defaultValue={customer.our_reference} value={this.state.our_reference === undefined ? customer.our_reference : this.state.our_reference} name="our_reference">
                <option value=""></option>
                <option value="henrik">Henrik</option>
                <option value="daniel">Daniel</option>
                <option value="jesper">Jesper</option>
                <option value="mikael">Mikael</option>
                <option value="benjamin">Benjamin</option>
                <option value="henric">Henric</option>
              </FormSelect>
            </Col>
          </Row>
          <Row form>
            <Col lg="4">
              {this.getFormInputRow('vat_number', <FormattedMessage id="vat_number" defaultMessage="Vat Number"/>, customer)}
            </Col>
            <Col lg="4">
              <label htmlFor="vat_type"><FormattedMessage id="vat_type" defaultMessage="VAT type"/></label>
              <FormSelect
                id="vat_type"
                name="vat_type"
                defaultValue={customer.vat_type}
              >
                <option value="se_vat">SE</option>
                <option value="reverse_vat">SE Omvändmoms</option>
              </FormSelect>
            </Col>
            <Col lg="4">
              <label htmlFor="invoice_delivery_method"><FormattedMessage id="invoice_delivery_method" defaultMessage="Invoice Delivery Method"/></label>
              <FormSelect
                id="invoice_delivery_method"
                name="invoice_delivery_method"
                defaultValue={customer.invoice_delivery_method}
                value={this.state.invoice_delivery_method === undefined ? customer.invoice_delivery_method : this.state.invoice_delivery_method}
                onChange={this.onChangeCustomerField}
              >
                <option value="print">Utskrift</option>
                <option value="email">E-post</option>
              </FormSelect>
            </Col>
          </Row>
          <Row form>
            <Col lg="12">
              {this.getFormInputRow('your_reference', <FormattedMessage id="customer_reference" defaultMessage="Customer Reference"/>, customer)}
            </Col>
          </Row>

          {/* Invoice Address */}
          <div className="text-title"><FormattedMessage id="invoice_address" defaultMessage="Invoice Address"/></div>
          <Row form className="mb-3">
            <Col lg="12">
              <label><FormattedMessage id="customer.modal.search-address" defaultMessage="Search Address" /></label>
              <PlacesAutocomplete
                object={{ address: customer.address }}
                suggestions={placesSuggestions}
                getSuggestions={this.getLocationSuggestions}
                onSelect={this.onSelectAddress}
                id="address"
              />
            </Col>
          </Row>

          <Row form>
            <Col lg="12">
              <label htmlFor="address"><FormattedMessage id="address" defaultMessage="Address"/></label>
              <FormInput
                type="text"
                id="address"
                name="address"
                defaultValue={customer.address}
                value={this.state.address}
                onChange={this.handleChange}
              />
            </Col>
            <Col lg="12">
              {this.getFormInputRow('address2', <FormattedMessage id="address2" defaultMessage="Address 2"/>, customer)}
            </Col>
            <Col lg="6">
              <label htmlFor="zip_code"><FormattedMessage id="zip_code" defaultMessage="Zip Code"/></label>
              <FormInput
                type="text"
                id="zip_code"
                name="zip_code"
                defaultValue={customer.zip_code}
                value={this.state.zip_code}
                onChange={this.handleChange}
              />
            </Col>
            <Col lg="6">
              <label htmlFor="area"><FormattedMessage id="area" defaultMessage="Area"/></label>
              <FormInput
                type="text"
                id="area"
                name="area"
                defaultValue={customer.area}
                value={this.state.area}
                onChange={this.handleChange}
              />
            </Col>
          </Row>

          <FormInput
            value={this.state.is_organisation !== undefined ? this.state.is_organisation : customer.is_organisation}
            name="is_organisation"
            readOnly
            hidden
          />
          <FormInput
            name="latitude"
            value={this.state.latitude}
            readOnly
            hidden
          />
          <FormInput
            name="longitude"
            value={this.state.longitude}
            readOnly
            hidden
          />
        </Col>
      </Row>
    );
  }
}

import axios from '../../utils/Api';
import { setItems } from '../actions';

export const requestItems = (page = 1, limit = 10) => async (dispatch, getState) => {
  const { notification, items } = getState();
  const query = `?page=${page ? page : items.page}&per_page=${(limit || items.pageSize)}`;
  const response = await axios.get(`/api/v1/items${query}`);

  if (response.data.errors) {
    notification.notifyError('Items error', response.data.errors);
  }

  dispatch(setItems(response.data.data));
};

export const requestCreateItem = data => async (dispatch, getState) => {
  const response = await axios.post('/api/v1/items', { item: data });

  if (response.data.errors) {
    getState().notification.notifyError('Items error', response.data.errors);
  }

  dispatch(requestItems());
  getState().notification.notifySuccess('Items', response.data.message);
};

export const requestUpdateItem = data => async (dispatch, getState) => {
  const response = await axios.put(`/api/v1/items/${data.itemId}`, { item: data });

  if (response.data.errors) {
    getState().notification.notifyError('Items error', response.data.errors);
  }

  dispatch(requestItems());
  getState().notification.notifySuccess('Items', response.data.message);
};

export const requestDeleteItems = items => async (dispatch, getState) => {
  const response = await axios.delete('/api/v1/items/bulk_delete', { data: { items } });

  if (response.data.errors) {
    getState().notification.notifyError('Items error', response.data.errors);
  }

  dispatch(requestItems());
  getState().notification.notifySuccess('Items', response.data.message);
};

export const requestSearchItems = text => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get(`/api/v1/items/search?query=${text}`);

    if (response.data.data) {
      dispatch(setItems({ records: response.data.data }));
    }

  } catch (err) {
    notification.notifyError('Items error', 'Erro in searching for item');
  }
};

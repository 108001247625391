import axios from '../../utils/Api';
import { setEmployeesFields } from '../actions';
import { getNotificationMessage } from './utility';
import FileSaver from 'file-saver';

export const requestEmployees = (page, limit) => async (dispatch, getState) => {
  const { notification, employees } = getState();
  try {
    const query = `?page=${page || employees.page}&per_page=${(limit || employees.pageSize)}`;
    const response = await axios.get(`/api/v1/employees${query}`);

    if (response.data.errors) {
      dispatch(setEmployeesFields({ errors: response.data.errors }));
      notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    dispatch(setEmployeesFields({ ...response.data.data }));
  } catch (err) {
    notification.notifyError('Employee error', 'Error while gettng employees');
  }
};

export const requestEmployeePdf = employeeId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get(`/api/v1/employees/${employeeId}/pdf`);

    fetch(`data:image/png;base64,${response.data.data.pdf_file.content}`)
      .then(res => res.blob())
      .then(blob => FileSaver.saveAs(blob, response.data.data.pdf_file.name));

    if (response.data.errors) {
      notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }
  } catch (err) {
    notification.notifyError('Employee error', 'Error while gettng employees pdf');
  }
};

export const requestCreateEmployee = (details, closeModal) => async (dispatch, getState) => {
  const { notification, employees } = getState();
  try {
    const response = await axios.post('/api/v1/employees', { employee: details });

    if (response.data.errors) {
      dispatch(setEmployeesFields({ errors: response.data.errors }));
      return notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    dispatch(setEmployeesFields({ records: [...employees.records, response.data.data] }));
    closeModal();
    notification.notifySuccess('Employee', 'Employee created');
  } catch (err) {
    return notification.notifyError('Employee error', 'Error while creating employee');
  }
};

export const requestUpdateEmployee = (details, employeeId, closeModal) => async (dispatch, getState) => {
  try {
    const response = await axios.put(`/api/v1/employees/${employeeId}`, { employee: details });

    if (response.data.errors) {
      dispatch(setEmployeesFields({ errors: response.data.errors }));

      return getState().notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    dispatch(requestEmployees());
    closeModal();
    getState().notification.notifySuccess('Employee', response.data.message);
  } catch (err) {
    getState().notification.notifyError('Employee error', 'Error in updating employee');
  }
};

export const requestActivateEmployee = employeeId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/employees/${employeeId}/activate`);

    if (response.data.errors) {
      notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    dispatch(requestInactiveEmployees());
    notification.notifySuccess('Employee', response.data.message);
  } catch (err) {
    notification.notifyError('Employee error', 'Error while activating employee');
  }
};

export const requestDeactivateEmployee = employeeId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.put(`/api/v1/employees/${employeeId}/deactivate`);

    if (response.data.errors) {
      notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    dispatch(requestEmployees());
    notification.notifySuccess('Employee', 'Employee deactivated');
  } catch (err) {
    notification.notifyError('Employee error', 'Error while deactivating employee');
  }
};

export const requestEmployee = employeeId => async (dispatch, getState) => {
  const response = await axios.get(`/api/v1/employees/${employeeId}`);

  if (response.data.errors) {
    getState().notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
  }

  dispatch(setEmployeesFields({ employee: response.data.data }));
};

export const requestInactiveEmployees = employeeId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get('/api/v1/employees/inactive');

    if (response.data.errors) {
      notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    dispatch(setEmployeesFields({ records: response.data.data }));
  } catch (err) {
    notification.notifyError('Employee error', 'Error while getting inactive employees');
  }
};

export const requestActiveEmployees = employeeId => async (dispatch, getState) => {
  const response = await axios.get('/api/v1/employees/active_employees');

  if (response.data.errors) {
    getState().notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
  }

  dispatch(setEmployeesFields({ employees: response.data.data }));
};

export const requestSearchEmployees = text => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get(`/api/v1/employees/search?query=${text}`);

    if (response.data.data) {
      dispatch(setEmployeesFields({ records: response.data.data }));
    }

  } catch (err) {
    notification.notifyError('Employee error', 'Error while searching for employee');
  }
};

export const requestActivitiesEmployees = employeeId => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.get(`api/v1//employees/${employeeId}/activities`);

    if (response.data.errors) {
      notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    dispatch(setEmployeesFields({ EmployeeActivity: response.data.data }));
  } catch (err) {
    notification.notifyError('Employee error', 'Error while requesting employee activities');
  }
};

export const requestDeleteEmployees = (ids, viewInactiveEmployees) => async (dispatch, getState) => {
  const { notification } = getState();
  try {
    const response = await axios.delete('/api/v1/employees/bulk_delete', { data: { employees: ids } }); // eslint-disable-line

    if (response.data.errors) {
      getState().notification.notifyError('Employee error', getNotificationMessage(response.data.errors, 'employees'));
    }

    if (viewInactiveEmployees) return dispatch(requestInactiveEmployees());
    dispatch(requestEmployees());
    getState().notification.notifySuccess('Employee', 'Employee deleted');
  } catch (err) {
    notification.notifyError('Employee error', 'Error while deleting employee');
  }
};

import { Role } from '../utils/roles';

export default function () {
  const userRole = localStorage.getItem('role');
  return Role.SuperAdmin === userRole ? [
    {
      title: 'Companies',
      htmlBefore: '<i class="material-icons mr-2">list</i>',
      to: '/admin/companies',
      id: 'companies',
    },
    {
      title: 'Invoices',
      htmlBefore: '<i class="material-icons mr-2">attach_money</i>',
      to: '/admin/invoices',
      id: 'invoices',
    },
    {
      title: 'Errors',
      htmlBefore: '<i class="material-icons mr-2">error</i>',
      to: '/admin/errors',
      id: 'error',
    },
  ] : [

    {
      title: 'Calendar',
      id: 'calendar',
      htmlBefore: '<i class="material-icons mr-2">calendar_today</i>',
      to: '/calendar',
    },
    {
      title: 'Items/Services',
      id: 'items-services',
      htmlBefore: '<i class="material-icons mr-2">list</i>',
      items: [
        {
          title: 'Items',
          to: '/items',
          id: 'items',
        },
        {
          title: 'Services',
          to: '/services',
          id: 'services',
        },
      ],
    },
    {
      title: 'Register',
      id: 'register',
      htmlBefore: '<i class="material-icons mr-2">people</i>',
      items: [
        {
          title: 'Employees',
          to: '/employees',
          id: 'employees',
        },
        {
          title: 'Administrator',
          to: '/administrator',
          id: 'administrator',
        },
        {
          title: 'Customer',
          to: '/customer',
          id: 'customer',
        },
      ],
    },
    {
      title: 'Vehicle',
      htmlBefore: '<i class="material-icons mr-2">local_shipping</i>',
      to: '/vehicle',
      id: 'vehicle',
    },
    {
      title: 'Orders',
      htmlBefore: '<i class="material-icons mr-2">library_books</i>',
      to: '/',
      id: 'bookings',
    },
    {
      title: 'Invoices',
      htmlBefore: '<i class="material-icons mr-2">receipt</i>',
      to: '/invoices',
      id: 'invoices',
    },
    {
      title: 'Reports',
      htmlBefore: '<i class="material-icons mr-2">description</i>',
      to: '/reports',
      id: 'report',
    },
  ];
}

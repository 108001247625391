/* eslint-disable no-inline-comments */
import React from 'react';
import Details from './Details';
import Employees from './Employees';
import Price from './Price';
import Comment from './OtherInfo';
import Address from './Address';
import { FormattedMessage } from 'react-intl';
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from 'shards-react';

export default class extends React.Component {
  state = {
    activeTab: 1,
    employees: null,
  }

  static getDerivedStateFromProps (props, state) {
    if (props.parentProps.bookings.editingBookingObject) {
      return {
        ...state,
        employees: state.employees || props.parentProps.bookings.editingBookingObject.bookings_employees,
        customer: state.customer || props.parentProps.bookings.editingBookingObject.customer,
      };
    }
    return null;
  }

  componentDidMount () {
    if (this.props.objectId) {
      this.props.parentProps.requestBooking(this.props.objectId);
    }
  }

  componentWillUnmount () {
    this.props.parentProps.setBookingsFields({ editingBookingObject: {} });
  }

  setModalData = (key, data) => {
    this.setState({ [key]: data });
  }

  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  }

  render () {
    const { activeTab } = this.state;
    const {
      bookings: { editingBookingObject = {} },
      customers: { records: customersSuggestion },
      services: { records: servicesSuggestion },
      employees: { records: employeesSuggestions },
      items: { records: itemsSuggestions },
      vehicles: { records: vehiclesSuggestions },
      locations: { records: placesSuggestions },
      requestSearchPlaces,
      downloadItemsPdf,
    } = this.props.parentProps;
    const { getSuggestions } = this.props;
    const booking = editingBookingObject;

    if (this.props.parentProps.bookings.fetchingBooking) {
      return <div>Loading ...</div>;
    }

    return (
      <>
        <Row>
          <Col lg="12">
            <Nav tabs>
              <NavItem>
                <NavLink className="cursor-pointer" onClick={() => this.setActiveTab(1)} active={activeTab === 1}>
                  <FormattedMessage id="order_detail" defaultMessage="Detail"/>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-pointer" onClick={() => this.setActiveTab(2)} active={activeTab === 2}>
                  <FormattedMessage id="employee" defaultMessage="Employees"/>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-pointer" onClick={() => this.setActiveTab(3)} active={activeTab === 3}>
                  <FormattedMessage id="address" defaultMessage="Address"/>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-pointer" onClick={() => this.setActiveTab(4)} active={activeTab === 4}>
                  <FormattedMessage id="price" defaultMessage="Price"/>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="cursor-pointer" onClick={() => this.setActiveTab(5)} active={activeTab === 5}>
                  <FormattedMessage id="other" defaultMessage="Other info"/>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Details
          booking={booking}
          customer={this.state.customer}
          setModalData={this.setModalData}
          active={activeTab === 1}
          getSuggestions={getSuggestions}
          servicesSuggestion={servicesSuggestion}
          customersSuggestion={customersSuggestion}
          vehiclesSuggestions={vehiclesSuggestions}
          requestCreateCustomer={this.props.parentProps.requestCreateCustomer}
          locations={this.props.parentProps.locations}
        />
        <Employees booking={booking} setModalData={this.setModalData} active={activeTab === 2} getSuggestions={getSuggestions} employeesSuggestions={employeesSuggestions} />
        <Address booking={booking} setModalData={this.setModalData} active={activeTab === 3} requestSearchPlaces={requestSearchPlaces} placesSuggestions={placesSuggestions} />
        <Price downloadItemsPdf={downloadItemsPdf} booking={booking} employees={this.state.employees} setModalData={this.setModalData} customer={this.state.customer} itemsSuggestions={itemsSuggestions} getSuggestions={getSuggestions} active={activeTab === 4} />
        <Comment booking={booking} active={activeTab === 5} />
      </>
    );
  }
}

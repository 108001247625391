import React from 'react';
import { Role } from '../../../utils/roles';
import {
  Row,
  Col,
  FormInput,
} from 'shards-react';
import { FormattedMessage } from 'react-intl';

function getModalContent (employee, errors, handleChange, role) { //eslint-disable-line
  return (
    <Row style={{ overflowY: 'auto' }}>
      <Col lg="12">
        <div className="text-title"><FormattedMessage id="basic_info" defaultMessage="Basic Information"/></div>
        <Row form>
          <Col lg="6" className="form-group">
            <label htmlFor="name"><FormattedMessage id="name" defaultMessage="Name"/></label>
            <FormInput
              id="Name"
              name="name"
              defaultValue={employee.name}
              valid={errors.name}
              invalid={errors.name === false}
              onChange={handleChange}
              required
            />
          </Col>
          <Col lg="6" className="form-group">
            <label htmlFor="identification_num"><FormattedMessage id="personal_number" defaultMessage="Personal number"/></label>
            <FormInput
              id="identification_num"
              name="identification_num"
              defaultValue={employee.identification_num}
              valid={errors.identification_num}
              invalid={errors.identification_num === false}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" className="form-group">
            <label htmlFor="phone"><FormattedMessage id="phone_number" defaultMessage="Phone"/></label>
            <FormInput
              type="tel"
              id="phone"
              name="phone"
              maxLength="12"
              pattern="[0-9]*"
              defaultValue={employee.phone}
              valid={errors.phone}
              invalid={errors.phone === false}
              onChange={handleChange}
            />
          </Col>
          <Col lg="3" className="form-group">
            <label htmlFor="mobile"><FormattedMessage id="mobile" defaultMessage="Mobile"/></label>
            <FormInput
              type="tel"
              id="mobile"
              name="mobile"
              maxlength="12"
              pattern="[0-9]*"
              defaultValue={employee.mobile}
              valid={errors.mobile}
              invalid={errors.mobile === false}
              onChange={handleChange}
            />
          </Col>
        </Row>

        <div className="text-title"><FormattedMessage id="login_title" defaultMessage="Login Email"/></div>
        <Row form>
          <Col lg="5" className="form-group">
            <label htmlFor="email"><FormattedMessage id="email" defaultMessage="E-mail"/></label>
            <FormInput
              id="email"
              name="email"
              defaultValue={employee.email}
              valid={errors.email}
              invalid={errors.email === false}
              onChange={handleChange}
            />
          </Col>
        </Row>

        {role === Role.Owner && (
          <>
            <div className="text-title"><FormattedMessage id="closest_relative" defaultMessage="Closest Relative"/></div>
            <Row>
              <Col md="5" className="form-group">
                <label htmlFor="relative_name"><FormattedMessage id="name" defaultMessage="Name"/></label>
                <FormInput
                  id="relative_name"
                  name="relative_name"
                  defaultValue={employee.relative_name}
                  valid={errors.relative_name}
                  invalid={errors.relative_name === false}
                  onChange={handleChange}
                />
              </Col>
              <Col lg="5" className="form-group">
                <label htmlFor="relative_telefone"><FormattedMessage id="phone_number" defaultMessage="Phone"/></label>
                <FormInput
                  id="relative_telefone"
                  name="relative_telefone"
                  defaultValue={employee.relative_telefone}
                  valid={errors.relative_telefone}
                  invalid={errors.relative_telefone === false}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <div className="text-title"><FormattedMessage id="bank_acccount_information" defaultMessage="Bank Account Information"/></div>
            <Row form>
              <Col lg="4" className="form-group">
                <label htmlFor="account_number"><FormattedMessage id="account_number" defaultMessage="Account Number"/></label>
                <FormInput
                  id="account_number"
                  name="account_number"
                  defaultValue={employee.account_number}
                  valid={errors.account_number}
                  invalid={errors.account_number === false}
                  onChange={handleChange}
                />
              </Col>
              <Col md="4" className="form-group">
                <label htmlFor="clearing_number"><FormattedMessage id="clearing_number" defaultMessage="Clearing Number"/></label>
                <FormInput
                  id="clearing_number"
                  name="clearing_number"
                  defaultValue={employee.clearing_number}
                  valid={errors.clearing_number}
                  invalid={errors.clearing_number === false}
                  onChange={handleChange}
                />
              </Col>
              <Col md="4" className="form-group">
                <label htmlFor="bank_name"><FormattedMessage id="bank_name" defaultMessage="Bank Name"/></label>
                <FormInput
                  id="bank_name"
                  name="bank_name"
                  defaultValue={employee.bank_name}
                  valid={errors.bank_name}
                  invalid={errors.bank_name === false}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <div className="text-title"><FormattedMessage id="salary_information" defaultMessage="Salary Information"/></div>
            <Row form>
              <Col lg="3" className="form-group">
                <label htmlFor="salary"><FormattedMessage id="salary" defaultMessage="Salary"/></label>
                <FormInput
                  id="salary"
                  name="salary"
                  defaultValue={employee.salary}
                  valid={errors.salary}
                  invalid={errors.salary === false}
                  onChange={handleChange}
                />
              </Col>
              <Col lg="3" className="form-group">
                <label htmlFor="salary_per_hour"><FormattedMessage id="salary_per_hour" defaultMessage="Salary Per Hour"/></label>
                <FormInput
                  id="salary_per_hour"
                  name="salary_per_hour"
                  defaultValue={employee.salary_per_hour}
                  valid={errors.salary_per_hour}
                  invalid={errors.salary_per_hour === false}
                  onChange={handleChange}
                />
              </Col>
              <Col md="3" className="form-group">
                <label htmlFor="commission"><FormattedMessage id="comission" defaultMessage="Commission"/></label>
                <FormInput
                  id="commission"
                  name="commission"
                  defaultValue={employee.commission}
                  valid={errors.commission}
                  invalid={errors.commission === false}
                  onChange={handleChange}
                />
              </Col>
              <Col md="3" className="form-group">
                <label htmlFor="other_salary"><FormattedMessage id="other" defaultMessage="Others"/></label>
                <FormInput
                  id="other_salary"
                  name="other_salary"
                  defaultValue={employee.other_salary}
                  valid={errors.other_salary}
                  invalid={errors.other_salary === false}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </>)
        }

        <div className="text-title"><FormattedMessage id="address_info_title" defaultMessage="Address Information"/></div>
        <Row form>
          <Col lg="4" className="form-group">
            <label htmlFor="address"><FormattedMessage id="address" defaultMessage="Address"/></label>
            <FormInput
              id="address"
              name="address"
              defaultValue={employee.address}
              valid={errors.address}
              invalid={errors.address === false}
              onChange={handleChange}
            />
          </Col>
          <Col lg="4" className="form-group">
            <label htmlFor="zip_code"><FormattedMessage id="zip_code" defaultMessage="Zip Code"/></label>
            <FormInput
              id="zip_code"
              name="zip_code"
              defaultValue={employee.zip_code}
              valid={errors.zip_code}
              invalid={errors.zip_code === false}
              onChange={handleChange}
            />
          </Col>
          <Col md="4" className="form-group">
            <label htmlFor="area"><FormattedMessage id="area" defaultMessage="Area"/></label>
            <FormInput
              id="area"
              name="area"
              defaultValue={employee.area}
              valid={errors.area}
              invalid={errors.area === false}
              onChange={handleChange}
            />
          </Col>
        </Row>

      </Col>
    </Row>
  );
}

export default getModalContent;

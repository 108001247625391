import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Nav, Collapse } from 'shards-react';
import MenuItem from './MenuItem';
import { connect } from 'react-redux';

class HeaderNavbar extends React.Component {
  render () {
    const { items } = this.props;
    return (
      <Collapse className="header-navbar d-lg-flex p-0 bg-white border-top" open={this.props.profile.openSideNav}>
        <Container>
          <Row>
            <Col>
              <Nav tabs className="border-0 flex-column flex-lg-row">
                {items.map((item, idx) => (
                  <MenuItem key={idx} item={item} />
                ))}
              </Nav>
            </Col>
          </Row>
        </Container>
      </Collapse>
    );
  }
}


HeaderNavbar.propTypes = {

  /**
   * The array of header navbar items.
   */
  items: PropTypes.array,
};

const mapState = state => ({
  profile: state.profile,
});

export default connect(mapState, null)(HeaderNavbar);

/* eslint-disable global-require */
/* eslint-disable no-inline-comments */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestResetPassword } from '../appRedux/thunks/auth';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react';

const Register = (props) => {

  const submitForm = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    if (!email) return;
    props.requestResetPassword(email);
    event.target.reset();
    props.notification.notifySuccess('Forgot password', 'Email sent!');
  };

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              <img
                className="auth-form__logo d-table mx-auto mb-3"
                src={require('../images/mex-without-description.jpeg')}
                alt="MEX LOGO"
              />

              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                <FormattedMessage id="reset_password_title" defaultMessage="Reset Password"/>
              </h5>

              {/* Form Fields */}
              <Form onSubmit={submitForm}>
                <FormGroup>
                  <label htmlFor="emailInput"><FormattedMessage id="email" defaultMessage="Email address"/></label>
                  <FormInput
                    type="email"
                    name="email"
                    id="emailInput"
                    placeholder="Skriv din e-post..."
                    autoComplete="email"
                  />
                  <small className="form-text text-muted text-center">
                    <FormattedMessage id="reset_password_messsage" defaultMessage="You will receive an email with a unique token."/>
                  </small>
                </FormGroup>
                <Button
                  pill
                  theme="accent"
                  className="d-table mx-auto"
                  type="submit"
                >
                  <FormattedMessage id="reset_password" defaultMessage="Reset Password" />
                </Button>
              </Form>
            </CardBody>
          </Card>

          {/* Meta Details */}
          <div className="auth-form__meta d-flex mt-4">
            <Link to="/login" className="mx-auto">
              <FormattedMessage id="back_to_login" defaultMessage="Take me back to login"/>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapState = state => ({
  notification: state.notification,
});

export default withRouter(connect(mapState, { requestResetPassword })(Register));

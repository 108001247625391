/* eslint-disable no-console */
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react';

class Autocomplete extends React.Component {
  constructor (props) {
    super(props);
    this.state = { address: '' };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    let fullAddress = null;
    geocodeByAddress(address)
      .then((results) => {
        fullAddress = results[0];
        return getLatLng(fullAddress);
      })
      .then(latLng => this.props.onSelect(latLng, address, fullAddress))
      .catch(error => console.error('Error', error));
    this.setState({ address: '' });
  };

  render () {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <InputGroup seamless>
              <FormInput
                size="lg"
                {...getInputProps({
                  placeholder: 'Sök en adress...',
                  className: 'location-search-input form-control',
                })}
              />
              <InputGroupAddon type="append">
                <InputGroupText>
                  <i className="material-icons">search</i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' };

                return (
                  <div
                    className="places-suggestion"
                    {...getSuggestionItemProps(suggestion, { className, style })}>
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default Autocomplete;

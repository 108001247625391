import React, { Component } from 'react';
import RichTextEditor from 'react-rte';

class Editor extends Component {
  state = {
    value: RichTextEditor.createEmptyValue(),
  }

  componentDidUpdate (prevProps, prevState) {
    const initStr = '<p><br></p>';
    if (
      this.state.value.toString('html') === initStr &&
      (this.props.value && this.props.value !== initStr) &&
      prevState.value.toString('html') !== this.props.value
    ) {
      this.setState({ value: RichTextEditor.createValueFromString(this.props.value, 'html') });
    }
  }

  onChange = (value) => {
    this.setState({ value }, () => {
      if (this.props.onChange) {
        this.props.onChange(value.toString('html'));
      }
    });
  };

  toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  }

  render () {

    return (
      <RichTextEditor
        toolbarConfig={this.toolbarConfig}
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}

export default Editor;

import { SET_CONFIRM_MODAL_FIELDS } from '../../utils/constants';

function confirmDialog (state = {}, action) {
  switch (action.type) {
  case SET_CONFIRM_MODAL_FIELDS:
    if (action.payload.reset) return {};

    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
}

export default confirmDialog;

export const getZipCode = (address) => {
  if (!address || !address.address_components) return;
  for (const add of address.address_components) {
    if (add.types[0] === 'postal_code') {
      return add.long_name;
    }
  }
};

export const getArea = (address) => {
  if (!address || !address.address_components) return;
  for (const add of address.address_components) {
    if (add.types.find(typ => typ === 'locality')) {
      return add.long_name;
    }
  }
};

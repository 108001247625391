/* eslint-disable max-params */
/* eslint-disable no-unused-expressions */
import React from 'react';
import PlacesAutocomplete from '../../PlacesAutocomplete';
import FormModal from '../../FormModal';
import { FormattedMessage } from 'react-intl';
import sortBy from 'lodash/fp/sortBy';
import {
  Row,
  Col,
  Button,
  FormInput,
  FormSelect,
} from 'shards-react';

const optionsNumber = Array(21).fill().map((e, i) => i++); // eslint-disable-line
const addressTypes = Array(9).fill().map((e, i) => i++); // eslint-disable-line
const addressDetail = ['address_type', 'customer_address', 'number_of_rooms', 'living_area', 'basement', 'stairs', 'garden', 'elevator', 'comments', 'latitude', 'longitude'];

class Address extends React.Component {
  constructor () {
    super();
    this.state = {
      openAddNewModal: false,
      // editingObject: {},
      errors: {},
      // addresses: [],
    };

    this.onSubmitModal = this.onSubmitModal.bind(this);
  }

  static getDerivedStateFromProps (props, state) {
    if (props.booking.addresses) {
      return {
        ...state,
        addresses: state.addresses || props.booking.addresses,
      };
    }

    return null;
  }

  getFormInputRow = (name, title, required, type = 'text') => {
    const { editingObject = {} } = this.state;
    return (
      <Row form>
        <Col md="12" className="form-group">
          <label htmlFor={name}>{title}</label>
          <FormInput
            id={name}
            name={name}
            defaultValue={editingObject[name]}
            valid={this.props.booking[name]}
            invalid={this.state.errors[name]}
            value={editingObject[name]}
            onChange={this.onChangeAddressInput}
            type={type}
            {...required}
          />
        </Col>
      </Row>
    );
  }

  isAddressUsed = addressType => Boolean(this.state.addresses && this.state.addresses.find(add => add.address_type === addressType))

  onChangeAddressInput = (e) => {
    const [keyName, value] = [e.target.name, e.target.value];

    this.setState({ editingObject: { ...this.state.editingObject, [keyName]: value } });
  }

  getLocationSuggestions = (txt) => {
    if (txt.length <= 3) return;
    this.props.requestSearchPlaces(txt);
  }

  onSelectAddress = (lat_long, address, fullAddress) => {
    this.setState({
      editingObject: {
        ...this.state.editingObject,
        customer_address: fullAddress.formatted_address || address,
        latitude: lat_long.lat,
        longitude: lat_long.lng,
      },
    });
  }

  getModalContent = (address = {}) => (
    <Col lg="12 p-2">
      <div className="tab-content p-2" id="pills-tabContent">
        <div className="tab-pane fade show active" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
          <Col lg="12" className="form-group">
            <label htmlFor="address_type"><FormattedMessage id="address_type" defaultMessage="Address Type"/></label>
            <FormSelect value={address.address_type} onChange={this.onChangeAddressInput} name="address_type" required invalid={this.state.errors.addressType}>
              <option></option>
              {(!this.isAddressUsed('Start address') || address.address_type === 'Start address') && <option value="Start address">Startadress</option>}
              {addressTypes.map(op => (
                (this.isAddressUsed(`Address ${op + 2}`) && address.address_type !== `Address ${op + 2}`)
                  ? null
                  : (<option key={`add-type-${op}`} value={`Address ${op + 2}`}>Adress {op + 2}</option>)
              ))}
            </FormSelect>
          </Col>
          <Row form className="mb-3">
            <Col lg="12">
              <label><FormattedMessage id="customer.modal.search-address" defaultMessage="Search Address" /></label>
              <PlacesAutocomplete
                object={{ address: address.customer_address }}
                suggestions={this.props.placesSuggestions}
                getSuggestions={this.getLocationSuggestions}
                onSelect={this.onSelectAddress}
                id="address"
              />
            </Col>
          </Row>
          {this.getFormInputRow('customer_address', <FormattedMessage id="address" defaultMessage="Address"/>, ['required'])}
          <Row form>
            <Col lg="3" className="form-group">
              <label htmlFor="number_of_rooms"><FormattedMessage id="rooms" defaultMessage="Rooms"/></label>
              <FormSelect value={address.number_of_rooms} onChange={this.onChangeAddressInput} name="number_of_rooms">
                {optionsNumber.map(op => (<option key={`rooms-${op}`} value={op}>{op}</option>))}
              </FormSelect>
            </Col>
            <Col lg="3" className="form-group">
              {this.getFormInputRow('living_area', <FormattedMessage id="living_area" defaultMessage="Living Area"/>)}
            </Col>
            <Col lg="3" className="form-group">
              <label htmlFor="basement"><FormattedMessage id="basement_wind" defaultMessage="Basement/Wind"/></label>
              <FormSelect value={address.basement} onChange={this.onChangeAddressInput} name="basement">
                <option value="Nej">Nej</option>
                <option value="Källare">Källare</option>
                <option value="Vind">Vind</option>
                <option value="Förräd">Förråd</option>
                <option value="Garage">Garage</option>
              </FormSelect>
            </Col>
            <Col lg="3" className="form-group">
              <label htmlFor="stairs"><FormattedMessage id="stairs" defaultMessage="Stairs"/></label>
              <FormSelect value={address.stairs} onChange={this.onChangeAddressInput} name="stairs">
                {optionsNumber.map(op => (<option key={`stairs-${op}`} value={op}>{op}</option>))}
              </FormSelect>
            </Col>
          </Row>
          <Row form>
            <Col lg="3" className="form-group">
              <label htmlFor="garden"><FormattedMessage id="garden" defaultMessage="Garden"/></label>
              <FormSelect value={address.garden} onChange={this.onChangeAddressInput} name="garden">
                <option value="Nej">Nej</option>
                <option value="Ja">Ja</option>
              </FormSelect>
            </Col>
            <Col lg="3" className="form-group">
              <label htmlFor="elevator"><FormattedMessage id="elevator" defaultMessage="Elevator"/></label>
              <FormSelect value={address.elevator} onChange={this.onChangeAddressInput} name="elevator">
                <option value="Nej">Nej</option>
                <option value="Liten">Liten</option>
                <option value="Mellan">Mellan</option>
                <option value="Stor">Stor</option>
              </FormSelect>
            </Col>
          </Row>
          {this.getFormInputRow('comments', <FormattedMessage id="other" defaultMessage="Other Information"/>)}
        </div>
      </div>
      <FormInput
        name="latitude"
        value={address.latitude}
        readOnly
        hidden
      />
      <FormInput
        name="longitude"
        value={address.longitude}
        readOnly
        hidden
      />
    </Col>
  )

  onClickCloseModal = () => {
    this.setState({
      openAddNewModal: false,
      editingObject: undefined,
      editingIndex: undefined,
    });
  }

  onClickAddNew = () => {
    this.setState({ openAddNewModal: true });
  }

  onSubmitModal (e) {
    e.stopPropagation();
    e.preventDefault();
    const address = {};
    const { addresses = [] } = this.state;

    for (const field of addressDetail) {
      if (e.target[field]) {
        address[field] = e.target[field].value;
      }
    }

    if (!address.address_type || !address.customer_address) {
      this.setState({ errors: { customer_address: !address.customer_address, addressType: !address.address_type } });
      return;
    }

    if (this.state.editingObject.address_type && typeof this.state.editingIndex === 'number') {
      addresses[this.state.editingIndex] = address;
    } else {
      addresses[addresses.length] = address;
    }

    this.setState({ addresses }, () => {
      this.props.setModalData('addresses', this.state.addresses);
      this.onClickCloseModal();
    });
  }

  getSortedAddresses = (addresses = []) => {
    const startAddress = addresses.filter(a => a.address_type === 'Start address');
    const otherAddresses = sortBy((a) => {
      // eslint-disable-next-line require-unicode-regexp
      const addressNumber = a.address_type.match(/\d+/);
      if (addressNumber) return Number(addressNumber);
      return a.address_type;
    })(addresses.filter(a => a.address_type !== 'Start address'));
    return [...startAddress, ...otherAddresses];
  }

  removeAddress = (index) => {
    const addresses = this.state.addresses.filter((ad, i) => i !== index);

    this.setState({ addresses });
  }

  onClickEditItem = (editingObject, editingIndex) => {
    this.setState({ openAddNewModal: true, editingObject, editingIndex });
  }

  render () {
    const { active } = this.props;
    const { editingObject } = this.state;
    const addresses = this.getSortedAddresses(this.state.addresses);
    const content = this.getModalContent(editingObject);

    return (
      <div>
        <FormModal
          content={content}
          title={<FormattedMessage id="add_edit" defaultMessage="Add/Edit Address"/>}
          onSubmit={this.onSubmitModal}
          open={this.state.openAddNewModal}
          onClose={this.onClickCloseModal}
        />
        <Row style={{ overflowY: 'auto', display: active ? 'block' : 'none' }}>
          <Col lg="12">
            <div className="tab-content p-2" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
                <Row form>
                  <Col lg="12" className="form-group">
                    <Button onClick={this.onClickAddNew}><FormattedMessage id="add_new_address" defaultMessage="Add New Address"/></Button>
                  </Col>
                </Row>
                {addresses && addresses.map((add, ind) => (
                  <Row key={`address-${ind}`} form>
                    <Col lg="10" tag="label" className="form-group">
                      <Col xs="12" className="font-weight-bold">{add.address_type}</Col>
                      <Col xs="12">{add.customer_address}</Col>
                      <Col>
                        <small className="form-text">
                          <FormattedMessage id="room_added" defaultMessage="Room:"/> {add.number_of_rooms}, <FormattedMessage id="living_area_added" defaultMessage="Living Area:"/> {add.living_area}, <FormattedMessage id="basement_added" defaultMessage="Basement:"/> {add.basement}, <FormattedMessage id="stairs_added" defaultMessage="Stairs:"/> {add.stairs}, <FormattedMessage id="garden_added" defaultMessage="Garden:"/> {add.garden}, <FormattedMessage id="elevator_added" defaultMessage="Elevator:"/> {add.elevator}
                        </small>
                      </Col>
                       <Col>
                        <small className="form-text">
                          <FormattedMessage id="other" defaultMessage="Other Information"/> : {add.comments}
                        </small>
                      </Col>
                    </Col>
                    <Col xs="2" className="form-group">
                      <span onClick={() => this.onClickEditItem(add, ind)}><i className="material-icons mr-2 cursor-pointer">edit</i></span>
                      <span onClick={() => this.removeAddress(ind)} className="text-danger cursor-pointer"><i className="material-icons">delete</i></span>
                    </Col>
                  </Row>))
                }
              </div>
            </div>
            <FormInput
              name="addresses"
              value={JSON.stringify((addresses || []))}
              readOnly
              hidden
            />
          </Col>
        </Row>
      </div>

    );
  }
}

export default Address;

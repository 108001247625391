import React, { Fragment } from 'react';
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from 'shards-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
  requestNotificationMarkRead,
} from '../../../../appRedux/thunks/notifications';

class Notifications extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  toggleNotifications () {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render () {
    const { records = [] } = this.props.notifications;
    const unreadCount = records.reduce((res, rec) => rec.unread ? (res + 1) : res, 0);

    return (
      <NavItem className="border-right dropdown notifications">
        <NavLink
          className="nav-link-icon text-center cursor-pointer"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            {unreadCount > 0 && (
              <Badge pill theme="danger">
                {unreadCount}
              </Badge>)
            }
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible}
          className="dropdown-menu dropdown-menu-small"
        >
          {records.length === 0 ? (
            <DropdownItem className="notification__all text-center">
              <span><FormattedMessage id="notfication_placeholder" defaultMessage="No notification"/></span>
            </DropdownItem>)
            : (
              <Fragment>
                {records.slice(0, 5).map(not => (
                  <DropdownItem onClick={() => this.props.requestNotificationMarkRead(not.id)}>
                    <div className="notification__icon-wrapper">
                      <div className="notification__icon">
                        <i className="material-icons">{not.unread ? 'visibility' : 'visibility_off'}</i>
                      </div>
                    </div>
                    <div className="notification__content cursor-pointer w-100">
                      <div className="w-100 d-flex">
                        <span className="notification__category pull-left">{not.actor_name}</span>
                        <span className="notification__category ml-auto">{moment(not.created_at).format('DD-MM-YYYY HH:MM')}</span>
                      </div>
                      <p className="text-capitalize">
                        {not.message}.
                      </p>
                      <p className="text-capitalize">
                        {not.identifier}
                      </p>
                    </div>
                  </DropdownItem>))
                }
                <DropdownItem className="notification__all text-center">
                  <Link to="/notifications"><FormattedMessage id="view_all_notification" defaultMessage="View all Notifications"/></Link>
                </DropdownItem>
              </Fragment>)
          }
        </Collapse>
      </NavItem>
    );
  }
}

const mapState = state => ({
  notifications: state.notification,
});

const mapDispatch = {
  requestNotificationMarkRead,
};

export default connect(mapState, mapDispatch)(Notifications);

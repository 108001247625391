import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_ROLE,
  USER_TOKEN_SET,
  SET_AUTH_FIELDS,
} from '../../utils/constants';

const INIT_STATE = {
  authUser: JSON.parse(localStorage.getItem('user')),
  initURL: '',
  role: localStorage.getItem('role'),
  token: localStorage.getItem('token'),
  errors: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

  case INIT_URL: {
    return {
      ...state,
      initURL: action.payload,
    };
  }

  case SIGNOUT_USER_SUCCESS: {
    return {
      ...state,
      authUser: null,
      initURL: '',
      role: null,
      token: null,
    };
  }

  case USER_DATA: {
    return {
      ...state,
      authUser: action.payload,
    };
  }

  case USER_TOKEN_SET: {
    return {
      ...state,
      token: action.payload,
    };
  }
  case USER_ROLE: {
    return {
      ...state,
      role: action.payload,
    };
  }
  case SET_AUTH_FIELDS: {
    return {
      ...state,
      ...action.payload,
    };
  }

  default:
    return state;
  }
};

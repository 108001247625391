import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export const UiButtonSize = {
  small: 'btn-sm',
  large: 'btn-lg',
};

export const UiButtonTheam = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  success: 'btn-success',
  danger: 'btn-danger',
  warning: 'btn-warning',
  info: 'btn-info',
  light: 'btn-light',
  dark: 'btn-dark',
  link: 'btn-link',
};

const UiSppinnerButton = (props) => {
  const {
    isDisabled,
    labelId,
    labelDefaultMessage,
    onClick,
    buttonSize,
    buttonTheam,
    FormattedMessageID,
  } = props;

  const classes = classNames(
    'btn',
    `${buttonSize || UiButtonSize.small}`,
    `${buttonTheam || UiButtonTheam.primary}`
  );

  return (
    <button className={classes} type="button" disabled ={isDisabled} onClick={onClick}>
      {isDisabled &&
        <>
          <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
          <FormattedMessage id={FormattedMessageID} defaultMessage={'Loading...'}/>
        </>
      }
      {!isDisabled && <FormattedMessage id={labelId} defaultMessage={labelDefaultMessage}/>}
    </button>
  );
};

export default UiSppinnerButton;

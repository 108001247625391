/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Container,
  Col,
  Row,
} from 'shards-react';
import { connect } from 'react-redux';
import PageTitle from '../components/common/PageTitle';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
  requestNotificationMarkRead,
  requestNotificationMarkReadAll,
  requestDeleteNotification,
  requestDeleteNotificationAll,
  requestNotifcations,
} from '../appRedux/thunks/notifications';

const Notifications = ({ title, notifications, requestNotifcations, requestNotificationMarkRead, requestNotificationMarkReadAll, requestDeleteNotification, requestDeleteNotificationAll }) => (
  <Container className="main-content-container px-4 pb-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={<FormattedMessage id="notifications" defaultMessage="Notifications"/>} subtitle={<FormattedMessage id="notifications_desc" defaultMessage="Mark read or delete notification"/>} className="text-sm-left mb-3" />
      <Col sm="4" className="d-flex ml-auto my-auto justify-content-end">
        <Button onClick={requestNotificationMarkReadAll} outline theme="secondary" className="mb-2 mr-1">
          <i className="material-icons mr-2">done</i><FormattedMessage id="mark_all_read" defaultMessage="Mark all read"/>
        </Button>
        <Button onClick={requestDeleteNotificationAll} outline theme="secondary" className="mb-2 mr-1">
          <i className="material-icons mr-2">delete</i><FormattedMessage id="delete_all" defaultMessage="Delete all"/>
        </Button>
      </Col>
    </Row>
    <Card small className="user-activity mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0"><FormattedMessage id="notifications" defaultMessage="Notifications" /></h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        {/* User Activity Icon */}
        {(notifications.records || []).map(not => (
          <div key={`${not.actor_name}-${not.id}`} className="user-activity__item pr-3 py-3">
            <div className="user-activity__item__icon">
              <i className="material-icons">&#xE7FE;</i>
            </div>
            <div className="user-activity__item__content text-capitalize w-100">
              <div className="d-flex w-75">
                <span className="text-light">{not.actor_name}</span>
                <span className="ml-auto">
                  <p className="notification__category ml-auto">{moment(not.created_at).format('DD-MM-YYYY')}</p>
                  <p className="notification__category ml-auto">{moment(not.created_at).format('hh:mm')}</p>
                </span>
              </div>
              <p>{not.message}.</p>
              <p>{not.identifier}.</p>
            </div>
            <div className="user-activity__item__action ml-auto">
              {not.unread && <i onClick={() => requestNotificationMarkRead(not.id)} title="Mark read" className="material-icons cursor-pointer my-2">check_circle_outline</i>}
              <i onClick={() => requestDeleteNotification(not.id)} title="Delete" className={`material-icons cursor-pointer mt-4 ${!not.unread ? 'mr-3' : ''}`}>delete</i>
            </div>
          </div>))
        }
      </CardBody>
      <CardFooter className="border-top">
        {Number(notifications.page) < Number(notifications.pages) && (
          <Button onClick={() => requestNotifcations(notifications.page + 1)} size="sm" theme="white" className="d-table mx-auto">
            <FormattedMessage id="load_more" defaultMessage="Load More"/>
          </Button>)
        }
      </CardFooter>
    </Card>

  </Container>
);

const mapState = state => ({
  notifications: state.notification,
});

const mapDispatch = {
  requestNotificationMarkRead,
  requestNotificationMarkReadAll,
  requestDeleteNotification,
  requestDeleteNotificationAll,
  requestNotifcations,
};

export default connect(mapState, mapDispatch)(Notifications);

Notifications.defaultProps = {
  title: 'Notifications',
};

import axios from 'axios';
import store from '../appRedux/store/index';
import { setLoading } from '../appRedux/actions';
import urls from './urls';

const configuredAxios = axios.create({
  baseURL: urls.getApiBaseUrl(),
  headers: {
    'Authorization': `Bearer  ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
});

function onError (error) {
  store.dispatch(setLoading(false));
  return Promise.reject(error);
}

configuredAxios.interceptors.request.use((config) => {
  store.dispatch(setLoading(true));
  return config;
}, onError);

// Add a response interceptor
configuredAxios.interceptors.response.use((response) => {
  store.dispatch(setLoading(false));
  return response;
}, onError);

export default configuredAxios;

import React from 'react';
import { connect } from 'react-redux';
import FormSectionTitle from '../components/edit-user-profile/FormSectionTitle';
import urls from '../utils/urls';

import { setConfirmModalFields, setProfile } from '../appRedux/actions';
import { setLanguage } from '../utils/language';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  FormInput,
  FormSelect,
} from 'shards-react';
import {
  requestFortnoxIntegration,
  requestUpdateProfile,
  requestRemoveFortnoxIntegration,
} from '../appRedux/thunks/profile';

class EditUserProfile extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      formFields: ['company_name', 'identification_num', 'email', 'name', 'address', 'zip_code', 'area', 'mobile', 'phone', 'city', 'email'],
      fortnoxData: {},
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleChangeFortnoxInput = (event) => {
    const fortnoxData = {
      ...this.state.fortnoxData,
      [event.target.name]: event.target.value,
    };

    this.setState({ fortnoxData });
  }

  handleFormSubmit (event) {
    event.preventDefault();
    const details = {};

    this.state.formFields.forEach((field) => {
      if (event.target[field]) {
        details[field] = event.target[field].value;
      }
    });
    this.props.requestUpdateProfile(details);
  }

  //  Fortnox integration

  handleChangeFortnoxIntegration = (event) => {
    const { company = {} } = this.props.profile;
    const { fortnoxData } = this.state;

    if (company.fortnox_integrated) {
      return this.props.setConfirmModalFields({
        callBack: this.props.requestRemoveFortnoxIntegration,
        message: <FormattedMessage id="remove_integration_message" defaultMessage="This will remove Fortnox intergeration." />,
        open: true,
        title: <FormattedMessage id="remove_integration" defaultMessage="Remove Integration" />,
      });
    }
    if (!fortnoxData.code || !fortnoxData.account) return;

    this.props.requestFortnoxIntegration(this.state.fortnoxData);
  }

  onLanguageChange = (e, props) => {
    const language = e.target.value;
    this.props.setProfile({ language });
    setLanguage(language);
  }

  render () {
    const { company = {}, user = {} } = this.props.profile;

    return (
      <div>
        <Container fluid className="main-content-container px-0">
          <Row>
            <Col lg="8" className="mx-auto mt-4">
              <Card small className="edit-user-details mb-4">

                <CardBody className="p-2">

                  <FormSectionTitle
                    title={<FormattedMessage id="profile" defaultMessage="Profile" />}
                    description={<FormattedMessage id="profile_desc" defaultMessage="Edit your profile below" />}
                  />
                  <Row form className="mx-4">
                    <Col lg="2">
                      <label><small> <FormattedMessage id="choose_lang" defaultMessage="Choose Language" /></small></label>
                      <FormSelect value={this.props.language} onChange={this.onLanguageChange}>
                        <option value="se">Swedish</option>
                        <option value="en">English</option>
                      </FormSelect>
                    </Col>
                  </Row>

                  <Form className="py-4" onSubmit={this.handleFormSubmit}>
                    <Row form className="mx-4">
                      <Col lg="12">
                        <Row form>

                          <Col md="4" className="form-group">
                            <label htmlFor="company"><FormattedMessage id="company_name" defaultMessage="Company" /></label>
                            <FormInput
                              id="company"
                              name="company_name"
                              defaultValue={company.name}
                              required
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="org-number"><FormattedMessage id="organisations_number" defaultMessage="Organisation Number" /></label>
                            <FormInput
                              type="text"
                              id="org-number"
                              name="identification_num"
                              defaultValue={company.identification_num}
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="contact-person"><FormattedMessage id="contact_person" defaultMessage="Contact Person" /></label>
                            <FormInput
                              type="text"
                              id="contact-person"
                              name="name"
                              defaultValue={user.name}
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="postaddress"><FormattedMessage id="post_address" defaultMessage="Post Address" /></label>
                            <FormInput
                              type="text"
                              id="postaddress"
                              name="address"
                              defaultValue={company.address}
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="zip_code"><FormattedMessage id="post_number" defaultMessage="Post Number" /></label>
                            <FormInput
                              id="zip_code"
                              name="zip_code"
                              defaultValue={company.zip_code}
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="area"><FormattedMessage id="area_name" defaultMessage="Area" /></label>
                            <FormInput
                              id="area"
                              name="area"
                              defaultValue={company.area}
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="mobile"><FormattedMessage id="mobile_number" defaultMessage="Mobile" /></label>
                            <FormInput
                              id="mobile"
                              name="mobile"
                              defaultValue={company.mobile}
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="phone"><FormattedMessage id="phone_number" defaultMessage="Phone" /></label>
                            <FormInput
                              id="phone"
                              name="phone"
                              defaultValue={company.phone}
                            />
                          </Col>

                          <Col md="4" className="form-group">
                            <label htmlFor="city"><FormattedMessage id="city_name" defaultMessage="City" /></label>
                            <FormInput
                              id="city"
                              name="city"
                              defaultValue={company.city}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <hr />

                    <FormSectionTitle
                      title={<FormattedMessage id="login" defaultMessage="Login Email" />}
                      description={<FormattedMessage id="login_desc" defaultMessage="Change your login email" />}
                    />

                    <Row form className="mx-4">

                      <Col md="4" className="form-group">
                        <label htmlFor="email"><FormattedMessage id="login_email" defaultMessage="Email" /></label>
                        <FormInput
                          id="email"
                          name="email"
                          defaultValue={user.email}
                          required
                        />
                      </Col>
                    </Row>

                    <Button
                      type="submit"
                      size="sm"
                      theme="accent"
                      className="ml-auto d-table mr-3"
                    >
                      <FormattedMessage id="save_changes" defaultMessage="Save Changes" />
                    </Button>

                  </Form>
                  <hr />

                  <FormSectionTitle
                    title={<FormattedMessage id="fortnox_integration" defaultMessage="Fortnox Integration" />}
                    description={<FormattedMessage id="fortnox_integration_desc" defaultMessage="To integrate Fortnox with our webiste, you need to provide Authorization Code. You can search our app by 'Bokning & Schema Online'. Check this guide for getting Authorization Code from fortnox." />}
                  />

                  <Row form className="mx-4 d-flex">
                    {!company.fortnox_integrated ? (
                        <>
                          <Col md="4" className="form-group">
                            <a href={urls.getFortnoxIntegrationUrl()}>Integrate Fortnox</a>
                          </Col>
                        </>) : (
                      <Col
                        tag="label"
                        className="col-form-label h4"
                      >
                        <FormattedMessage id="integration_active" defaultMessage="Fortnox Integration Is Active" />

                      </Col>)
                    }

                    <Row form className="mx-4">
                      {/* <Col
                        tag="label"
                        htmlFor="integrate-fortnox"
                        className="col-form-label"
                      >
                        {!company.fortnox_integrated ? 'Integrate Fortnox' : 'Remove Fortnox Integration'}
                        <small className="text-muted form-text">
                          {!company.fortnox_integrated ? 'Click here to integrate fortnox' : 'Click here to remove fortnox integration'}
                        </small>
                      </Col> */}
                      <Col className="d-flex align-self-center">
                        <div>
                          {company.fortnox_integrated && (
                            <Button theme={!company.fortnox_integrated ? 'primary' : 'danger'} onClick={this.handleChangeFortnoxIntegration} name="integrateFortnox" >
                              <FormattedMessage id="remove_integration" defaultMessage="Remove Integration" />
                            </Button>
                          )}
                        </div>
                        {/* <FormCheckbox
                          toggle
                          checked={company.fortnox_integrated}
                          onChange={this.handleChangeFortnoxIntegration}
                          className="ml-auto my-auto"
                          id="integrate-fortnox"
                          name="integrateFortnox"
                          required
                        /> */}
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  language: state.profile.language,
});

const mapDispatchToProps = {
  requestFortnoxIntegration,
  requestRemoveFortnoxIntegration,
  requestUpdateProfile,
  setConfirmModalFields,
  setProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserProfile);

/* eslint-disable global-require */
/* eslint-disable no-inline-comments */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { requestChangePassword } from '../appRedux/thunks/auth';
import queryString from 'qs';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from 'shards-react';

const ChangePassword = (props) => {

  const submitForm = (event) => {
    event.preventDefault();
    const queryParams = queryString.parse(props.location.search);
    const password = event.target.password.value;
    const passwordConfirmation = event.target.confirmPassword.value;
    if (!password || !passwordConfirmation || password !== passwordConfirmation) {
      return props.notification.notifyError('Change Passwrd', ' Password and Repeat password must be same');
    }
    props.requestChangePassword({
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: queryParams.reset_password_token || queryParams['?reset_password_token'],
    }, props.history);
  };

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              <img
                className="auth-form__logo d-table mx-auto mb-3"
                src={require('../images/mex-without-description.jpeg')}
                alt="LOGO MEX"
              />

              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                <FormattedMessage id="change_new_password_title" defaultMessage="Change New Password"/>
              </h5>

              {/* Form Fields */}
              <Form onSubmit={submitForm}>
                <FormGroup>
                  <label htmlFor="password1"><FormattedMessage id="password" defaultMessage="Password"/></label>
                  <FormInput
                    type="password"
                    name="password"
                    id="password1"
                    autoComplete="new-password"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="password2"><FormattedMessage id="repeat_password" defaultMessage="Repeat Password"/></label>
                  <FormInput
                    type="password"
                    name="confirmPassword"
                    id="password2"
                    autoComplete="new-password"
                  />
                </FormGroup>
                <Button
                  pill
                  theme="accent"
                  className="d-table mx-auto"
                  type="submit"
                >
                  <FormattedMessage id="change_password" defaultMessage="Change Password"/>
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapState = state => ({
  notification: state.notification,
});

export default connect(mapState, { requestChangePassword })(ChangePassword);

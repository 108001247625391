import { SET_SUPERADMIN_FIELDS, SET_SUPER_ADMIN_ERRORS } from '../../utils/constants';
import { getPages } from './utility';

const initialState = {
  pageSizeOptions: [5, 10, 15, 20, 25, 30],
  pageSize: 10,
  pages: 1,
  page: 1,
  integrationErrors: {},
};

function superAdmin (state = initialState, action) {
  switch (action.type) {
  case SET_SUPERADMIN_FIELDS:
    return {
      ...state,
      ...action.payload,
      page: action.payload.page ? parseInt(action.payload.page, 10) : state.page,
      pages: getPages(state, action.payload),
      pageSize: action.payload.per_page ? parseInt(action.payload.per_page, 10) : state.pageSize,
    };
  case SET_SUPER_ADMIN_ERRORS:
    return {
      ...state,
      integrationErrors: {
        ...action.payload,
        page: action.payload.page ? parseInt(action.payload.page, 10) : state.page,
        pages: getPages(state, action.payload),
        pageSize: action.payload.per_page ? parseInt(action.payload.per_page, 10) : state.pageSize,
      },
    };
  default:
    return state;
  }
}

export default superAdmin;

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
// import {routerMiddleware} from "react-router-redux";

import reducers from '../reducers/index';

// midleware and devttools
const middlewares = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
